import React from 'react'
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import RepeatIcon from '@mui/icons-material/Repeat';
import ShuffleIcon from '@mui/icons-material/Shuffle';
import './button.scss'

function Button({ play, isPlaying }) {
  return (
    <div className='btn-container'>
        <ShuffleIcon className='icon'/>
        <SkipPreviousIcon className='icon' />
        <div onClick={play} className={isPlaying ? 'btn-stop' : 'btn-play'}></div>
        <SkipNextIcon className='icon'/>
        <RepeatIcon className='icon'/>
    </div>
  )
}
export default Button