import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { AuthContext } from "../../controllers/authControllers"
import { useQuery } from "react-query";

import LeftSide from "../../components/Slide/LeftSide";
import LeftSideAdmin from "../../components/Slide/LeftSideAdmin";

import image3 from '../../assets/images/orange_money.png'
import image4 from '../../assets/images/moov_money.png'
import image5 from '../../assets/images/wave.png'
import image7 from '../../assets/images/paypal.png'

import './retrait.scss';
import Chatbot from "../../components/v2/chatbot";

function Retrait({ page }) {
    const currentUser = useContext(AuthContext);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [payementMethod, setPayementMethod] = useState("orange money")
    const [RetraitData, setRetraitData] = useState({
        numb: "",
        name: "",
        email: "",
        montant: "",
        devise: "$",
        methode:payementMethod,
      });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setRetraitData({ ...RetraitData, [name]: value });
      };

    useEffect(() => {
        const checkAuth = async () => {
          try {
                await axios.post('/api/users/verify', {}, {
                withCredentials:true,
            });
            setIsAuthenticated(true);
          } catch (error) {
            console.error('Erreur lors de la vérification de l\'authentification', error);
          }
        };
    
        checkAuth();
    }, []);
    function PayementMethod(method) {
        setPayementMethod(method);
        setRetraitData({
            numb: "",
            name: "",
            email: "",
            montant: "",
            devise: "$",
            methode:method,
        })
    }
    const handleMakeRetrait = async(event) => {
        event.preventDefault();
        try{
                await axios.post("/api/transactions/makeTransactions", RetraitData, {
                withCredentials:true,
            })
            window.location.href = '/dashboard';
        }catch(error){
            console.log(error);
        }
    }

    const royaltiesData = useQuery(["royalties", currentUser.currentUser.idMusicien], () =>
        
        axios.get(`/api/royalties/getRoyalties?musicienId=${currentUser.currentUser.idMusicien}`, {
            withCredentials:true
        }).then((res) => {    
            return res.data;
        })
    )

    let revenue = 0;

    if (royaltiesData.data) {
        revenue = Math.floor(royaltiesData.data.reduce((sum, item) => {
            const {idroyalties, music_title, musicien_id, ...others} = item;
            const sumOfServices = Object.values(others).reduce((acc, value) => acc + value, 0);
            return sum + sumOfServices;
        }, 0) * 100) / 100;
    }

    const transactionsData = useQuery(["transactions", currentUser.currentUser.idMusicien], () =>
        
        axios.get(`/api/transactions/getArtistesTransactions?musicienId=${currentUser.currentUser.idMusicien}`, {
            withCredentials:true
        }).then((res) => {    
            return res.data;
        })
    )

    let retrait = 0;

    if (transactionsData.data) {
        retrait = Math.floor(transactionsData.data.reduce((sum, item) => {
            const {Montant} = item;
            const sumOfServices = Montant;
            return sum + sumOfServices;
        }, 0) * 100) / 100;
    }
    let reste = revenue - retrait;
      if (isAuthenticated){
        
          return (
              <div className="retrait">
                {
                    page === 'admin'
                        ?<LeftSideAdmin page='retrait'/>
                        :<LeftSide page='retrait'/>
                }
                <Chatbot />
                <div className="rightSide">
                    <div className="body">
                        <div className="payementMode">
                            <h4>Choisir un mode de retrait</h4>
                            <div className={"box " + (payementMethod === "paypal" ? "active" :'')} onClick={() => PayementMethod("paypal")}>
                                <div className="boxLeft">
                                    PayPal
                                </div>
                                <div className="boxRight">
                                    <img src={image7} alt="carte-visa" style={{width:'80px'}}/>
                                </div>
                            </div>
                            <div className={"box " + (payementMethod === "orange money" ? "active" :'')} onClick={() => PayementMethod("orange money")}>
                                <div className="boxLeft">
                                    Orange Money
                                </div>
                                <div className="boxRight">
                                    <img src={image3} alt="carte-visa" />
                                </div>
                            </div>
                            <div 
                                className={"box " + (payementMethod === "moov money" ? "active" :'')}
                                onClick={() => PayementMethod("moov money")}
                            >
                                <div className="boxLeft">
                                    Moov Money
                                </div>
                                <div className="boxRight">
                                    <img src={image4} alt="carte-visa" />
                                </div>
                            </div>
                            <div className={"box " + (payementMethod === "wave" ? "active" :'')} onClick={() => PayementMethod("wave")}>
                                <div className="boxLeft">
                                    Wave
                                </div>
                                <div className="boxRight">
                                    <img src={image5} alt="carte-visa" style={{width:'50px', borderRadius:'10px'}}/>
                                </div>
                            </div>
                        </div>
                        <div className="payementOptions">
                            <h4>Vérification</h4>
                            {
                                payementMethod === 'orange money'
                                    ?
                                        <form action="" onSubmit={handleMakeRetrait}>
                                            <h4>Orange Money</h4>
                                            <label htmlFor="numb">Numero de Téléphone</label>
                                            <input
                                                required
                                                type="text"
                                                name="numb"
                                                placeholder="+226 72528159"
                                                onChange={handleInputChange}
                                                value={RetraitData.numb}
                                            />
                                            <label htmlFor="name">Nom et Prénom</label>
                                            <input
                                                required
                                                type="text"
                                                name="name"
                                                placeholder="PEZONGO Mickael"
                                                onChange={handleInputChange}
                                                value={RetraitData.name}
                                            />
                                            <label htmlFor="email">Votre Email</label>
                                            <input
                                                required
                                                type="text"
                                                name="email"
                                                placeholder="contact@lithyus-music.com"
                                                onChange={handleInputChange}
                                                value={RetraitData.email}
                                            />
                                            <label htmlFor="montant">Montant</label>
                                            <div className={"montant " + (RetraitData.montant > reste ? 'depasse':'')}>
                                                <input
                                                    required
                                                    type="text" 
                                                    name="montant"
                                                    placeholder="200"
                                                    onChange={handleInputChange}
                                                    value={RetraitData.montant}
                                                />
                                                <select name="devise" id="devise" onChange={handleInputChange}>
                                                    <option value="$">$</option>
                                                    <option value="FCFA">FCFA</option>
                                                </select>
                                            </div>
                                            {
                                                RetraitData.montant > reste
                                                    ? <div style={{color:'red'}}>Solde insuffisent</div>
                                                    :null
                                            }
                                            {
                                                RetraitData.montant > reste || RetraitData.montant === 0 
                                                    ? <input type="submit" disabled style={{opacity:'.3'}}/>
                                                        
                                                    :<input type="submit"/>
                                            }
                                        </form>
                                    : payementMethod === 'moov money'
                                    ?
                                        <form action="" onSubmit={handleMakeRetrait}>
                                            <h4>Moov Money</h4>
                                            <label htmlFor="numb">Numero de Téléphone</label>
                                            <input
                                                required
                                                type="text"
                                                name="numb"
                                                placeholder="+226 72528159"
                                                onChange={handleInputChange}
                                                value={RetraitData.numb}
                                            />
                                            <label htmlFor="name">Nom et Prénom</label>
                                            <input
                                                required
                                                type="text"
                                                name="name"
                                                placeholder="PEZONGO Mickael"
                                                onChange={handleInputChange}
                                                value={RetraitData.name}
                                            />
                                            <label htmlFor="email">Votre Email</label>
                                            <input
                                                required
                                                type="email"
                                                name="email"
                                                placeholder="contact@lithyus-music.com"
                                                onChange={handleInputChange}
                                                value={RetraitData.email}
                                            />
                                            <label htmlFor="montant">Montant</label>
                                            <div className={"montant " + (RetraitData.montant > reste ? 'depasse':'')}>
                                                <input
                                                    required
                                                    type="text"
                                                    name="montant"
                                                    placeholder="200"
                                                    onChange={handleInputChange}
                                                    value={RetraitData.montant}
                                                />
                                                <select name="devise" id="devise" onChange={handleInputChange}>
                                                    <option value="$">$</option>
                                                    <option value="FCFA">FCFA</option>
                                                </select>
                                            </div>
                                            {
                                                RetraitData.montant > reste
                                                    ? <div style={{color:'red'}}>Solde insuffisent</div>
                                                    :null
                                            }
                                            {
                                                RetraitData.montant > reste || RetraitData.montant === 0 
                                                    ? <input type="submit" disabled style={{opacity:'.3'}}/>
                                                        
                                                    :<input type="submit"/>
                                            }
                                        </form>
                                    : payementMethod === 'paypal'
                                    ?
                                        <form action="" onSubmit={handleMakeRetrait}>
                                            <h4>PAYPAL</h4>
                                            <label htmlFor="email">Numero de Téléphone ou Email</label>
                                            <input
                                                required
                                                type="email"
                                                name="email"
                                                placeholder="contact@lithyus-music.com ou +226 72528159"
                                                onChange={handleInputChange}
                                                value={RetraitData.email}
                                            />
                                            <label htmlFor="name">Nom et Prénom</label>
                                            <input
                                                required
                                                type="text"
                                                name="name"
                                                placeholder="PEZONGO Mickael"
                                                onChange={handleInputChange}
                                                value={RetraitData.name}
                                            />
                                            <label htmlFor="montant">Montant</label>
                                            <div className={"montant " + (RetraitData.montant > reste ? 'depasse':'')}>
                                                <input
                                                    required
                                                    type="text"
                                                    name="montant"
                                                    placeholder="200"
                                                    onChange={handleInputChange}
                                                    value={RetraitData.montant}
                                                />
                                                <select name="devise" id="devise" onChange={handleInputChange}>
                                                    <option value="$">$</option>
                                                    <option value="FCFA">FCFA</option>
                                                </select>
                                            </div>
                                            {
                                                RetraitData.montant > reste
                                                    ? <div style={{color:'red'}}>Solde insuffisent</div>
                                                    :null
                                            }
                                            {
                                                RetraitData.montant > reste || RetraitData.montant === 0 
                                                    ? <input type="submit" disabled style={{opacity:'.3'}}/>
                                                        
                                                    :<input type="submit"/>
                                            }
                                        </form>
                                    :
                                        <form action="" onSubmit={handleMakeRetrait}>
                                            <h4>Wave</h4>
                                            <label htmlFor="numb">Numero de Téléphone</label>
                                            <input 
                                                required
                                                type="text"
                                                name="numb"
                                                placeholder="+226 72528159"
                                                onChange={handleInputChange}
                                                value={RetraitData.numb}
                                            />
                                            <label htmlFor="name">Nom et Prénom</label>
                                            <input
                                                required
                                                type="text"
                                                name="name"
                                                placeholder="PEZONGO Mickael"
                                                onChange={handleInputChange}
                                                value={RetraitData.name}
                                            />
                                            <label htmlFor="montant">Montant</label>
                                            <div className={"montant " + (RetraitData.montant > reste ? 'depasse':'')}>
                                                <input
                                                    required
                                                    type="text"
                                                    name="montant"
                                                    placeholder="200"
                                                    onChange={handleInputChange}
                                                    value={RetraitData.montant}
                                                />
                                                <select name="devise" id="devise" onChange={handleInputChange}>
                                                    <option value="$">$</option>
                                                    <option value="FCFA">FCFA</option>
                                                </select>
                                            </div>
                                            {
                                                RetraitData.montant > reste
                                                    ? <div style={{color:'red'}}>Solde insuffisent</div>
                                                    :null
                                            }
                                            {
                                                RetraitData.montant > reste || RetraitData.montant === 0 
                                                    ? <input type="submit" disabled style={{opacity:'.3'}}/>
                                                        
                                                    :<input type="submit"/>
                                            }
                                        </form>
                            }

                        </div>
                    </div>
                    <div className="copyright">
                        <span>© 2024 Lithyus Music Group, LLC. Tous droits réservés.</span>
                    </div>
                </div>
              </div>
          )
      }else{
        return null;
      }
}

export default Retrait;