import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import "./verifyUser.scss"

function VerifyUser() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const token = params.get('token');

  const [verificationStatus, setVerificationStatus] = useState('pending');

  useEffect(() => {
    async function verifyEmail() {
      try {
        await axios.get(`/api/email/verification?token=${token}`, {
          withCredentials: true,
        });
        setVerificationStatus('success');
      } catch (error) {
        console.log(error);
        setVerificationStatus('error');
      }
    }

    verifyEmail();
  }, [token]);

  // Redirige vers la page de login quand la vérification réussit
  useEffect(() => {
    if (verificationStatus === 'success') {
      window.location.href = '/login'
    }
  }, [verificationStatus]);

  return (
    <div>
      {verificationStatus === 'pending' && <p>Verification in progress...</p>}
      {verificationStatus === 'error' && <p>Error occurred during verification. Please try again later.</p>}
    </div>
  );
}

export default VerifyUser;
