import { createContext, useEffect, useState } from "react";

export const DarkModeContext = createContext();

export const DarkModeContextProvider = ({ children }) => {
  const [darkMode, setDarkMode] = useState(
    JSON.parse(localStorage.getItem("darkMode")) || false
  );

  const toggle = () => {
    setDarkMode(!darkMode);
  };

  useEffect(() => {
    localStorage.setItem("darkMode", darkMode);
  }, [darkMode]);

  return (
    <DarkModeContext.Provider value={{ darkMode, toggle }}>
      {children}
    </DarkModeContext.Provider>
  );
};

export const LangueContext = createContext();

export const LangueContextProvider = ({ children }) => {
  const [ lang, setLangMode ] = useState(
    JSON.parse(localStorage.getItem("fr")) || true
  );
  const toggle_lang = () => {
    setLangMode(!lang);
  };
  useEffect(() => {
    localStorage.setItem("fr", lang);
  }, [lang]);

  return(
    <LangueContext.Provider value={{lang, toggle_lang}}>
      { children }
    </LangueContext.Provider>
  )
}