import React from "react"
import { Link } from "react-router-dom";
import NavBar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import "./confidentialites.scss"
import Chatbot from "../../components/v2/chatbot";

function Confidentialite() {
    return(
        <div className="conditions">
            <NavBar />
            <Chatbot />
            <div className="corps">
                <div className="title">
                    <span>Termes et conditions d'utilisations</span>
                    <p>
                        Ces termes et conditions décrivent les règles et règlements d'utilisation du site Web de Lithyus Music Distribution, situé à l'adresse web lithyusmusic.com.
                        En accédant à ce site Web, nous supposons que vous acceptez ces termes et conditions. Ne continuez pas à utiliser Lithyus Music Distribution si vous n'acceptez pas de respecter tous les termes et conditions énoncés sur cette page.
                    </p>
                </div>
                <div className="body">
                    <span>Cookies</span>
                    <p>
                    Le site Web utilise des cookies pour aider à personnaliser votre expérience en ligne. En 
                    accédant à Lithyus Music Distribution, vous avez accepté d'utiliser les cookies requis.

                    <br/>Un cookie est un fichier texte placé sur votre disque dur par un serveur de pages Web. 
                        Les cookies ne peuvent pas être utilisés pour exécuter des programmes ou transmettre des virus 
                        à votre ordinateur. Les cookies vous sont attribués de manière unique et ne peuvent être lus que
                        par un serveur Web du domaine qui vous a émis le cookie.

                        <br/>Nous pouvons utiliser des cookies pour collecter, stocker et suivre des informations à des 
                        fins statistiques ou marketing pour exploiter notre site Web. Vous avez la possibilité 
                        d'accepter ou de refuser les Cookies facultatifs. Certains cookies obligatoires sont nécessaires 
                        au fonctionnement de notre site Web. Ces cookies ne nécessitent pas votre consentement car ils 
                        fonctionnent toujours. Veuillez garder à l'esprit qu'en acceptant les cookies requis, vous 
                        acceptez également les cookies tiers, qui pourraient être utilisés via des services fournis 
                        par des tiers si vous utilisez ces services sur notre site Web, par exemple, une fenêtre 
                        d'affichage vidéo fournie par des tiers et intégrée dans notre site Web.
                    </p>
                    <span>Licence</span>
                    <p>
                        Sauf indication contraire, Lithyus Music Distribution et/ou ses concédants détiennent les droits 
                        de propriété intellectuelle pour tout le matériel de Lithyus Music Distribution. Tous les droits 
                        de propriété intellectuelle sont réservés. Vous pouvez y accéder à partir de Lithyus Music 
                        Distribution pour votre usage personnel, sous réserve des restrictions définies dans les 
                        présentes conditions générales.
                    </p>
                    <span>
                        Il ne faut pas:
                    </span>
                    <p>
                        Copier ou republier du matériel de Lithyus Music Distribution Vendre, louer ou sous-licencier 
                        du matériel de Lithyus Music Distribution Reproduire, dupliquer ou copier du matériel de Lithyus 
                        Music Distribution Redistribuer le contenu de Lithyus Music Distribution.<br/>
                        Le présent accord prend effet à la date des présentes.
                        Certaines parties de ce site Web offrent aux utilisateurs la possibilité de publier et 
                        d'échanger des opinions et des informations dans certaines zones du site Web. Lithyus Music
                         Distribution ne filtre pas, n'édite pas, ne publie pas et ne révise pas les commentaires avant 
                         leur présence sur le site Web. Les commentaires ne reflètent pas les points de vue et 
                         opinions de Lithyus Music Distribution, de ses agents et/ou de ses sociétés affiliées. Les 
                         commentaires reflètent les points de vue et opinions de la personne qui publie ses points 
                         de vue et opinions. Dans la mesure permise par les lois applicables, Lithyus Music Distribution 
                         ne sera pas responsable des commentaires ou de toute responsabilité, dommages ou dépenses
                         causés et/ou subis à la suite de toute utilisation et/ou publication et/ou apparition des 
                         commentaires. sur ce site.
                        <br/>Lithyus Music Distribution se réserve le droit de surveiller tous les commentaires et de 
                        supprimer tout commentaire pouvant être considéré comme inapproprié, offensant ou entraînant 
                        une violation des présentes conditions générales.
                    </p>
                    <span>Vous garantissez et déclarez que :</span>
                    <p>
                        Vous avez le droit de publier les commentaires sur notre site Web et disposez de toutes les licences et 
                        autorisations nécessaires pour le faire ; Les commentaires n'envahissent aucun droit de propriété intellectuelle, 
                        y compris, sans s'y limiter, les droits d'auteur, les brevets ou les marques de commerce d'un tiers ; Les 
                        commentaires ne contiennent aucun contenu diffamatoire, calomnieux, offensant, indécent ou autrement illégal, 
                        ce qui constitue une atteinte à la vie privée. Les Commentaires ne seront pas utilisés pour solliciter ou 
                        promouvoir des activités commerciales ou personnalisées ou présenter des activités commerciales ou des activités 
                        illégales. <br/>
                        Par la présente, vous accordez à Lithyus Music Distribution une licence non exclusive pour utiliser, reproduire, éditer 
                        et autoriser d'autres personnes à utiliser, reproduire et éditer n'importe lequel de vos commentaires sous toutes 
                        formes, formats ou supports. <br />
                        Par l’entremise de notre plateforme de distribution, vous reconnaissez en acceptant ses termes et conditions nous céder la propriété intellectuelle de vos créations (musique sonore, paroles écrites) et la gestion des droits générés par l’ensembles de ces composantes. Vous nous accordez la possibilité d’utiliser les morceaux distribués comme bon nous semble que ce soit pour des fins commerciales ou pour des placements qui auront pour but de générer des profits en retour. Cela accorde la propriété intellectuelle des musiques distribuées par Lithyus Music Distribution ,qui, en contrepartie, vous dédommagera à travers le versement des retours générés par le placement de vos chansons. Il faut noter que ces termes et conditions inclues l’ensembles des morceaux déjà distribués par l’entremise de Lithyus Music Distribution.
                    </p>
                    <span>
                        Lien hypertexte vers notre contenu
                    </span>
                    <p>
                        Les organisations suivantes peuvent créer un lien vers notre site Web sans autorisation écrite préalable : <br /><br/>
                        Organismes gouvernementaux; Moteurs de recherche; Organismes de presse ; Les distributeurs d'annuaires en ligne peuvent créer un lien vers notre site Web de la même manière qu'ils créent un lien hypertexte vers les sites Web d'autres entreprises répertoriées ; et Entreprises accréditées à l'échelle du système, à l'exception des organisations à but non lucratif, des centres commerciaux caritatifs et des groupes de collecte de fonds caritatifs qui ne peuvent pas créer de lien hypertexte vers notre site Web. <br/><br/>
                        Les limitations et interdictions de responsabilité énoncées dans la présente section et ailleurs dans la présente clause de non-responsabilité : (a) sont soumises au paragraphe précédent ; et (b) régissent toutes les responsabilités découlant de la clause de non-responsabilité, y compris les responsabilités contractuelles, délictuelles et pour manquement à une obligation légale. <br />
                        Tant que le site Web et les informations et services sur le site Web sont fournis gratuitement, nous ne serons pas responsables de toute perte ou dommage de quelque nature que ce soit.
                    </p>
                    <span>Partage des informations</span>
                    <div>
                        <p>
                            Nous ne vendons, n'échangeons ni ne louons vos informations personnelles à des tiers sans votre 
                            consentement, sauf dans les cas suivants :
                        </p>
                        <div className="fiber">
                            <FiberManualRecordIcon className="icon"/>
                            Lorsque nous sommes tenus de le faire par la loi, une réglementation ou une demande légale.
                        </div>
                        <div className="fiber">
                            <FiberManualRecordIcon className="icon"/>
                            Lorsque nous pensons de bonne foi que la divulgation est nécessaire pour protéger nos droits, votre 
                            sécurité ou celle des autres utilisateurs.                   
                        </div>
                        <div className="fiber">
                            <FiberManualRecordIcon className="icon"/>
                            Lorsque cela est nécessaire pour assurer le bon fonctionnement de notre entreprise, y compris le traitement 
                            des paiements ou la gestion des données.
                        </div>
                    </div>
                    <span>Sécurité des informations</span>
                    <p>
                        Nous mettons en place des mesures de sécurité appropriées pour protéger vos informations personnelles contre 
                        tout accès non autorisé, toute divulgation, toute altération ou destruction accidentelle ou illégale. Cependant,
                         veuillez noter qu'aucune méthode de transmission sur Internet ou de stockage électronique n'est totalement 
                         sécurisée, et nous ne pouvons garantir la sécurité absolue de vos informations.
                    </p>
                    <span>Modification de la politique de confidentialité</span>
                    <p>
                        Nous nous réservons le droit de modifier cette politique de confidentialité à tout moment. Les modifications 
                        seront publiées sur notre site web avec la date de leur entrée en vigueur. Nous vous encourageons à consulter 
                        régulièrement cette politique de confidentialité pour rester informé des éventuelles mises à jour.
                    </p>
                    <span>Accès et contrôle de vos informations</span>
                    <p>
                        Vous avez le droit d'accéder à vos informations personnelles que nous détenons et de les mettre à jour ou de 
                        les supprimer si nécessaire. Si vous souhaitez exercer ce droit, veuillez nous contacter en utilisant les 
                        coordonnées fournies à la fin de cette politique.
                    </p>
                    <span>Protection des mineurs</span>
                    <p>
                        Notre site web ne s'adresse pas aux personnes de moins de 18 ans. Nous ne collectons pas sciemment 
                        d'informations personnelles auprès de personnes de moins de 18 ans. Si vous êtes un parent ou un tuteur et que 
                        vous pensez que votre enfant nous a fourni des informations personnelles, veuillez nous contacter afin que nous 
                        puissions supprimer ces informations de nos systèmes.
                    </p>
                    <span>Transfert international des données</span>
                    <p>
                        Veuillez noter que vos informations personnelles peuvent être transférées et traitées dans des pays autres que 
                        votre pays de résidence. Ces pays peuvent avoir des lois sur la protection des données qui diffèrent de celles 
                        de votre pays. Toutefois, nous prendrons les mesures nécessaires pour garantir que vos informations 
                        personnelles bénéficient d'un niveau de protection adéquat conformément aux lois applicables en matière de 
                        protection des données.
                    </p>
                    <span>Communication commerciale</span>
                    <p>
                        Avec votre consentement, nous pouvons utiliser vos informations personnelles pour vous envoyer des 
                        communications commerciales, telles que des newsletters, des offres spéciales et des informations sur nos 
                        produits et services. Vous pouvez choisir de ne plus recevoir ces communications à tout moment en suivant 
                        les instructions de désabonnement fournies dans les communications elles-mêmes.
                    </p>
                    <span>Responsabilité des utilisateurs</span>
                    <p>
                        Il est important de rappeler que vous êtes responsable de la confidentialité des informations que vous partagez 
                        publiquement sur notre site web, telles que les commentaires, les avis ou les messages sur les forums. Nous ne 
                        pouvons pas contrôler les actions des autres utilisateurs avec lesquels vous interagissez sur notre site, et nous 
                        déclinons toute responsabilité en cas d'utilisation abusive ou de divulgation de vos informations personnelles de 
                        cette manière.
                    </p>
                    <span>Application de la politique de confidentialité</span>
                    <p>
                        Nous nous engageons à respecter les principes énoncés dans cette politique de confidentialité et à nous 
                        conformer aux lois applicables en matière de protection des données. Si vous avez des préoccupations concernant 
                        notre respect de cette politique ou si vous estimez que vos droits en matière de confidentialité ont été 
                        violés, veuillez nous contacter en utilisant les coordonnées fournies ci-dessus. Nous enquêterons sur les 
                        plaintes et prendrons les mesures appropriées pour remédier à toute violation de confidentialité.<br/><br/>
                        Nous vous remercions de la confiance que vous accordez à DEVMastermind. Nous nous engageons à protéger vos 
                        informations personnelles et à assurer la confidentialité de vos données.
                    </p>
                    <Link to="/contact"><button>Contacter</button></Link>
                </div>
            </div>
            <Footer />
        </div>

    )
}

export default Confidentialite;