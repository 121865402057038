import React, {useEffect, useContext, useState} from 'react'
import { NavLink, useLocation } from 'react-router-dom';
import { FaCheck } from "react-icons/fa6";
import logo from '../../assets/logoLithyus.webp'
import ClearIcon from '@mui/icons-material/Clear'
import Loader from "../../components/loading/Loader";
import { v4 as uuidv4 } from 'uuid';
import { AuthContext } from "../../controllers/authControllers"
import axios from 'axios'
import './promuteMusic.scss'

const getId = async () => {
  let id = uuidv4();
  id = id.replace(/-/g, '').substring(0, 10);

  return id;
};

export default function PromuteMusic() {
  const currentUser = useContext(AuthContext);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const musicId = params.get('musicId');
  const [payement, setPayement] = useState("pro")
  const [loading, setLoading] = useState(false);
  const [musicData, setMusicData] = useState()
  const [payementData, setPayementData] = useState({
    amount: 25,
    description: "Achat du pack pro pour une promotion de la musique",
    type: "pro",
    transaction_id: '',
    old_music:1
  });

  const handleChangePayement = async(type) => {
    setPayement(type);
    if (type === 'simple'){
      const updatedPayementData = { ...payementData };
      updatedPayementData.type = "Simple";
      updatedPayementData.amount = 10
      updatedPayementData.description = "Achat du pack simple pour une promotion de la musique"
      updatedPayementData.transaction_id = await getId()
      setPayementData(updatedPayementData)
    }else if (type === 'pro'){
      const updatedPayementData = { ...payementData };
      updatedPayementData.type = "Pro";
      updatedPayementData.amount = 25
      updatedPayementData.description = "Achat du pack pro pour une promotion de la musique"
      updatedPayementData.transaction_id = await getId()
      setPayementData(updatedPayementData)
    }else if (type === 'plus'){
      const updatedPayementData = { ...payementData };
      updatedPayementData.type = "Plus";
      updatedPayementData.amount = 42
      updatedPayementData.description = "Achat du pack plus pour une promotion de la musique"
      updatedPayementData.transaction_id = await getId()
      setPayementData(updatedPayementData)
    }
  }

  useEffect(() => {
    const musicDataFc = async() => {
      const id = await getId()
      setPayementData(prevPayementData => ({
        ...prevPayementData,
        transaction_id: id
      }));
      axios.get(`/api/music/getMusicOnly?musicId=${musicId}`, {
          withCredentials:true
      }).then((res) => {    
        setMusicData(res.data)
      })
    }

    musicDataFc()

  }, [currentUser])

  const makePayementForMusic = async(event) => {
    event.preventDefault()
    const res = await axios.post("/api/payement/stripe-checkout", {payementData, musicData, currentUser}, {
        withCredentials:true
    })

    window.location.href = res.data.url
  }

  console.log(payementData)
  return (
    <div className='promutemusic'>
      <div className="addMusic-navbar">
        <div className="logo">
            <img src={logo} alt="logo de Lithyus" />
        </div>
        <div className="respect">
            <span className="txt">Respectez les consignes donnés et envoyez des musiques de qualité supérieure</span>
        </div>
        <div className="cancel">
            <NavLink to='/dashboard'><ClearIcon /></NavLink>
        </div>
      </div>
      <div className="promute-details">
        <div className="paiement">
            <div className="paiement-top">
                <h5>Choix du pack pour la promotion</h5>
                <p>Simpe et sécurisé</p>
            </div>
            <div className="paiement-middle">
                <h5>Quel type de promotion souhaitez vous faire ?</h5>
                <div className="paiement-middle-content">
                    <div className="paiement-middle-content-box" onClick={() => handleChangePayement('simple')}>
                        <div className={"box " + (payement === 'simple' ? 'active':'')}>
                            <div className="check-box">
                                <FaCheck className="icon"/>
                            </div>
                            <div className="check-box-content">
                                <div className="title">Simple</div>
                                <div className="prix">
                                  <div style={{
                                    width:'100%',
                                    textAlign:'center',
                                    fontSize:'18px'
                                  }}>
                                    5.000 Fcfa
                                  </div>
                                  <div style={{
                                    width:'100%',
                                    textAlign:'center',
                                    fontSize:'12px'
                                  }}>
                                    ≈
                                  </div>
                                  <div style={{
                                    width:'100%',
                                    textAlign:'center',
                                    fontSize:'18px'
                                  }}>
                                    10 $
                                  </div>
                                </div>
                                <div className="description">Un marketing qui sera fait au sein des plateformes de streaming 
                                pour une durée illimité afin de développer votre influence dans l'industrie de la musique.</div>
                            </div>
                        </div>
                    </div>
                    <div className="paiement-middle-content-box" onClick={() => handleChangePayement('pro')}>
                        <div className={"box " + (payement === 'pro' ? 'active':'')}>
                            <div className="check-box">
                                <FaCheck className="icon"/>
                            </div>
                            <div className="check-box-content">
                                <div className="title">Pro</div>
                                <div className="prix">
                                  <div style={{
                                    width:'100%',
                                    textAlign:'center',
                                    fontSize:'18px'
                                  }}>
                                    15.000 Fcfa
                                  </div>
                                  <div style={{
                                    width:'100%',
                                    textAlign:'center',
                                    fontSize:'12px'
                                  }}>
                                    ≈
                                  </div>
                                  <div style={{
                                    width:'100%',
                                    textAlign:'center',
                                    fontSize:'18px'
                                  }}>
                                    25 $
                                  </div>
                                </div>
                                <div className="description">Une promotion de vos titres musicaux sur les réseaux sociaux, mais aussi au sein des radios internationales afin de développer votre influence.</div>
                            </div>
                        </div>
                    </div>
                    <div className="paiement-middle-content-box" onClick={() => handleChangePayement('plus')}>
                        <div className={"box " + (payement === 'plus' ? 'active':'')}>
                            <div className="check-box">
                                <FaCheck className="icon"/> 
                            </div>
                            <div className="check-box-content">
                                <div className="title">Plus</div>
                                <div className="prix">
                                  <div style={{
                                    width:'100%',
                                    textAlign:'center',
                                    fontSize:'18px'
                                  }}>
                                    25.000 Fcfa
                                  </div>
                                  <div style={{
                                    width:'100%',
                                    textAlign:'center',
                                    fontSize:'12px'
                                  }}>
                                    ≈
                                  </div>
                                  <div style={{
                                    width:'100%',
                                    textAlign:'center',
                                    fontSize:'18px'
                                  }}>
                                    42 $
                                  </div>
                                </div>
                                <div className="description">On fera une promotion de vos titres sur : les réseaux sociaux, sur les playlists, les radios en plus de faire la promotion de vos titres musicaux sur youtube afin de contribuer à développer encore plus votre notoriété.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <span style={{fontSize:'14px', color:'red'}}>Attention le paiement se fait uniquement par <span style={{fontWeight:'bold', fontSize:'16px'}}>carte bancaire</span>. Veillez donc vous munir de votre <span style={{fontWeight:'bold', fontSize:'16px'}}>carte bancaire</span> avant de passer à l'étape suivante</span>
        </div>
        <div className="confirmation">
            <p className="txt">En cliquant sur « Payer maintenant » vous acceptez les <a href="/">conditions générales d'utilisation</a> et les chartes de <a href="/">confidentialité</a> de Digitrans, ainsi que les règles  
            tarifaires.</p>                          
        </div>
        {
          loading
            ?<button disabled>
              <Loader type="newtons-cradle"/>
            </button>
            :<button onClick={makePayementForMusic}>Payer</button>
        }
      </div>
    </div>
  )
}
