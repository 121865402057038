import React from "react";

import CheckSharpIcon from '@mui/icons-material/CheckSharp';
import DoNotDisturbAltSharpIcon from '@mui/icons-material/DoNotDisturbAltSharp';
import AutorenewSharpIcon from '@mui/icons-material/AutorenewSharp';

function Transactions({transactions}) {
    return (
        <div className="box" key={transactions.idtransactions
        }>
            <div className="id">
                #{transactions.idtransactions.split('-')[0]}
            </div>
            <div className="montant">
                {transactions.Montant} $
            </div>
            <div className="methodes">
                {transactions.methode}
            </div>
            {transactions.status === 0
                        ?
                            <div className="status">
                                <div className="statusNoIcon">
                                    <DoNotDisturbAltSharpIcon className="icon"/>
                                </div>
                                <span>non traité</span>
                            </div>
                        : transactions.status === 1
                            ?
                                <div className="status">
                                    <div className="statusInIcon">
                                        <AutorenewSharpIcon className="icon"/>
                                    </div>
                                    <span>En cours</span>
                                </div>
                            :
                                <div className="status">
                                    <div className="statusDoIcon">
                                        <CheckSharpIcon className="icon"/>
                                    </div>
                                    <span>Traité</span>
                                </div>
                    }
        </div>
    )
}

export default Transactions;