import React from 'react'
import { useEffect, useContext, useState, useRef } from 'react';
import { useQuery } from "react-query";
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { IoMdSend } from "react-icons/io";
import Loader from '../../components/loading/Loader';
import { AuthContext } from "../../controllers/authControllers"
import CloseIcon from '@mui/icons-material/Close';
import './generateCover.scss'

export default function GenerateCover() {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const token =  params.get('token') || localStorage.getItem("coverToken");
    const [promt, setpromt] = useState("")
    const [error, setError] = useState("")
    const currentUser = useContext(AuthContext);
    const [loading, setLoading] = useState(false)
    const [nbrEssaie, setNbrEssaie] = useState(0)

    const [displayGenerateCover, setDisplayGenerateCover] = useState(false)

    const handleDisplayGenerateCover = () => {
        setDisplayGenerateCover(!displayGenerateCover)
    }

    const handleSetPromt = (event) => {
        setpromt(event.target.value)
    }

    const coverGenerateData = useQuery(["coverGenerateData"], () =>
        
        axios.get(`/api/covGenerate/get-generateCover`, {
            withCredentials:true
        }).then((res) => {    
            return res.data;
        })
    )

    useEffect(() => {
        const getNbrEssai = () => {
            axios.get("/api/covGenerate/getNbrEssai", {
                withCredentials:true
            }).then((res) =>{ setNbrEssaie(res.data[0].essaie)})
            .catch((error) => {
                console.log(error)
            })
        }

        getNbrEssai()
    }, [])

    const generateImages = async () => {
        setLoading(true)
        const promt2 = promt
        setpromt("")
        setError("")
        axios.post("/api/covGenerate/generateCover", {promt2, token}, {
            withCredentials: true
        })
        .then(response => {
            setLoading(false)
        })
        .catch(err => {
            setError(err.response.data);
            setLoading(false)
        });
    };

    const handleCoverPayement = async() => {
        const res = await axios.post("/api/payement/cover-generate-payement",{currentUser}, {
          withCredentials:true
        })
    
        window.open(res.data.url, '_blank');
    }


    return (
    <div className='w-screen relative justify-center items-center flex flex-col min-h-screen'>
        <div className='w-full max-w-4xl flex flex-col justify-start items-start mb-20 h-screen-90 overflow-y-auto custom-scrollbar '>
            {
                coverGenerateData && 
                coverGenerateData.isSuccess &&
                coverGenerateData.data.map((data, index) => (
                    <div className='w-full flex flex-col justify-start items-center' key={index}>
                        <div className='w-full h-24 flex justify-end items-center'>
                            <div type="text" className='max-w-2xl bg-black-10 text-end px-10 py-2 rounded-full text-white'>
                                {data.prompt}
                            </div>
                        </div>
                        <div className='w-full flex gap-2 items-center flex-wrap mb-20 h-auto'>
                            <div className='w-full h-auto flex flex-col justify-center items-center'>
                                <img src={ "/api/static/generateCover/" + data.imgurl} alt="cover" className='w-full h-auto' />
                                <a href={data.imgurl} download className='text-white'>
                                    Télécharger l'image
                                </a>
                            </div>
                        </div>
                    </div>
                ))
            }
            {
                loading &&
                <div className='mb-10 flex justify-end items-center w-full h-auto gap-4 text-white px-10'>
                    <span>
                        Génération du cover 
                    </span>
                    <Loader type="newtons-cradle" />
                </div>
            }
        </div>
        <div className='w-full max-w-4xl fixed bottom-10 flex gap-2'>
            <button onClick={handleDisplayGenerateCover} className='md:flex hidden w-1/6 h-12 bg-red-600 rounded-full justify-center items-center text-white'>
                Ajout des tokens
            </button>
            <button onClick={handleDisplayGenerateCover} className='md:hidden flex w-12 h-12 bg-red-600 rounded-full justify-center items-center text-white'>
                +
            </button>
            {
                displayGenerateCover &&
                <div className="generate-cover-contain w-full relative">
                <CloseIcon className="icon absolute text-red-600 right-2 top-2 mb-4" onClick={handleDisplayGenerateCover}/>
                Lithyus Music vous propose la génération automatique de vos covers pour vous.
                En choisissant de générer un cover, vous devez payer la somme de 1000 fcfa.
                Vous aurez jusqu'à trois essais supplementaire de génération après le paiement.
                <button className='w-36 h-16 bg-red-500 text-white' onClick={handleCoverPayement}>Continuer</button>
                </div>
            }
            <div className='flex justify-between items-center h-auto w-5/6 min-h-12 rounded-3xl border-2 bg-black border-white'>
                <div className='w-2/12 flex justify-center items-center text-white text-[10px]'>
                    Essaie : {nbrEssaie}
                </div>
                <textarea onChange={handleSetPromt}
                    value={promt}
                    className='max-h-60 resize-none h-auto w-9/12 bg-transparent border-none flex justify-center items-center outline-none text-center text-white placeholder:text-black-10'
                    placeholder='Texte de génération du cover...'
                />
                {
                    loading ?
                    <div className='w-1/12 h-full flex justify-center items-center'>
                        <Loader type="newtons-cradle" />
                    </div>
                    :
                    <div className='w-1/12 h-full flex justify-center items-center' onClick={generateImages}>
                        <IoMdSend className="text-4xl text-white"/>
                    </div>
                }

            </div>
        </div>
        {
            error && 
            <div className='w-full text-red-600 text-center fixed bottom-5'>
                {error}
            </div>
        }
    </div>
    )
}
