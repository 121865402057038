import React, {useState, useContext} from "react"
import axios from 'axios'
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';
import Footer from "../../components/footer/Footer";
import { AuthContext } from "../../controllers/authControllers"
import './addPodcast.scss'
import Loader from "../../components/loading/Loader";
import LeftSideAdmin from "../../components/Slide/LeftSideAdmin";

const getImageSize = (file) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      resolve({ width: img.width, height: img.height });
    };
    img.onerror = () => {
      reject(new Error("Erreur lors du chargement de l'image."));
    };
    img.src = URL.createObjectURL(file);
  });
};

function AddPodcast(){
  const Navigate = useNavigate()
  const currentUser = useContext(AuthContext);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showImage, setShowImage] = useState(null);
  const [showAudio, setShowAudio] = useState(null);
  const [selectedAudio, setSelectedAudio] = useState(null);
  const [audioClass, setAudioClass] = useState("no-audio");
  const [imageError, setImageError] = useState(false);
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);

  const [podcastData, setPodcastData] = useState({
    title:"",
    desc:"",
    episodes:""
  });
  const [errors, setErrors] = useState({
    title:"",
    desc:"",
    episodes:"",
    cover:"",
    music:"",
  });

  const uploadMusic = async () => {
    try {
      const formData = new FormData();
      formData.append("file", selectedAudio);
      const res = await axios.post("/api/musicFile/uploadPodcast", formData, {
        withCredentials:true
      });
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };
  const uploadCov = async () => {
    try {
      const formData = new FormData();
      formData.append("file", selectedImage);
      const res = await axios.post("/api/cov/uploadPodcast", formData, {
        withCredentials:true
      });
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };

  const handleChange = (event) => {
    setPodcastData((prev) => ({
        ...prev, [event.target.name]:event.target.value
    }));
  }

  const mutation = useMutation(
    async(newMusic) => {
      setLoading(true)
      return await axios.post("/api/music/addPodcast", newMusic, {
        withCredentials:true
      });
    },
    {
      onSuccess: async(data) => {
        queryClient.invalidateQueries(["music"]);
        return data.data.id
      },
    }
  );
  const [existMusic, setExistMusic] = useState("")
  const handleClick = async (e) => {
    e.preventDefault();
    const { title, desc, episodes } = podcastData;
    const newErrors = {};

    if (!title){
      newErrors.title= "Veuillez donner le titre du podcast";
    }
    if (!desc){
      newErrors.desc= "Veuillez donner la description du podcast";
    }
    if (!episodes){
      newErrors.episodes= "Veuillez choisir l'episodes";
    }
    if (!selectedAudio){
      newErrors.music = "Veillez choisir une musique";
    }
    if (!selectedImage){
      newErrors.cover = "Veillez choisir le cover du podcast";
    }
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    let musicUrl = "";
    let covUrl = "";
    setLoading(true)
    try{
      if (selectedAudio) musicUrl = await uploadMusic();
      if (selectedImage) covUrl = await uploadCov();
      mutation.mutate({ podcastData, music_cov: covUrl.cov, music_file: musicUrl.music });
      setPodcastData({
        music_title:"",
        music_genre:"",
        music_lang:"",
        date:""
      });
      setSelectedAudio(null);
      setSelectedImage(null);
      Navigate('/dashboard');
    }catch(error)
    {
      console.log(error)
      setLoading(false)
      setExistMusic(error.response.data.message)
    }
  };

  const handleDrop = (event, fileType) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (fileType === "image") {
      setSelectedImage(file);
    } else if (fileType === "audio") {
      setSelectedAudio(file);
    }
  };
  const handleFileClick = (fileType) => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    if (fileType === "image") {
      fileInput.accept = "image/jpg, image/png, image/jpeg";
    } else if (fileType === "audio") {
      fileInput.accept = "audio/wav";
    }
    fileInput.onchange = async (event) => {
      const file = event.target.files[0];
      if (fileType === "image") {
        try {
            setSelectedImage(file);
            setShowImage(URL.createObjectURL(file));
            setImageError(false)
        } catch (error) {
          console.error("Erreur lors de la récupération de la taille de l'image:", error);
        }
      } else if (fileType === "audio") {
        setSelectedAudio(file);
        setAudioClass('have-audio')
        setShowAudio(URL.createObjectURL(file));
      }
    };
    fileInput.click();
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
  };
  return(
      <React.Fragment>
          <div className="addMusic">
              <LeftSideAdmin />
              <div className="music-details">
                  <h2>Informations du podcast</h2>
                  <form className="form" onSubmit={handleClick} method="POST">
                      <input 
                        type="text"
                        name="title"
                        placeholder="Titre"
                        onChange={handleChange}
                        value={podcastData.title}
                      />
                      {errors.title && <span style={{color:'red'}}>{errors.title}</span>}
                      <textarea name="desc" id="" placeholder="Description" value={podcastData.desc} onChange={handleChange} />
                      {errors.desc && <span style={{color:'red'}}>{errors.desc}</span>}
                      <input type="number" name="episodes" id="" placeholder="Episodes" min={0} value={podcastData.episodes} onChange={handleChange}/>
                      {errors.episodes && <span style={{color:'red'}}>{errors.episodes}</span>}
                      <div
                          className={'drag-or-click-field ' + audioClass}
                          onDrop={(event) => handleDrop(event, "audio")}
                          onDragOver={handleDragOver}
                          onDragLeave={handleDragLeave}
                          onClick={() => handleFileClick("audio")}
                      >
                          {selectedAudio ? (
                              <>
                                {/* <p>Audio sélectionné : {selectedAudio.name}</p> */}
                                  <audio controls>
                                    <source src={showAudio} type="audio/wav" />
                                    Votre navigateur ne prend pas en charge l'élément audio.
                                  </audio>
                                <div className="changer-music">
                                  changer
                                </div>
                              </>
                            ) : (
                              <p>
                                Glissez et déposez un fichier audio ici ou cliquez pour sélectionner
                                un fichier audio.
                                <AddIcon className="addAudioIcon" />
                              </p>
                          )}
                      </div>
                      {errors.music && <span style={{color:'red'}}>{errors.music}</span>}
                      <div className="cov">
                          <div
                              className="drag-or-click-field image"
                              onDrop={(event) => handleDrop(event, "image")}
                              onDragOver={handleDragOver}
                              onDragLeave={handleDragLeave}
                              onClick={() => handleFileClick("image")}
                          >
                              {selectedImage ? (
                              <img src={showImage} alt="Selected" />
                              ) : (
                              <p>
                                  Glissez et déposez une image ici ou cliquez pour sélectionner une
                                  image.
                                  <AddIcon className="addAudioIcon"/>
                                  {
                                    imageError
                                      ?
                                        <span style={{color:'red'}}>
                                          la taille de votre image ne correspond pas veillez lire les consignes. <br />
                                        </span>
                                      :null
                                  }
                              </p>
                              
                              )}
                          </div>
                      </div>
                      {
                        imageError
                          ?
                            <span style={{color:'red'}}>
                              Cliquez <a href="https://www.resizepixel.com/">ici</a> pour redimensionner vos images
                            </span>
                          :null
                      }
                      {errors.cover && <span style={{color:'red'}}>{errors.cover}</span>}
                      {
                        loading
                          ?<button disabled>
                            <Loader type="newtons-cradle"/>
                          </button>
                          :<button type="submit">Ajouter Podcast</button>
                      }
                      {(errors.cover || errors.music_title) && <span style={{color:'red'}}>Veillez entrer tous les champs demandés</span>}
                      {
                        existMusic
                          ?<div style={{color:'red'}}>{existMusic}</div>
                          :null
                      }
                  </form>
              </div>
          </div>
          <Footer />
      </React.Fragment>
  )
}

export default AddPodcast;
