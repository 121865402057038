import React from "react"
import './loader.scss'

function Loader({type}){
    return (
        <div className="loader">
            {
                type === "newtons-cradle"
                    ?
                        <div className="newtons-cradle">
                            <div className="newtons-cradle__dot"></div>
                            <div className="newtons-cradle__dot"></div>
                            <div className="newtons-cradle__dot"></div>
                            <div className="newtons-cradle__dot"></div>
                        </div>
                    : type === "spiner"
                        ?<div className="spinner"></div>
                        :null
            }
        </div>
    )
}

export default Loader;