import React from "react";
import "./detailMaisonProduction.scss";

import {useLocation } from "react-router-dom";
import NavBar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import {blue} from "@mui/material/colors";
import Chatbot from "../../components/v2/chatbot";

const DetailMaisonProduction = () => {

    const location = useLocation();
    const {title, topic, contact, content, imagePath} = location.state;

    console.log(title)

    return (
        <div>
            <div className="mainDetail">
                <NavBar page={"propos"}/>
                <Chatbot />
                <div className="detail">
                    <img style={{borderRadius: "30px"}} src={imagePath} alt={title} className="ima1"/>

                    <div className="content-2">
                        <h2 className="h22">{title}</h2>
                        <h4>Type : {topic}</h4>
                        <br/>
                        {content}
                        <br/>
                        <br/>
                        {contact}
                    </div>
                    <div className="ima2">
                        <figure>
                            <img className="thumbnail" src={imagePath} alt={title}/>
                            <figcaption>{title}</figcaption>
                        </figure>
                        <figure>
                            <img className="thumbnail" src={imagePath} alt={title}/>
                            <figcaption>{title}</figcaption>
                        </figure>
                        <figure>
                            <img className="thumbnail" src={imagePath} alt={title}/>
                            <figcaption>{title}</figcaption>
                        </figure>
                    </div>
                </div>

            </div>
            <Footer/>
        </div>
    );
};

export default DetailMaisonProduction;
