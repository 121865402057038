/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";
import { NavLink } from "react-router-dom";

import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import "./footer.scss";

function Footer() {
  return (
    <footer className="footer">
      <div className="content">
        <div className="spotify-widget">
        <iframe
            src="https://open.spotify.com/embed/playlist/37i9dQZF1DX05r4Oy3Ln97?utm_source=generator&theme=0"
            width="100%"
            // height="352"
            allowFullScreen
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            loading="lazy"
        ></iframe>
        </div>
        <div className="footer-bottom">
          <div className="footer-box">
            <div className="footer_column">
              <h4>Réseaux sociaux</h4>
              <ul>
                <NavLink className='link' to='https://www.facebook.com/lithyusmusic'><FacebookOutlinedIcon className="icon"/><li>Facebook</li></NavLink>
                <NavLink className='link' to='https://twitter.com/LithyusMusic'><TwitterIcon className="icon" /><li>Twitter</li></NavLink>
                <NavLink className='link' to='https://www.instagram.com/lithyusmusic/'><InstagramIcon className="icon"/><li>Instagram</li></NavLink>
              </ul>
            </div>
            <div className="footer_column">
              <h4>Pages</h4>
              <ul>
                <NavLink className='link' to='/label'><li>Label</li></NavLink>
                <NavLink className='link' to='/blog'><li>Blog</li></NavLink>
                <NavLink className='link' to='/propos'><li>A propos</li></NavLink>
                <NavLink className='link' to='/contact'><li>Contact</li></NavLink>
              </ul>
            </div>
            <div className="footer_column">
              <h4>Ressources</h4>
              <ul>
                <NavLink className='link' to='/politiqueconfidentialites' style={{fontSize:'12px'}}><li>Politiques de Confidentialités</li></NavLink>
                <NavLink className='link' to='/politiqueVentes' style={{fontSize:'12px'}}><li>Politiques de Ventes</li></NavLink>
                <NavLink className='link' to='/serviceDistributions' style={{fontSize:'12px'}}><li>Politiques de services de distributions</li></NavLink>
                <NavLink className='link' to='/termeConditions' style={{fontSize:'12px'}}><li>Termes et Conditions</li></NavLink>
                <span className='link' style={{fontSize:'12px'}}><li>+1-725-224-8456</li></span>
                <span className='link' style={{fontSize:'12px'}}><li>infos@lithyusmusic.com</li></span>
                <span className='link' style={{fontSize:'12px'}}><li>900 av Rockland, Outremont, H2V3A2</li></span >
              </ul>
            </div>
          </div>
        </div>
      </div>
      <p className="copyright">© 2024 Lithyus Music Group, LLC. Tous droits réservés.</p>
    </footer>
  );
}

export default Footer;
