import "./navbar.scss";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import MenuSharpIcon from "@mui/icons-material/MenuSharp";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import logoLithyus from "../../assets/logoLithyus.webp";
import { CiLogin } from "react-icons/ci";
import {
  CDropdown,
  CButton,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownDivider,
} from "@coreui/react";
import { useFiltre } from "../FiltreContext";

function NavBar({ page }) {
  const [displayMenu, setDisplayMenu] = useState(false);

  const handleDisplayMenu = (event) => {
    event.preventDefault();
    setDisplayMenu(!displayMenu);
  };

  const { setFiltre } = useFiltre();

  const handlePageChange = (selectedPage) => {
    setFiltre(selectedPage);
  };

  return (
    <div className="navbar">
      <div className="tel">
        <div className={"menu " + (displayMenu ? "active" : "inactive")}>
          <div className="top">
            <CloseSharpIcon className="icon" onClick={handleDisplayMenu} />
            <span>LITHYUS MUSIC DISTRIBUTION</span>
          </div>
          <hr />
          <div className="bottom">
            <NavLink
              to="/"
              className={"txt " + (page === "home" ? "active" : "")}
            >
              Accueil
            </NavLink>
            <NavLink
              to="/services"
              className={"txt " + (page === "services" ? "active" : "")}
            >
              Services
            </NavLink>
            <NavLink
              to="/label"
              className={"txt " + (page === "label" ? "active" : "")}
            >
              Label
            </NavLink>
            <div className={"txt " + (page === "blog" ? "active" : "")}>
              <CDropdown variant="btn-group">
                <CButton
                  className={"txt " + (page === "blog")}
                  color=""
                  size="lg"
                >
                  <NavLink
                    to="/propos"
                    className={"txt " + (page === "blog" ? "active" : "")}
                    onClick={() => handlePageChange("Blog")}
                  >
                    A propos
                  </NavLink>
                </CButton>
                <CDropdownToggle
                  color=""
                  className={"txt " + (page === "blog")}
                  size="lg"
                  split
                />
                <CDropdownMenu onClick={handleDisplayMenu} className="CDropdownMenu">
                  <NavLink
                    to="/propos"
                    className="nav-link"
                    onClick={() => handlePageChange("Interviews")}
                  >
                    LithyusMusic
                  </NavLink>
    
                  <NavLink
                    to="/event"
                    className="nav-link"
                    onClick={() => handlePageChange("Monde")}
                  >
                    Evènements
                  </NavLink>
                  <NavLink
                    to="/maisonProduction"
                    className="nav-link"
                    onClick={() => handlePageChange("Monde")}
                  >
                    Repertoires
                  </NavLink>
                  <NavLink
                    to="/contact"
                    className="nav-link"
                    onClick={() => handlePageChange("Monde")}
                  >
                    Contact
                  </NavLink>
                </CDropdownMenu>
              </CDropdown>
            </div>
            <NavLink
              to="/blog"
              className={"txt " + (page === "blog" ? "active" : "")}
            >
              Blog
            </NavLink>
            <NavLink
              to="/podcasts"
              className={"txt " + (page === "contact" ? "active" : "")}
            >
              Podcasts
            </NavLink>
          </div>
        </div>
        {displayMenu ? null : (
          
          <div className="tel-content">
            <div className="tel-content-top">
              <div className="title">
                2000 vues = 80% de financement pour votre prochaine musique. C'est le moment de briller!
              </div>
            </div>
            <div className="tel-content-bottom">
              <div className="content-icon" onClick={handleDisplayMenu}>
                <MenuSharpIcon className="icon" />
              </div>
              <div className="image">
                <NavLink to="/">
                  <img src={logoLithyus} alt="logo de lithyus" />
                </NavLink>
              </div>
              <div className="btn">
                <NavLink to="/login">
                  <CiLogin style={{color:'red', fontSize:'25px', fontWeight:'200'}}/>
                  <button>Login/Signup</button>
                </NavLink>
              </div>
            </div>
          </div>
        )}
      </div>


      <div className="ordinateur">
        <div className="top">
          <div className="title">
            2000 vues = 80% de financement pour votre prochaine musique. C'est le moment de briller!
          </div>
        </div>
        <div className="bottom">
          <div className="left">
            <NavLink to="/">
              <img src={logoLithyus} alt="logo de lithyus" />
            </NavLink>
          </div>
          <div className="middle">
            <div className={"content-txt " + (page === "home" ? "active" : "")}>
              <NavLink
                to="/"
                className={"txt " + (page === "home" ? "active" : "")}
              >
                Accueil
              </NavLink>
            </div>
            <div
              className={"content-txt " + (page === "services" ? "active" : "")}
            >
              <NavLink
                to="/services"
                className={"txt " + (page === "home" ? "active" : "")}
              >
                Services
              </NavLink>
            </div>
            <div className={"content-txt " + (page === "label" ? "active" : "")}>
              <NavLink
                to="/label"
                className={"txt " + (page === "home" ? "active" : "")}
              >
                Label
              </NavLink>
            </div>
            {/* <div className={"content-txt " + (page === "propos" ? "active" : "")}>
              <NavLink
                to="/propos"
                className={"txt " + (page === "home" ? "active" : "")}
              >
                A propos
              </NavLink>
            </div> */}
            <div className={"content-txt " + (page === "propos" ? "active" : "")}>
              <CDropdown variant="btn-group">
                <CButton
                  className={"txt " + (page === "blog")}
                  color=""
                  size="lg"
                >
                  <NavLink
                    className={"txt " + (page === "blog" ? "active" : "")}
                    onClick={() => handlePageChange("Blog")}
                  >
                    A propos
                  </NavLink>
                </CButton>
                <CDropdownToggle
                  color=""
                  className={"txt " + (page === "blog")}
                  size="lg"
                  split
                />
                <CDropdownMenu className="CDropdownMenu">
                  <NavLink
                    to="/propos"
                    className={"txt " + (page === "propos" ? "active" : "")}
                  >
                    Lithyus Music
                  </NavLink>
                  <CDropdownDivider />
                  <NavLink
                    to="/event"
                    className={"txt " + (page === "propos" ? "active" : "")}
                    onClick={() => handlePageChange("Monde")}
                  >
                    Evènements
                  </NavLink>
                  <CDropdownDivider />
                  <NavLink
                    to="/maisonProduction"
                    className={"txt " + (page === "propos" ? "active" : "")}
                    onClick={() => handlePageChange("Conseils")}
                  >
                    Repertoires
                  </NavLink>
                  <CDropdownDivider />
                  <NavLink
                    to="/contact"
                    className={"txt " + (page === "propos" ? "active" : "")}
                    onClick={() => handlePageChange("Conseils")}
                  >
                    Contact
                  </NavLink>
                </CDropdownMenu>
              </CDropdown>
            </div>
            <div
              className={"content-txt " + (page === "blog" ? "active" : "")}
            >
              <NavLink
                to="/blog"
                className={"txt " + (page === "home" ? "active" : "")}
              >
                Blog
              </NavLink>
            </div>
            <div
              className={"content-txt " + (page === "podcasts" ? "active" : "")}
            >
              <NavLink
                to="/podcasts"
                className={"txt " + (page === "home" ? "active" : "")}
              >
                Podcasts
              </NavLink>
            </div>
          </div>
          <div className="right">
            <NavLink to="/login">
              <button className="btn1">Connexion</button>
            </NavLink>
            <NavLink to="/signup">
              <button className="btn2">Inscription</button>
            </NavLink>
        </div>
        </div>
      </div>
    </div>
  );
}

export default NavBar;