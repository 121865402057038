import Footer from '../../components/footer/Footer'
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Slider from '../../components/Slider/Slider';
import React, { useState, useRef } from 'react'
import song from '../../assets/music/audio.wav'
import ControlPanel from '../../components/controls/ControPannel';
import image2 from '../../assets/images/100.webp'
import image13 from '../../assets/images/2.jpg'
import image3 from '../../assets/images/3.webp'
import image4 from '../../assets/images/3prime.webp'
import image5 from '../../assets/images/4.jpg'
import image6 from '../../assets/images/4prime.webp'
import image7 from '../../assets/images/5.webp'
import image8 from '../../assets/images/5prime.webp'
import image9 from '../../assets/images/5prmeprime.webp'
import image10 from '../../assets/images/motif.webp'
import image11 from '../../assets/images/Etoiles.png'
import image12 from '../../assets/images/101.jpg'
import logoAmazon from '../../assets/images/logoAmazon.png'
import logoAudius from '../../assets/images/logo_audius.png'
import logoSpotify from '../../assets/images/logospotify.png'
import logoApple from '../../assets/images/logo_apple_music.png'
import logoDeezer from '../../assets/images/logodeezer.png'
import logoYoutubeMusic from '../../assets/images/youtube.png'
import logoBoomPlay from '../../assets/images/boomPlay.png'
import logoAudioMack from '../../assets/images/audioMack.png'
import logoPandora from '../../assets/images/pandora.png'
import logoShazam from '../../assets/images/shazam.png'
import logoIheart from '../../assets/images/iheart.png'
import inacheve from '../../assets/images/inacheve.jpeg'
import bg from '../../assets/images/bg.png'
import './home.scss'
import Navbar from '../../components/navbar/Navbar';
import { NavLink } from 'react-router-dom';
import Chatbot from '../../components/v2/chatbot';

function Home(){
    const audioRef = useRef()
    const [percentage, setPercentage] = useState(0)
    const [isPlaying, setIsPlaying] = useState(false)
    const [duration, setDuration] = useState(0)
    const [currentTime, setCurrentTime] = useState(0)
    const onChange = (e) => {
        const audio = audioRef.current
        audio.currentTime = (audio.duration / 100) * e.target.value
        setPercentage(e.target.value)
    }

    const play = () => {
        const audio = audioRef.current
        audio.volume = 1
    
        if (!isPlaying) {
          setIsPlaying(true)
          audio.play()
        }
    
        if (isPlaying) {
          setIsPlaying(false)
          audio.pause()
        }
    }


    const getCurrDuration = (e) => {
        const percent = ((e.currentTarget.currentTime / e.currentTarget.duration) * 100).toFixed(2)
        const time = e.currentTarget.currentTime
    
        setPercentage(+percent)
        setCurrentTime(time.toFixed(2))
    }
    return(
        <React.Fragment>
        <Navbar page='home'/>
        <Chatbot />
        <div className="home">
            <div className="telephone">
                <div className="first-block">
                    <div className="content">
                        <span className='title'>Predisez l'avenir</span>
                        <span className='title'>en</span>
                        <span className='title'>le créant</span>
                        <span className='desc'>
                            Devenir célèbre, c'est bien, maîtriser sa communication et son image, c'est encore mieux! Nous regroupons 
                            l'ensemble de ces critères pour faire de vous un artiste incontournable.
                        </span>
                    </div>
                </div>
                <div className="second-block">
                    <h2 className='title'>LE PANAFRICANISME A UN AUTRE NIVEAU</h2>
                    <div className="content">
                        <div className="left">
                            <span>
                                Nous sommes un service de distribution de musique pour les africains, par des africains.
                            </span>
                            <Stack>
                                <NavLink to='/services'><Button variant="contained" sx={{ backgroundColor: 'red', width: '150px', borderRadius:'12px' }}>Voir plus</Button></NavLink>
                            </Stack>
                        </div>
                        <div className="beautiful-girl-right">
                            <div>

                            <img src={image13} alt="beautgirl" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="third-block">
                    <h2>NOTRE SAVOIR FAIRE A VOTRE SERVICE</h2>
                    <div className="content">
                        <div className="box">
                            <div className="box-content">
                                <img src={image3} alt="distribution gratuite" />
                                <div className="comment">
                                    <span>distribution gratuite</span>
                                </div>
                            </div>
                            <div className="box-content">
                                <img src={image4} alt="Mise en ligne rapide" />
                                <div className="comment">
                                    Mise en ligne rapide
                                </div>
                            </div>
                        </div>
                        <div className="box">
                            <div className="box-content">
                                <img src={image5} alt="Données en temps réels" />
                                <div className="comment">
                                    Données en temps réels
                                </div>
                            </div>
                            <div className="box-content">
                                <img src={image6} alt="Service client efficace" />
                                <div className="comment">
                                    Service client efficace
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="fourth-block">
                    <h2>LES PLATEFORMES DE DISTRIBUTION</h2>
                    <div className="content">
                        <div className="box">
                            <img src={logoAmazon} alt="logo amazon" />
                            <img src={logoAudius} alt="logo audius" />
                            <img src={logoSpotify} alt="logo spotify" />
                            <img src={logoYoutubeMusic} alt="logo Youtube Music" />
                        </div>
                        <div className="box">
                            <img src={logoSpotify} alt="logo amazon" />
                            <img src={logoApple} alt="logo apple" />
                            <img src={logoDeezer} alt="logo deezer" />
                            <img src={logoBoomPlay} alt="logo BoomPlay" />
                        </div>
                        <div className="box">
                            <img src={logoAudioMack} alt="logo Audio Mack" />
                            <img src={logoPandora} alt="logo Pandora" />
                            <img src={logoShazam} alt="logo shazam" />
                            <img src={logoIheart} alt="logo iHeart" />
                        </div>
                    </div>
                </div>
                <div className="five-block">
                    <h2>FAITES DE VOTRE CARRIERE, LA PLUS BELLE DES MUSIQUES !</h2>
                    <div className="content">
                        <div className="box1">
                            <img src={image9} alt="Grande mélodie" />
                        </div>
                        <div className='box2'>
                            <img src={image8} alt="fou de danse" />
                            <img src={image7} alt="Inspiration" />
                        </div>
                    </div>
                </div>
                <div className="six-block">
                    <img src={bg} alt="" />
                    <div className="content">
                        <div className="six-block-top">
                            <div className="title">
                                Lithyus Music Distribution
                            </div>
                            <img src={inacheve} alt="kayawoto" />
                            <div>
                                <h2>inachevé, Vol. 1</h2>
                                <span>THE LORD</span>
                            </div>
                        </div>
                        <div className="audio-player">
                            <Slider percentage={percentage} onChange={onChange} />
                            <audio
                                ref={audioRef}
                                onTimeUpdate={getCurrDuration}
                                onLoadedData={(e) => {
                                setDuration(e.currentTarget.duration.toFixed(2))
                                }}
                                src={song}
                            ></audio>
                            <ControlPanel
                                play={play}
                                isPlaying={isPlaying}
                                duration={duration}
                                currentTime={currentTime}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="ordinateur">
                <div className="block1">
                    <div className="left">
                        <span className='title'>
                            <span>PREDISEZ </span>
                            L'AVENIR EN LE CREANT
                        </span>
                        <span className='desc'>
                            Devenir célèbre, c'est bien, maîtriser sa
                            communication et son image, c'est encore
                            mieux! Nous regroupons l'ensemble de
                            ces critères pour faire de vous un artiste
                            incontournable.
                        </span>
                    </div>
                    <div className="right">
                        <img src={image10} alt="homme avec un micro" />
                    </div>
                </div>
                <div className="block2">
                    <div className='top'>
                        <hr />
                        <h2>Lithyus Music Distribution</h2>
                    </div>
                    <div className='bottom'>
                        <div className="left">
                            UNE MAISON
                            DE DISTRIBUTION DE
                            MUSIQUE AFRICAINE
                            POUR LES AFRICAINS,
                            PAR DES AFRICAINS.
                        </div>
                        <div className="right">
                            <img src={image2} alt="" />
                        </div>
                    </div>
                </div>
                <div className="block3">
                    <h2>NOTRE SAVOIR FAIRE A VOTRE SERVICE</h2>
                    <div className="content">
                        <div className="box">
                            <div className="box-content">
                                <img src={image3} alt="distribution gratuite" />
                                <div className="comment">
                                    <span>distribution gratuite</span>
                                </div>
                                <div className="desc">
                                    Grace à nous, vous
                                    serez sur les
                                    plateformes de
                                    streaming sans
                                    avoir à dépenser
                                    quoi que ce soit.
                                </div>
                            </div>
                            <div className="box-content">
                                <img src={image4} alt="Mise en ligne rapide" />
                                <div className="comment">
                                    Mise en ligne rapide
                                </div>
                                <div className="desc">
                                    Plus de délai, vous
                                    verrez vos
                                    musiques sur les
                                    plateformes en
                                    moins de 14 jours.
                                </div>
                            </div>
                        </div>
                        <div className="box">
                            <div className="box-content">
                                <img src={image5} alt="Données en temps réels" />
                                <div className="comment">
                                    Données en temps réels
                                </div><div className="desc">
                                    Nous vous ferons
                                    le point de
                                    manière
                                    mensuelle sur vos
                                    streams afin que
                                    vous puissiez
                                    cerner les
                                    principaux points.
                                </div>
                            </div>
                            <div className="box-content">
                                <img src={image6} alt="Service client efficace" />
                                <div className="comment">
                                    Service client efficace
                                </div>
                                <div className="desc">
                                    Vous avez des
                                    questions? ou des
                                    préoccupations?
                                    Bénéficiez de
                                    notre service client
                                    le plus complet
                                    H24/7j
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="block4">
                    <h2>LES PLATEFORMES SUR LESQUELLES VOUS SEREZ DISTRIBUES</h2>
                    <div className="content">
                        <div className="box">
                            <img src={logoAmazon} alt="logo amazon" />
                            <img src={logoAudius} alt="logo audius" />
                            <img src={logoSpotify} alt="logo spotify" />
                            <img src={logoYoutubeMusic} alt="logo Youtuber Music" />
                        </div>
                        <div className="box">
                            <img src={logoSpotify} alt="logo amazon" />
                            <img src={logoApple} alt="logo apple" />
                            <img src={logoDeezer} alt="logo deezer" />
                            <img src={logoBoomPlay} alt="logo BoomPlay" />
                        </div>
                        <div className="box">
                            <img src={logoAudioMack} alt="logo Audio Mack" />
                            <img src={logoPandora} alt="logo Pandora" />
                            <img src={logoShazam} alt="logo shazam" />
                            <img src={logoIheart} alt="logo iHeart" />
                        </div>
                    </div>
                </div>
                <div className="block5">
                    <h2>FAITES DE VOTRE CARRIERE, LA PLUS BELLE DES MUSIQUES !</h2>
                    <div className="content">
                        <div className="box1">
                            <img src={image2} alt="Grande mélodie" />
                        </div>
                        <div className="box2">
                            <img src={image12} alt="Grande mélodie" />
                        </div>
                        <div className="box3">
                            <img className='img1' src={image11} alt="Grande mélodie" />
                            <img className='img2' src={image9} alt="Grande mélodie" />
                        </div>
                    </div>
                    <div className="txt-btn">
                        <span>Distribuez votre musique avec nous!</span>
                        <NavLink to='/signup'><button>Rejoindre</button></NavLink>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
        </React.Fragment>
    )
}

export default Home;