import React from "react"

function Royalties({royalties}) {

    return(
            <div className="box" key={royalties.idstream}>
                <div className="royaltiesMusic">
                    {royalties.music_title}
                </div>
                <div className="spotify">
                    {royalties.spotify || 0}
                </div>
                <div className="apple">
                    {royalties.deezer || 0}
                </div>
                <div className="google">
                   {royalties.youtube || 0}
                </div>
                <div className="youtube">
                    {royalties.apple_music || 0}
                </div>
                <div className="autre">
                    {royalties.other || 0}
                </div>
                <div className="total">
                    {Math.floor((royalties.spotify + royalties.deezer + royalties.youtube + royalties.apple_music + royalties.other) * 100) / 100 || 0}
                </div>
            </div>
    )
}

export default Royalties;