// Ce composant c'est pour le filtrage en fonction des éléments du dropdown MaisonProduction
import React, { createContext, useContext, useState } from 'react';

const FiltreContext = createContext();

export const FiltreProvider = ({ children }) => {
  const [filtre, setFiltre] = useState('');

  return (
    <FiltreContext.Provider value={{ filtre, setFiltre }}>
      {children}
    </FiltreContext.Provider>
  );
};

export const useFiltre = () => useContext(FiltreContext);
