import React, {useState, useEffect} from "react";
import axios from 'axios'
import { useQuery } from "react-query";

import LoginAdmin from "../admin_login/Login";
import LeftSideAdmin from "../../components/Slide/LeftSideAdmin";

import './artistes.scss'
import Loader from "../../components/loading/Loader";

function Artistes(){
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    
    useEffect(() => {
        const checkAuth = async () => {
            try {
                await axios.post('/api/users/verifyAdmin', {}, {
                    withCredentials:true,
                });
                setIsAuthenticated(true);
            } catch (error) {
                console.error('Erreur lors de la vérification de l\'authentification', error);
            }
        };
        
        checkAuth();
    }, []);

    const artistesData = useQuery(["artistesData"], () =>
        
        axios.get(`/api/users/getArtistesData`, {
            withCredentials:true
        }).then((res) => {    
            return res.data;
        })
    )
    if (isAuthenticated){
        return (
            <div className="artistes">
                <LeftSideAdmin page="artistes"/>
                <div className="artistes-data">
                    <div className="artistes-data-Header">
                        <div>
                            Nom d'artistes
                        </div>
                        <div>
                            Nom
                        </div>
                        <div>
                            Prénom
                        </div>
                        <div className="email">
                            Email
                        </div>
                        <div>
                            Pays
                        </div>
                        <div>
                            Tel
                        </div>
                    </div>
                    {
                        artistesData.error
                            ?"Une erreur s'est produite"
                                :artistesData.isLoading
                                    ?<Loader type="sniper"/>
                                    :artistesData.data.length
                                        ? 
                                            artistesData.data.map((artistes) => (
                                                <div className="artistes-data-Header" key={artistes.idMusicien}>
                                                    <div>
                                                        {artistes.nom_artiste}
                                                    </div>
                                                    <div>
                                                        {artistes.name}
                                                    </div>
                                                    <div>
                                                        {artistes.last_name}
                                                    </div>
                                                    <div className="email">
                                                        <a href={"mailto:" + (artistes.email)}>{artistes.email}</a>
                                                    </div>
                                                    <div>
                                                        {artistes.pays}
                                                    </div>
                                                    <div>
                                                        {artistes.tel}
                                                    </div>
                                                </div>    
                                            ))
                                        : "Pas de donnees pour l'instant"
                    }
                </div>
                <div className="copyright">
                    <span>© 2024 Lithyus Music Group, LLC. Tous droits réservés.</span>
                </div>
            </div>
        )
    }else{
        return <LoginAdmin />
    }
}

export default Artistes;