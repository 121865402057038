import React, { useState } from "react"
import moment from 'moment';
import axios from "axios";

import AutorenewSharpIcon from '@mui/icons-material/AutorenewSharp';
import DoNotDisturbAltSharpIcon from '@mui/icons-material/DoNotDisturbAltSharp';
import CheckSharpIcon from '@mui/icons-material/CheckSharp';
import CloseIcon from '@mui/icons-material/Close';

import Loader from "../loading/Loader";
import { IoIosHelpCircleOutline } from "react-icons/io";

import './music.scss'
import { NavLink } from "react-router-dom";


function Music({music}){
    const [waitDelete, setWaitDelete] = useState(false);
    const [showMore, setShowMore] = useState(false);
    const [askDelete, setAskDelete] = useState(false);
    const handleShowMore = (event) => {
        setShowMore(!showMore);
    }
    const [displayHelpPromute, setDisplayHelpPromute] = useState(false)
    function handleDownload(e) {
        e.preventDefault();
      
        fetch("/api/static/cover/" + music.music_cov)
          .then((response) => response.blob())
          .then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = music.music_cov;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          })
          .catch((error) => console.log(error));
    }
    const handleAskDelete = () => {
        setAskDelete(!askDelete)
    }
    const handleChangeDisplayHelp = (event) => {
        event.preventDefault()
        setDisplayHelpPromute(!displayHelpPromute)
    }
    const handleDelete = async(event) => {
        event.preventDefault();
        try{
            setWaitDelete(true);
            await axios.delete(`/api/music/deleteMusic?musicId=${music.idmusic}`, {
                withCredentials:true
            })
            setWaitDelete(false);
            window.location.reload();
        }catch(error){
            console.log(error)
        }
    }
    const makePayementForMusic = async(event) => {
        event.preventDefault()
        const res = await axios.post("/api/payement/stripe-make-payement", music, {
            withCredentials:true
        })

        window.location.href = res.data.url
    }
    return (
        <div className="add_music">
            {
                askDelete
                    &&
                <div className="reject">
                    <CloseIcon className="icon" onClick={handleAskDelete}/>
                    <h3 style={{textAlign:'center'}}>Voulez vous vraiment supprimer la musique {music.music_title}</h3>
                    <div
                        style={{
                            width:'90%',
                            display:'flex',
                            alignItems:'center',
                            justifyContent:'space-between'
                        }}
                    >
                        <button
                            style={{
                                backgroundColor:'green',
                                width:'100px',
                                border:'none',
                                borderRadius:'8px'
                            }}
                            onClick={handleAskDelete}
                        >Non</button>
                        <button
                            style={{
                                backgroundColor:'red',
                                width:'100px',
                                border:'none',
                                borderRadius:'8px'
                            }}
                            onClick={handleDelete}
                        >Oui</button>
                    </div>
                </div>

            }
            <div className="box" key={music.idmusic}>
                <div className="titre" onClick={handleShowMore}>
                    <img src={"/api/static/cover/" +  music.music_cov} alt={music.music_cov} />
                    <div className="content">
                        <h4>{music.music_title}</h4>
                        <span>{music.nom_artiste}</span>
                    </div>
                </div>
                <div className="date" onClick={handleShowMore}>
                    {moment(music.date_publication).format('YYYY-MM-DD')}
                </div >
                    {music.status === 0
                        ?
                            <div className="status" onClick={handleShowMore}>
                                <div className="statusNoIcon">
                                    <DoNotDisturbAltSharpIcon className="icon"/>
                                </div>
                                <span>Vérification</span>
                            </div>
                        : music.status === 1
                            ?
                                <div className="status" onClick={handleShowMore}>
                                    <div className="statusInIcon">
                                        <AutorenewSharpIcon className="icon"/>
                                    </div>
                                    <span>En cours</span>
                                </div>
                            : music.status === 2
                                ?
                                    <div className="status" onClick={handleShowMore}>
                                        <div className="statusDoIcon">
                                            <CheckSharpIcon className="icon"/>
                                        </div>
                                        <span>Publiée</span>
                                    </div>
                                :
                                <div className="status" onClick={handleShowMore}>
                                    <div className="statusRejectIcon">
                                        <CloseIcon className="icon"/>
                                    </div>
                                    <span>Réfusée</span>
                                </div>
                    }
                    <div className="promute">
                        {
                            music.idpromotion
                            ? <span>
                                {music.payement != 1 
                                    ?
                                        <div className="promute-btn">
                                            <div className="button-pay">
                                                <button className="button-pay" onClick={makePayementForMusic}>
                                                    Payer
                                                </button>
                                                <IoIosHelpCircleOutline
                                                    onMouseEnter={() => setDisplayHelpPromute(true)}
                                                    onMouseLeave={() => setDisplayHelpPromute(false)}
                                                    onClick = {handleChangeDisplayHelp}
                                                />
                                            </div>
                                            <div className={"help-box " + (displayHelpPromute ? "display":"")}>
                                                Vous avez choisi un pack de promotion {music.type} sans éffectuer le paiement, veillez cliquer sur payer afin de faire le paiement et commencer votre promotion
                                            </div>
                                        </div>
                                    : 
                                    <div className="promute-btn">
                                        <button disabled className="pub-in-progress">
                                            PUB en cours
                                        </button>
                                    </div>
                                }
                                </span>
                            : 
                            <div className="promute-btn">
                                <div className="button-make-pub">
                                    <NavLink 
                                        style={{
                                            textDecoration:'none',
                                            color:'white'
                                        }}
                                        to={'/promute-music?musicId=' + music.idmusic}
                                    >
                                        Faire une PUB
                                    </NavLink>
                                    <IoIosHelpCircleOutline
                                        onMouseEnter={() => setDisplayHelpPromute(true)}
                                        onMouseLeave={() => setDisplayHelpPromute(false)}
                                        className='promotion-icon'
                                        onClick = {handleChangeDisplayHelp}
                                    />
                                </div>
                                <div className={"help-box " + (displayHelpPromute ? "display":"")}>
                                    Lithyus Music vous propose de promovoir vos musiques en faisant des campagnes de publicitées pour vous.
                                    Cliquez sur Faire une pub et decouvrez nos offres
                                </div>
                            </div>
                            
                        }
                    </div>
            </div>
            {
                showMore
                    ?
                        <div className="MusicShowMore">
                            <table style={{ borderCollapse: "collapse" }}>
                                <tbody>
                                    <tr>
                                        <td style={{ border: "1px solid #5f6065", padding: "5px" }}>
                                            <div>Artistes</div>
                                            <div>{music.nom_artiste}</div>
                                        </td>
                                        <td style={{ border: "1px solid #5f6065", padding: "5px" }}>
                                            <div>Titre</div>
                                            <div>{music.music_title}</div>
                                        </td>
                                        <td style={{ border: "1px solid #5f6065", padding: "5px" }}>
                                            <div>Genre</div>
                                            <div>{music.music_genre}</div>
                                        </td>
                                        <td style={{ border: "1px solid #5f6065", padding: "5px" }}>
                                            <div>Langue</div>
                                            <div>{music.music_lang}</div>
                                        </td>
                                        <td style={{ border: "1px solid #5f6065", padding: "5px" }}>
                                            <div>Audio</div>
                                            <div className="audio">
                                                <audio controls>
                                                    <source src={"/api/static/music/" + music.music_file} type="audio/wav" />
                                                </audio>
                                            </div>
                                        </td>
                                        <td style={{ border: "1px solid #5f6065", padding: "5px" }}>
                                            <div>Couverture</div>
                                            <div className="imgTelecharge">
                                                <img src={"/api/static/cover/" + music.music_cov} alt={music.music_cov} />
                                                <button onClick={handleDownload} style={{backgroundColor:'transparent', textDecoration:'underline'}}>
                                                    Telecharger
                                                </button>
                                            </div>
                                        </td>
                                        <td style={{ border: "1px solid #5f6065", padding: "5px" }}>
                                            <div>Date d'envoie</div>
                                            <div>{moment(music.date_enregistrement).format('YYYY-MM-DD')}</div>
                                        </td>
                                        <td style={{ border: "1px solid #5f6065", padding: "5px" }}>
                                            <div>Date de publication</div>
                                            <div>{moment(music.date_publication).format('YYYY-MM-DD')}</div>
                                        </td>
                                        <td style={{ border: "1px solid #5f6065", padding: "5px" }}>
                                            <div>
                                                Type de Promotion
                                            </div>
                                            <div>
                                                {
                                                    music.type
                                                }
                                            </div>
                                        </td>
                                        <td style={{ border: "1px solid #5f6065", padding: "5px" }}>
                                        {
                                            waitDelete
                                                ?<button disabled>
                                                <Loader type="newtons-cradle"/>
                                                </button>
                                                :<button onClick={handleAskDelete}>Supprimer</button>
                                        }
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    :null
            }
            <hr />
        </div>
    )
}

export default Music;