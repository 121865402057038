import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';


function groupByPrefix(data) {
  const groupedData = {};

  for (const key in data) {
    if (key.includes("Spotify") || key.includes("Deezer") || key.includes("YouTube")) {
      continue; // Ignore les clés contenant Spotify, Deezer ou YouTube
    }

    const prefix = key.slice(0, 2);
    const value = data[key];

    if (groupedData[prefix]) {
      groupedData[prefix] += value;
    } else {
      groupedData[prefix] = value;
    }
  }

  return groupedData;
}

function AreaChart({chartData}) {
  const [data, setData] = useState([]);
  const [options, setOptions] = useState({});

  useEffect(() => {
    const spotifyData = [
      chartData['01_Spotify'] ? chartData['01_Spotify'] : 0,
      chartData['02_Spotify'] ? chartData['02_Spotify'] : 0,
      chartData['03_Spotify'] ? chartData['03_Spotify'] : 0,
      chartData['04_Spotify'] ? chartData['04_Spotify'] : 0,
      chartData['05_Spotify'] ? chartData['05_Spotify'] : 0,
      chartData['06_Spotify'] ? chartData['06_Spotify'] : 0,
      chartData['07_Spotify'] ? chartData['07_Spotify'] : 0,
      chartData['08_Spotify'] ? chartData['08_Spotify'] : 0,
      chartData['09_Spotify'] ? chartData['09_Spotify'] : 0,
      chartData['10_Spotify'] ? chartData['10_Spotify'] : 0,
      chartData['11_Spotify'] ? chartData['11_Spotify'] : 0,
      chartData['12_Spotify'] ? chartData['12_Spotify'] : 0,
    ]
    const deezerData = [
      chartData['01_Deezer'] ? chartData['01_Deezer'] : 0,
      chartData['02_Deezer'] ? chartData['02_Deezer'] : 0,
      chartData['03_Deezer'] ? chartData['03_Deezer'] : 0,
      chartData['04_Deezer'] ? chartData['04_Deezer'] : 0,
      chartData['05_Deezer'] ? chartData['05_Deezer'] : 0,
      chartData['06_Deezer'] ? chartData['06_Deezer'] : 0,
      chartData['07_Deezer'] ? chartData['07_Deezer'] : 0,
      chartData['08_Deezer'] ? chartData['08_Deezer'] : 0,
      chartData['09_Deezer'] ? chartData['09_Deezer'] : 0,
      chartData['10_Deezer'] ? chartData['10_Deezer'] : 0,
      chartData['11_Deezer'] ? chartData['11_Deezer'] : 0,
      chartData['12_Deezer'] ? chartData['12_Deezer'] : 0,
    ]
  
    const YouTubeData = [
      chartData['01_YouTube'] ? chartData['01_YouTube'] : 0,
      chartData['02_YouTube'] ? chartData['02_YouTube'] : 0,
      chartData['03_YouTube'] ? chartData['03_YouTube'] : 0,
      chartData['04_YouTube'] ? chartData['04_YouTube'] : 0,
      chartData['05_YouTube'] ? chartData['05_YouTube'] : 0,
      chartData['06_YouTube'] ? chartData['06_YouTube'] : 0,
      chartData['07_YouTube'] ? chartData['07_YouTube'] : 0,
      chartData['08_YouTube'] ? chartData['08_YouTube'] : 0,
      chartData['09_YouTube'] ? chartData['09_YouTube'] : 0,
      chartData['10_YouTube'] ? chartData['10_YouTube'] : 0,
      chartData['11_YouTube'] ? chartData['11_YouTube'] : 0,
      chartData['12_YouTube'] ? chartData['12_YouTube'] : 0,
    ]
    const groupedData = groupByPrefix(chartData);
  
    const other = [
      groupedData['01'] ? groupedData['01'] : 0,
      groupedData['02'] ? groupedData['02'] : 0,
      groupedData['03'] ? groupedData['03'] : 0,
      groupedData['04'] ? groupedData['04'] : 0,
      groupedData['05'] ? groupedData['05'] : 0,
      groupedData['06'] ? groupedData['06'] : 0,
      groupedData['07'] ? groupedData['07'] : 0,
      groupedData['08'] ? groupedData['08'] : 0,
      groupedData['09'] ? groupedData['09'] : 0,
      groupedData['10'] ? groupedData['10'] : 0,
      groupedData['11'] ? groupedData['11'] : 0,
      groupedData['12'] ? groupedData['12'] : 0,
    ]
    setData([
        {
            name: 'Spotify',
            data:  spotifyData,
        },
        {
            name: 'Deezer',
            data: deezerData,
        },
        {
            name: 'Youtube',
            data: YouTubeData,
        },
        {
            name: 'Other',
            data: other,
        },
    ]);
    setOptions({
      chart: {
        type:'area',
        height: 350,
        events: {
          selection: function (chart, e) {
            // console.log(new Date(e.xaxis.min))
          }
        },
        toolbar: {
          show: false,
        },
      },
      colors: ['#ff0000', '#00ff00', '#0000ff', '#ff00ff'],
      stroke: {
        curve: 'smooth',
        width:.5,
      },
      dataLabels: {
        enabled: false
      },
      fill: {
        colors: ['#ff0000', '#00ff00', '#0000ff', '#ff00ff'],
        type: "gradient",
        gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.1,
        stops: [0, 90, 100]
        }
      },
      legend: {
        position: 'top',
        horizontalAlign: 'left'
      },
      xaxis: {
        categories: [
          'Jan',
          'Fév',
          'Mars',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ],
      },
      grid: {
        borderColor: "rgba(163, 174, 208, 0.3)",
        show: true,
        yaxis: {
          lines: {
            show: true,
            opacity: 0.5,
          },
        },
        row: {
          opacity: 0.5,
        },
        xaxis: {
          lines: {
            show: false,
          },
        },
    },
    });
  }, [chartData]);

  return (
    <Chart
      options={options}
      series={data}
      type='area'
      width="100%"
      height="300"
      stacked='true'
    />
  );
}

export default AreaChart;
