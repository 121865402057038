// Card component
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import "./articleCard.scss";

const ArticleCard = ({type, title, imageCover, id}) => {
    const [isFullContentShown,] = useState(false);
    const MAX_CHARACTERS = 150;


    const getLimitedContent = (text) => {
        if (isFullContentShown) {
            return "";
        } else if (text.length > MAX_CHARACTERS) {
            return text.slice(0, MAX_CHARACTERS) + "...";
        } else {
            return text;
        }
    };

    const navigate = useNavigate();
    const handleEnSavoirPlusClick = () => {
        navigate(`/detailBlog/${id}`);
    };

    return (
        <div className="book" id={id}>
            <div className="content">
                <p className="grandPoint">{getLimitedContent(title)}</p>
                {!isFullContentShown && (
                    <button className="button1" onClick={handleEnSavoirPlusClick}>
                        Voir plus
                    </button>
                )}
            </div>
            <div className="cover">
                <img src={imageCover} alt={type}/>
            </div>
            <div className="image-title">
                <p>{type}</p>
            </div>
        </div>
    );
};

export default ArticleCard;
