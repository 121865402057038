import React, {useEffect, useState} from 'react';
import './detailBlog.scss';
import {useParams} from "react-router-dom";
import {articles} from "../blog/articlesdb";
import ArticleCard from "../../components/articleCard/ArticleCard";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {useMediaQuery} from 'react-responsive';
import ReactMarkdown from 'react-markdown';
import PropTypes from "prop-types";
import Footer from "../../components/footer/Footer";
import NavBar from "../../components/navbar/Navbar";
import Chatbot from '../../components/v2/chatbot';

const DetailBlog = () => {
    const {id} = useParams();
    const [articleDetails, setArticleDetails] = useState(null);
    const isSmallScreen = useMediaQuery({maxWidth: 950});
    const isMediumScreen = useMediaQuery({minWidth: 951, maxWidth: 1370});

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: isSmallScreen ? 1 : isMediumScreen ? 2 : 3,
        slidesToScroll: isSmallScreen ? 1 : isMediumScreen ? 2 : 3,
    };

    useEffect(() => {
        // Recherchez l'article correspondant à l'ID spécifié dans l'URL
        const article = articles.find(article => article.id === parseInt(id));

        if (article) {
            setArticleDetails(article);
        } else {
            console.error('Article non trouvé');
        }
    }, [id]);

    if (!articleDetails) {
        return <div>Loading...</div>;
    }

    const {
        title,
        auteur,
        date,
        images,
        introduction,
        sectionTitle,
        content,
        imageCover,
            videos
    } = articleDetails;
    const similarArticles = articles.filter(article => article.id !== parseInt(id));

    return (
            <>
                <NavBar page={"blog"} />
                <Chatbot />
                <div className="blog-container1234">
                    {title && <Title text={title}/>}
                    {auteur && <Author name={auteur}/>}
                    {date && <Date name={date}/>}
                    <br/>
                    <br/>
                    {/* Affichage de la première image */}
                    {images[0].src && (
                            <ImageContainer src={images[0].src}
                                            alt={images[0].description}
                                            description={images[0].description}/>
                    )}
                    {introduction && <Introduction intro={introduction}/>}
                    {/* Affichage des sections et du contenu */}


                    {sectionTitle && (
                            <SectionTitle text={sectionTitle[0]}/>
                    )}
                    {videos[0].src && (<YoutubeEmbed embedId={videos[0].src} description={videos[0].description} />  )}
                    {images[1].src  && (
                            <ImageContainer src={images[1].src}
                                            alt={images[1].description}
                                            description={images[1].description}/>
                    )}
                    {content && (
                            <TypeBlock content={content[0]}/>
                    )}


                    {sectionTitle && (
                            <SectionTitle text={sectionTitle[1]}/>
                    )}
                    {videos[1].src && (<YoutubeEmbed embedId={videos[1].src} description={videos[1].description} />  )}
                    {images[2].src && (
                            <ImageContainer src={images[2].src}
                                            alt={images[2].description}
                                            description={images[2].description}/>
                    )}
                    {content && (
                            <TypeBlock content={content[1]}/>
                    )}


                    {sectionTitle && (
                            <SectionTitle text={sectionTitle[2]}/>
                    )}
                    {videos[2].src && (<YoutubeEmbed embedId={videos[2].src} description={videos[2].description} />  )}
                    {images[3].src && (
                            <ImageContainer src={images[3].src}
                                            alt={images[3].description}
                                            description={images[3].description}/>
                    )}
                    {content && (
                            <TypeBlock content={content[2]}/>
                    )}


                    {sectionTitle && (
                            <SectionTitle text={sectionTitle[3]}/>
                    )}
                    {videos[3].src && (<YoutubeEmbed embedId={videos[3].src} description={videos[3].description} />  )}
                    {images[4].src && (
                            <ImageContainer src={images[4].src}
                                            alt={images[4].description}
                                            description={images[4].description}/>
                    )}
                    {content && (
                            <TypeBlock content={content[3]}/>
                    )}


                    {sectionTitle && (
                            <SectionTitle text={sectionTitle[4]}/>
                    )}
                    {videos[4].src && (<YoutubeEmbed embedId={videos[4].src} description={videos[4].description} />  )}
                    {images[5].src && (
                            <ImageContainer src={images[5].src}
                                            alt={images[5].description}
                                            description={images[5].description}/>
                    )}
                    {content && (
                            <TypeBlock content={content[4]}/>
                    )}


                    {sectionTitle && (
                            <SectionTitle text={sectionTitle[5]}/>
                    )}
                    {videos[5].src && (<YoutubeEmbed embedId={videos[5].src} description={videos[5].description} />  )}
                    {images[6].src && (
                            <ImageContainer src={images[6].src}
                                            alt={images[6].description}
                                            description={images[6].description}/>
                    )}
                    {content && (
                            <TypeBlock content={content[5]}/>
                    )}


                    {sectionTitle && (
                            <SectionTitle text={sectionTitle[6]}/>
                    )}
                    {videos[6].src && (<YoutubeEmbed embedId={videos[6].src} description={videos[6].description} />  )}
                    {images[7] && (
                            <ImageContainer src={images[7].src}
                                            alt={images[7].description}
                                            description={images[7].description}/>
                    )}
                    {content && (
                            <TypeBlock content={content[6]}/>
                    )}



                    {sectionTitle && (
                            <SectionTitle text={sectionTitle[7]}/>
                    )}
                    {images[8] && (
                            <ImageContainer src={images[8].src}
                                            alt={images[8].description}
                                            description={images[8].description}/>
                    )}
                    {content && (
                            <TypeBlock content={content[7]}/>
                    )}
                </div>
                <div className="similar-articles1234">
                    <h2>Voir plus d'articles</h2>
                    <div className="articles-container">
                        <Slider {...settings}>
                            {similarArticles.map((article, index) => (
                                    <ArticleCard
                                            key={index}
                                            type={article.title.length > 40 ? article.title.substring(0, 40) + " ..." : article.title}
                                            title={article.title}
                                            content={article.content}
                                            imageCover={article.imageCover.src}
                                            id={article.id}
                                            images={article.images}
                                            auteur={article.auteur}
                                            introduction={article.introduction}
                                            date={article.date}
                                            sectionTitle={article.sectionTitle}
                                    />
                            ))}
                        </Slider>
                    </div>
                </div>
                <Footer />
            </>
    );
};


const ImageContainer = ({src, description}) => {
    return (
            <div className="image-container">
                <img src={src}
                     alt={description}
                     className="centered-image"/>
                <ReactMarkdown className="image-description">{description}</ReactMarkdown>
            </div>
    );
}


const Title = ({text}) => {
    return (
            <h1 className="titleArticle">{text}</h1>
    );
}

const SectionTitle = ({text}) => {
    return (
            <h1 className="subtitle">{text}</h1>
    );
}

const Author = ({name}) => {
    return (
            <div className="author">{name}</div>
    );
}
const Date = ({name}) => {
    return (
            <div className="date1">{name}</div>
    );
}

const TypeBlock = ({content}) => {
    return (
            <div className="type-block">
                <ReactMarkdown>{content}</ReactMarkdown>
            </div>
    );
}
const Introduction = ({intro}) => {
    return (
            <div className="intro">
                <ReactMarkdown>{intro}</ReactMarkdown>
            </div>
    );
}

const YoutubeEmbed = ({ embedId,description }) => (
        <div className="video-responsive">
            <iframe
                    width="853"
                    height="480"
                    description={description}
                    src={`https://www.youtube.com/embed/${embedId}`}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Embedded youtube"
            />
        </div>
);

YoutubeEmbed.propTypes = {
    embedId: PropTypes.string.isRequired
};

export default DetailBlog;
