import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import LeftSide from "../../components/Slide/LeftSide";
import LeftSideAdmin from "../../components/Slide/LeftSideAdmin";
import { AuthContext } from "../../controllers/authControllers"

import profile from "../../assets/images/profile.png";


import './settings.scss'
import Chatbot from "../../components/v2/chatbot";

function Settings({ page }) {
    const currentUser = useContext(AuthContext);
    const [selectedCountry, setSelectedCountry] = useState("");
    const [countries, setCountries] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);
    const [showImage, setShowImage] = useState(null);
    const [modify, setModify] = useState(false);
    const [settingsChangeData, setSettingsChangeData] = useState({
        idMusicien:currentUser.currentUser.idMusicien,
        name:currentUser.currentUser.name || "",
        last_name:currentUser.currentUser.last_name || "",
        nom_artiste:currentUser.currentUser.nom_artiste || "",
        email:currentUser.currentUser.email || "",
        bio:currentUser.currentUser.bio || "",
        tel:currentUser.currentUser.tel || "",
        pays:currentUser.currentUser.pays || "",
        ville:currentUser.currentUser.ville || "",
        adresse:currentUser.currentUser.adresse || "",
        facebook:currentUser.currentUser.facebook || "",
        instagram:currentUser.currentUser.instagram || "",
        twitter:currentUser.currentUser.twitter || "",
        spotify:currentUser.currentUser.spotify || "",
        deezer:currentUser.currentUser.deezer || "",
        apple_music:currentUser.currentUser.apple_music || "",
        password:'',
        confirmPassword:''

    })

    const handleChange = (e) => {
        const { name, value } = e.target;
        setSettingsChangeData({ ...settingsChangeData, [name]: value });
    };

    useEffect(() => {
        axios
          .get("https://restcountries.com/v2/all")
          .then((response) => {
            setCountries(response.data);
          })
          .catch((error) => {
            console.log(error);
          });
      }, []);
    
      const handleCountryChange = (event) => {
        setSelectedCountry(event.target.value);
        const { value } = event.target;
        setSettingsChangeData((prevData) => ({
          ...prevData,
          pays: value
        }));
      };

      const handleModify = () => {
        setModify(!modify);
      }

      const modifyProfile = async () => {
        try {
          const formData = new FormData();
          formData.append("file", selectedImage);
          const res = await axios.post("/api/profile/upload", formData, {
            withCredentials:true
          });
          return res.data;
        } catch (err) {
          console.log(err);
        }
      };
      const makeModify = async () => {
        try{
            if (selectedImage) await modifyProfile();
            const res = await axios.put(`/api/users/modify?userId=${currentUser.currentUser.idMusicien}`, settingsChangeData, {
                withCredentials:true,
            })
            localStorage.setItem("user", JSON.stringify(res.data));
            window.location.reload();
        }catch(error){
            console.log(error);
        }
      }
      const handleDrop = (event, fileType) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];
        setSelectedImage(file);
      };
      const handleFileClick = (fileType) => {
        const fileInput = document.createElement("input");
        fileInput.type = "file";
        if (fileType === "image") {
          fileInput.accept = "image/jpg, image/png";
        } else if (fileType === "audio") {
          fileInput.accept = "audio/wav";
        }
        fileInput.onchange = async (event) => {
            const file = event.target.files[0];
            setSelectedImage(file);
            setShowImage(URL.createObjectURL(file));
        };
        fileInput.click();
      };

      const handleDragOver = (event) => {
        event.preventDefault();
      };
    
      const handleDragLeave = (event) => {
        event.preventDefault();
      };
    return(
        <>
            <div className="settings">
                {
                    page === 'admin'
                        ?<LeftSideAdmin page='parametres'/>
                        :<LeftSide page='parametres'/>
                }
                <Chatbot />
                {
                    !modify
                        ?
                            <div className="rightSide">
                                <div className="profile">
                                    <div className="top">
                                        Parametres du compte
                                    </div>
                                    <div className="bottom">
                                        <div className="left">
                                            {
                                                currentUser.currentUser.profile 
                                                    ?<img src={"/api/static/profile/" +  currentUser.currentUser.profile} alt="profil"/>
                                                    :<img src={profile} alt="profil"/>
                                            }
                                        </div>
                                        <div className="right">
                                            <h4>{currentUser.currentUser.name} {currentUser.currentUser.last_name}</h4>
                                            <span>Artiste</span>
                                            <span>Ouagadougou, Burkina faso</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="personal-infos">
                                    <div className="title">
                                        <h4>
                                            Informations Personelles
                                        </h4>
                                        <button onClick={handleModify}>Modifier</button>
                                    </div>
                                    <div className="content">
                                        <div className="box">
                                            <div className="personal-infos-label">
                                                Nom
                                            </div>
                                            <div className="value">
                                                {currentUser.currentUser.name}
                                            </div>
                                        </div>
                                        <div className="box">
                                            <div className="personal-infos-label">
                                                Prenom
                                            </div>
                                            <div className="value">
                                                {currentUser.currentUser.last_name}
                                            </div>
                                        </div>
                                        <div className="box">
                                            <div className="personal-infos-label">
                                                Nom d'artiste
                                            </div>
                                            <div className="value">
                                                {currentUser.currentUser.nom_artiste}
                                            </div>
                                        </div>
                                        <div className="box">
                                            <div className="personal-infos-label">
                                                Email
                                            </div>
                                            <div className="value">
                                                {currentUser.currentUser.email}
                                            </div>
                                        </div>
                                        <div className="box">
                                            <div className="personal-infos-label">
                                                Numéro de téléphone
                                            </div>
                                            <div className="value">
                                                {currentUser.currentUser.tel}
                                            </div>
                                        </div>
                                        <div className="box">
                                            <div className="personal-infos-label">
                                                Bio
                                            </div>
                                            <div className="value">
                                                {currentUser.currentUser.bio}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="adresse">
                                    <div className="title">
                                        <h4>
                                            Adresse
                                        </h4>
                                    </div>
                                    <div className="content">
                                        <div className="box">
                                            <div className="personal-infos-label">
                                                Pays
                                            </div>
                                            <div className="value">
                                                {currentUser.currentUser.pays}
                                            </div>
                                        </div>
                                        <div className="box">
                                            <div className="personal-infos-label">
                                                Ville
                                            </div>
                                            <div className="value">
                                                {currentUser.currentUser.ville}
                                            </div>
                                        </div>
                                        <div className="box">
                                            <div className="personal-infos-label">
                                                Adresse de Résidence
                                            </div>
                                            <div className="value">
                                                {currentUser.currentUser.adresse}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="social-network">
                                    <div className="title">
                                        <h4>
                                            Reseau Social
                                        </h4>
                                    </div>
                                    <div className="content">
                                        <div className="box">
                                            <div className="personal-infos-label">
                                                Facebook
                                            </div>
                                            <div className="value">
                                                {currentUser.currentUser.facebook}
                                            </div>
                                        </div>
                                        <div className="box">
                                            <div className="personal-infos-label">
                                                Instagram
                                            </div>
                                            <div className="value">
                                                {currentUser.currentUser.instagram}
                                            </div>
                                        </div>
                                        <div className="box">
                                            <div className="personal-infos-label">
                                                Twitter
                                            </div>
                                            <div className="value">
                                                {currentUser.currentUser.twitter}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="site-streaming">
                                    <div className="title">
                                        <h4>
                                            Site de Streaming
                                        </h4>
                                    </div>
                                    <div className="content">
                                        <div className="box">
                                            <div className="personal-infos-label">
                                                spotify
                                            </div>
                                            <div className="value">
                                                {currentUser.currentUser.spotify}
                                            </div>
                                        </div>
                                        <div className="box">
                                            <div className="personal-infos-label">
                                                Apple Music
                                            </div>
                                            <div className="value">
                                                {currentUser.currentUser.apple_music}
                                            </div>
                                        </div>
                                        <div className="box">
                                            <div className="personal-infos-label">
                                                Deezer
                                            </div>
                                            <div className="value">
                                                {currentUser.currentUser.deezer}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        :
                            <div className="rightSideModify">
                                <div className="profile">
                                    <div className="top">
                                        Parametres du compte
                                    </div>
                                    <div className="bottom">
                                        <div className="left">
                                            <div
                                                className="drag-or-click-field image"
                                                onDrop={(event) => handleDrop(event, "image")}
                                                onDragOver={handleDragOver}
                                                onDragLeave={handleDragLeave}
                                                onClick={() => handleFileClick("image")}
                                            >
                                                {selectedImage ? (
                                                    <>
                                                        <img src={showImage} alt="Selected" />
                                                        <p style={{position:'relative', top:'10px', color:'red', textDecoration:'underLine'}}>
                                                            Modifier
                                                        </p>
                                                    </>
                                                    ) : (
                                                    <>
                                                        {
                                                            currentUser.currentUser.profile
                                                                ?<img src={"/api/static/profile/" +  currentUser.currentUser.profile} alt="profil"/>
                                                                :<img src={profile} alt="profile" />
                                                        }
                                                        <p style={{position:'relative', top:'10px', color:'red', textDecoration:'underLine'}}>
                                                            Modifier
                                                        </p>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        <div className="right">
                                            <h4>{currentUser.currentUser.name} {currentUser.currentUser.last_name}</h4>
                                            <span>Artiste</span>
                                            <span>Ouagadougou, Burkina faso</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="personal-infos">
                                    <div className="title">
                                        <h4>
                                            Informations Personelles
                                        </h4>
                                        <button onClick={makeModify}>Save</button>
                                    </div>
                                    <div className="content">
                                        <div className="box">
                                            <label htmlFor="nom">Nom</label>
                                            <input
                                                autoComplete="off"
                                                type="text"
                                                placeholder={settingsChangeData.name ?settingsChangeData.name:'................................................' }
                                                value={settingsChangeData.name}
                                                onChange={handleChange}
                                                name="name"
                                            />
                                        </div>
                                        <div className="box">
                                            <label htmlFor="nom">Prenom</label>
                                            <input
                                                autoComplete="off"
                                                type="text"
                                                placeholder={settingsChangeData.last_name ?settingsChangeData.last_name:'................................................' }
                                                value={settingsChangeData.last_name}
                                                onChange={handleChange}
                                                name="last_name"
                                            />
                                        </div>
                                        <div className="box">
                                            <label htmlFor="nom">Nom Artiste</label>
                                            <input
                                                autoComplete="off"
                                                type="text"
                                                placeholder={settingsChangeData.nom_artiste ?settingsChangeData.nom_artiste:'................................................' }
                                                value={settingsChangeData.nom_artiste}
                                                onChange={handleChange}
                                                name="nom_artiste"
                                            />
                                        </div>
                                        <div className="box">
                                            <label htmlFor="nom">Email</label>
                                            <input
                                                autoComplete="off"
                                                type="email"
                                                placeholder={settingsChangeData.email ?settingsChangeData.email:'................................................' }
                                                value={settingsChangeData.email}
                                                onChange={handleChange}
                                                name="email"
                                            />
                                        </div>
                                        <div className="box">
                                            <label htmlFor="nom">Numéro téléphone</label>
                                            <input
                                                autoComplete="off"
                                                type="text"
                                                placeholder={settingsChangeData.tel ?settingsChangeData.tel:'................................................' }
                                                value={settingsChangeData.tel}
                                                onChange={handleChange}
                                                name="tel"
                                            />
                                        </div>
                                        <div className="box">
                                            <label htmlFor="nom">Bio</label>
                                            <input
                                                autoComplete="off"
                                                type="text"
                                                placeholder={settingsChangeData.bio ?settingsChangeData.bio:'................................................' }
                                                value={settingsChangeData.bio}
                                                onChange={handleChange}
                                                name="bio"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="adresse">
                                    <div className="title">
                                        <h4>
                                            Adresse
                                        </h4>
                                    </div>
                                    <div className="content">
                                        <div className="box">
                                            <label htmlFor="nom">Pays</label>
                                            <select
                                                className="select-form"
                                                id="country"
                                                value={selectedCountry}
                                                onChange={handleCountryChange}
                                                name="pays"
                                            >
                                                <option value="">{settingsChangeData.pays}</option>
                                                {countries.map((country) => (
                                                <option key={country.alpha2Code} value={country.name}>
                                                    {country.name}
                                                </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="box">
                                            <label htmlFor="nom">Ville</label>
                                            <input
                                                autoComplete="off"
                                                type="text"
                                                placeholder={settingsChangeData.ville ?settingsChangeData.ville:'................................................' }
                                                value={settingsChangeData.ville}
                                                onChange={handleChange}
                                                name="ville"
                                            />
                                        </div>
                                        <div className="box">
                                            <label htmlFor="nom">Adresse de residence</label>
                                            <input
                                                autoComplete="off"
                                                type="text"
                                                placeholder={settingsChangeData.adresse ?settingsChangeData.adresse:'................................................' }
                                                value={settingsChangeData.adresse}
                                                onChange={handleChange}
                                                name="adresse"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="social-network">
                                    <div className="title">
                                        <h4>
                                            Réseaux sociaux
                                        </h4>
                                    </div>
                                    <div className="content">
                                        <div className="box">
                                            <label htmlFor="nom">Facebook</label>
                                            <input
                                                autoComplete="off"
                                                type="text"
                                                placeholder={settingsChangeData.facebook ?settingsChangeData.facebook:'................................................' }
                                                value={settingsChangeData.facebook}
                                                onChange={handleChange}
                                                name="facebook"
                                            />
                                        </div>
                                        <div className="box">
                                            <label htmlFor="nom">Instagram</label>
                                            <input
                                                autoComplete="off"
                                                type="text"
                                                placeholder={settingsChangeData.instagram ?settingsChangeData.instagram:'................................................' }
                                                value={settingsChangeData.instagram}
                                                onChange={handleChange}
                                                name="instagram"
                                            />
                                        </div>
                                        <div className="box">
                                            <label htmlFor="nom">Twitter</label>
                                            <input
                                                autoComplete="off"
                                                type="text"
                                                placeholder={settingsChangeData.twitter ?settingsChangeData.twitter:'................................................' }
                                                value={settingsChangeData.twitter}
                                                onChange={handleChange}
                                                name="twitter"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="streaming">
                                    <div className="title">
                                        <h4>
                                            Site de streaming
                                        </h4>
                                    </div>
                                    <div className="content">
                                        <div className="box">
                                            <label htmlFor="nom">Spotify</label>
                                            <input
                                                autoComplete="off"
                                                type="text"
                                                placeholder={settingsChangeData.spotify ?settingsChangeData.spotify:'................................................' }
                                                value={settingsChangeData.spotify}
                                                onChange={handleChange}
                                                name="spotify"
                                            />
                                        </div>
                                        <div className="box">
                                            <label htmlFor="nom">Deezer</label>
                                            <input
                                                autoComplete="off"
                                                type="text"
                                                placeholder={settingsChangeData.deezer ?settingsChangeData.deezer:'................................................' }
                                                value={settingsChangeData.deezer}
                                                onChange={handleChange}
                                                name="deezer"
                                            />
                                        </div>
                                        <div className="box">
                                            <label htmlFor="nom">Apple Music</label>
                                            <input
                                                autoComplete="off"
                                                type="text"
                                                placeholder={settingsChangeData.apple_music ?settingsChangeData.apple_music:'................................................' }
                                                value={settingsChangeData.apple_music}
                                                onChange={handleChange}
                                                name="apple_music"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="password">
                                    <div className="title">
                                        <h4>
                                            Mot de passe
                                        </h4>
                                    </div>
                                    <div className="content">
                                        <div className="box">
                                            <label htmlFor="password">Mot de passe</label>
                                            <input
                                                autoComplete="off"
                                                type="password"
                                                placeholder="**********"
                                                value={settingsChangeData.password}
                                                onChange={handleChange}
                                                name="password"
                                                id="password"
                                            />
                                        </div>
                                        <div className="box">
                                            <label htmlFor="nom">Confirmer Mot de passe</label>
                                            <input
                                                autoComplete="off"
                                                type="password"
                                                placeholder="**********"
                                                value={settingsChangeData.confirmPassword}
                                                onChange={handleChange}
                                                name="confirmPassword"
                                            />
                                        </div>
                                    </div>
                                    <div className="action">
                                        <button className="btn1" onClick={handleModify}>Annuler</button>
                                        <button className="btn2" onClick={makeModify}>Save</button>
                                    </div>
                                </div>

                            </div>
                }
                <div className="copyright">
                    <span>© 2024 Lithyus Music Group, LLC. Tous droits réservés.</span>
                </div>
            </div>
        </>
    )
}

export default Settings;