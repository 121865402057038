import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import "./payement.scss"

const CoverPayementAccept = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const token = params.get('token');
  const [disabledButton, setDisabledButton] = useState(false)

  async function getEssaie() {
    setDisabledButton(true)
    try {
      await axios.post(`/api/payement/cover-generate-payement-success`, {verifyToken:token}, {
        withCredentials: true,
      });
      localStorage.setItem("coverToken", token)
      window.location.href = `/generate-cover?token=${token}`
    } catch (error) {

    }
  }

  return (
    <div className='payement'>
        <div className="card"> 
            <button type="button" className="dismiss">×</button> 
            <div className="header"> 
                <div className="image">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><g strokeWidth="0" id="SVGRepo_bgCarrier"></g><g strokeLinejoin="round" strokeLinecap="round" id="SVGRepo_tracerCarrier"></g><g id="SVGRepo_iconCarrier"> <path strokeLinejoin="round" strokeLinecap="round" strokeWidth="1.5" stroke="#000000" d="M20 7L9.00004 18L3.99994 13"></path> </g></svg>
                </div> 
                <div className="content">
                    <span className="title">Paiement reussi</span> 
                    <p className="message">Votre paiement a bien reussi. Veillez Cliquer sur le bouton ci-dessous afin de recevoir vos 3 essais et etre redirige vers la page de generation!</p> 
                </div> 
                <div className="actions">
                  {
                    disabledButton ?
                    <button disabled onClick={getEssaie} type="button" className="history">Waiting...</button>
                    :<button onClick={getEssaie} type="button" className="history">Continuer</button>
                  }
                </div> 
            </div> 
        </div>
    </div>
  );
}

export default CoverPayementAccept;