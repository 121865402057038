import React from 'react';
import ReactDOM from 'react-dom/client';
import './style.scss';
import App from './App';
import { DarkModeContextProvider } from "./context/modeContext";
import { AuthContextProvider } from './controllers/authControllers';
import { QueryClient, QueryClientProvider } from "react-query";
import reportWebVitals from './reportWebVitals';
import { FiltreProvider } from './components/FiltreContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
const queryClient = new QueryClient();
root.render(
    <QueryClientProvider client={queryClient}>
      <AuthContextProvider>
        <DarkModeContextProvider>
          <React.StrictMode>
          <FiltreProvider>
            <App />
            </FiltreProvider>
          </React.StrictMode>
        </DarkModeContextProvider>
      </AuthContextProvider>
    </QueryClientProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
