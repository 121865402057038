import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import "./payement.scss"

const PayementAccept = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const id = params.get('id');

  console.log(id)
  useEffect(() => {
    async function verifyEmail() {
      try {
        await axios.post(`/api/payement/payement-success`, {id}, {
          withCredentials: true,
        });
        setTimeout(() => {
          window.location.href = '/login'
        }, 3000);
      } catch (error) {

      }
    }

    verifyEmail();
  }, [id]);

  return (
    <div className='payement'>
        <div className="card"> 
            <button type="button" className="dismiss">×</button> 
            <div className="header"> 
                <div className="image">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><g stroke-width="0" id="SVGRepo_bgCarrier"></g><g stroke-linejoin="round" stroke-linecap="round" id="SVGRepo_tracerCarrier"></g><g id="SVGRepo_iconCarrier"> <path stroke-linejoin="round" stroke-linecap="round" stroke-width="1.5" stroke="#000000" d="M20 7L9.00004 18L3.99994 13"></path> </g></svg>
                </div> 
                <div className="content">
                    <span className="title">Paiement reussi</span> 
                    <p className="message">Votre paiement a bien reussi. Vous serez redirigez vers le tableau de bord du site. Si dans quelques instant vous n'etes pas redirigez, veillez Cliquer sur le bouton ci-dessous!</p> 
                </div> 
                <div className="actions">
                    <button type="button" className="history">Tableau de bord</button>
                </div> 
            </div> 
        </div>
    </div>
  );
}

export default PayementAccept;