import React, {useState, useEffect } from 'react'
import axios from 'axios'
import moment from 'moment';
import { useQuery } from "react-query";
import LeftSideAdmin from '../../components/Slide/LeftSideAdmin';
import LoginAdmin from '../admin_login/Login';
import Loader from '../../components/loading/Loader';

import './sendMail.scss'

const SendEmail = () => {
    const [data, setData] = useState({
        sujet:'',
        message:''
    })
    const [res, setRes] = useState('')
    const [errors, setErrors] = useState({
        sujet:'',
        message:''
    })

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value });
    };

    const handleSendEmail = async (event) => {
        event.preventDefault();
        const {sujet, message} = data;
        const newErrors = {};
        if (!sujet) {
            newErrors.sujet = "Sujet vide";
        }
        if (!message) {
            newErrors.message = "Message vide";
        }
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }
        try{
            const response = await axios.post('/api/email/sendEmail', {data}, {
                withCredentials:true
            })
            setRes(response.data.message)
        }catch(error){
            console.log(error);
        }
    }

    const [isAuthenticated, setIsAuthenticated] = useState(false);

    const emailData = useQuery(["emailData"], () =>
        
        axios.get(`/api/email/getEmail`, {
            withCredentials:true
        }).then((res) => {    
            return res.data;
        })
    )
    console.log(emailData.data)
    useEffect(() => {
        const checkAuth = async () => {
            try {
                await axios.post('/api/users/verifyAdmin', {}, {
                    withCredentials:true,
                });
                setIsAuthenticated(true);
            } catch (error) {
                console.error('Erreur lors de la vérification de l\'authentification', error);
            }
        };
        checkAuth();
    }, []);
    if (isAuthenticated){
        return (
            <div className="sendmail">
                <LeftSideAdmin page='sendMail' />
                <div className="sendmail-content">
                    <div className="sendmail-content-left">
                        <label>
                            Sujet
                            <input type="text" name="sujet" id="subject" onChange={handleInputChange}/>
                            {
                                errors.sujet && <span style={{color:'red'}}>{errors.sujet}</span>
                            }
                        </label>
                        <label>
                            Message
                            <textarea name="message" id="message" cols="30" rows="10" onChange={handleInputChange}/>
                            {
                                errors.message && <span style={{color:'red'}}>{errors.message}</span>
                            }
                            {
                                res && <span style={{color:'green'}}>{res}</span>
                            }
                        </label>
                        <button onClick={handleSendEmail}>Envoyer</button>
                    </div>
                    <hr />
                    <div className="sendmail-content-leftRight">
                        {
                            emailData.error
                            ? <span>Une erreur s'est produite</span>
                            : emailData.isLoading
                                ? <Loader type='spiner' />
                                : emailData.data.length
                                ? emailData.data.map((email) => (
                                    <div className="sendmail-content-leftRight-box" key={email.idemail_data}>
                                        <div className="sujet">
                                            <span>
                                                {email.sujet}
                                            </span>
                                            <span className='date'>
                                                {moment(email.date).format('YYYY-MM-DD à hh:mm:ss')}
                                            </span>
                                        </div>
                                        <div className="content">
                                            {email.message}
                                        </div>
                                    </div>
                                )) 
                                :null
                        }
                    </div>
                </div>
            </div>
        )
        
    }else{
        return <LoginAdmin />
    }
}

export default SendEmail;