import React from "react";
import Bar from "../../components/bar/Bar";

function MostGender({gender, music}){
    return(
        <div className="content">
            {
                gender.data.map((gender)=>(
                    <div className="box" key={gender.ordre}>
                        <div className="boxTop">
                            {gender.music_genre}
                        </div>
                        <div className="boxBottom">
                            <span>
                                {music.data
                                    ? Math.floor((gender.nbr / music.data.length) * 100) 
                                    : 0} %
                                </span>
                            <Bar percentage={music.data ? Math.floor((gender.nbr / music.data.length) * 100) : 0}/>
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

export default MostGender;