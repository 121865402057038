import React, {useState, useContext} from "react"
import axios from 'axios'
import { useMutation, useQueryClient } from "react-query";
import { useNavigate, NavLink } from "react-router-dom";
import logo from '../../assets/logoLithyus.webp'
import ClearIcon from '@mui/icons-material/Clear'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import AddIcon from '@mui/icons-material/Add';
import { FaCheck } from "react-icons/fa6";
import Footer from "../../components/footer/Footer";
import { AuthContext } from "../../controllers/authControllers"
import './addMusic.scss'
import Loader from "../../components/loading/Loader";
import { v4 as uuidv4 } from 'uuid';
import CloseIcon from '@mui/icons-material/Close';
import Chatbot from "../../components/v2/chatbot";

const getImageSize = (file) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      resolve({ width: img.width, height: img.height });
    };
    img.onerror = () => {
      reject(new Error("Erreur lors du chargement de l'image."));
    };
    img.src = URL.createObjectURL(file);
  });
};

const getId = async () => {
  let id = uuidv4();
  id = id.replace(/-/g, '').substring(0, 10);

  return id;
};

function AddMusic(){
  const Navigate = useNavigate()
  const currentUser = useContext(AuthContext);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showImage, setShowImage] = useState(null);
  const [showAudio, setShowAudio] = useState(null);
  const [selectedAudio, setSelectedAudio] = useState(null);
  const [audioClass, setAudioClass] = useState("no-audio");
  const [imageError, setImageError] = useState(false);
  const queryClient = useQueryClient();
  const [payement, setPayement] = useState("pro")
  const [loading, setLoading] = useState(false);
  const [displayGenerateCover, setDisplayGenerateCover] = useState(false)

  const handleDisplayGenerateCover = () => {
    setDisplayGenerateCover(!displayGenerateCover)
  }
  const [payementData, setPayementData] = useState({
    amount: 25,
    description: "",
    type: "",
    transaction_id: ''
  });

  const [musicData, setMusicData] = useState({
    music_title:"",
    music_genre:"",
    music_lang:"",
    date:"",
    promute:""
  });
  const [errors, setErrors] = useState({
    music_title:"",
    music_genre:"",
    music_lang:"",
    date:"",
    cover:"",
    music:"",
    promute:""
  });

  const uploadMusic = async () => {
    try {
      const formData = new FormData();
      formData.append("file", selectedAudio);
      const res = await axios.post("/api/musicFile/upload", formData, {
        withCredentials:true
      });
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };
  const uploadCov = async () => {
    try {
      const formData = new FormData();
      formData.append("file", selectedImage);
      const res = await axios.post("/api/cov/upload", formData, {
        withCredentials:true
      });
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };

  const handleChange = (event) => {
    setMusicData((prev) => ({
        ...prev, [event.target.name]:event.target.value
    }));
  }

  const mutation = useMutation(
    async(newMusic) => {
      setLoading(true)
      return await axios.post("/api/music/addMusic", newMusic, {
        withCredentials:true
      });
    },
    {
      onSuccess: async(data) => {
        queryClient.invalidateQueries(["music"]);
        return data.data.id
      },
    }
  );
  const [existMusic, setExistMusic] = useState("")
  const handleClick = async (e) => {
    e.preventDefault();
    const {music_title, music_lang, music_genre, date, promute} = musicData;
    const newErrors = {};

    if (!music_title){
      newErrors.music_title= "Veuillez donner le nom de votre musique";
    }
    if (!music_lang){
      newErrors.music_lang= "Veuillez donner la langue de votre musique";
    }
    if (!music_genre){
      newErrors.music_genre= "Veuillez donner le genre de votre musique";
    }
    if (!selectedAudio){
      newErrors.music = "Veuillez choisir une musique"
    }
    if (!selectedImage){
      newErrors.cover = "Veuillez choisir le cover de votre musique"
    }
    if (!date){
      newErrors.date = "Veuillez selectionner la date de sortie de votre musique"
    }
    if(!promute){
      newErrors.promute = "Veuillez choisir un option entre OUI ou NON"
    }
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    let musicUrl = "";
    let covUrl = "";
    setLoading(true)
    try{
      await axios.post("/api/music/verifyMusic", musicData, {
        withCredentials:true
      });
      if (selectedAudio) musicUrl = await uploadMusic();
      if (selectedImage) covUrl = await uploadCov();
      await mutation.mutateAsync({ musicData, music_cov: covUrl.cov, music_file: musicUrl.music, promotion:payement, transaction_id:payementData.transaction_id });

      if (musicData.promute === 'yes'){

        const res = await axios.post("/api/payement/stripe-checkout",{ payementData, musicData, currentUser}, {
          withCredentials:true
        })

        window.location.href = res.data.url
        //code de cinet q voir plutard peut etre
        // const config = {
        //   method: 'post',
        //   url: 'https://api-checkout.cinetpay.com/v2/payment',
        //   headers: { 
        //     'Content-Type': 'application/json'
        //   },
        //   data : updatedPayementData
        // };
        // await axios(config)
        // .then(function (response) {
        //   const paymentUrl = new URL(response.data.data.payment_url);
        //   Navigate(paymentUrl.pathname);
        //   window.location.href = response.data.data.payment_url;
        // })
        // .catch(function (error) {
        //   console.log(error);
        // });
      }
      setMusicData({
        music_title:"",
        music_genre:"",
        music_lang:"",
        date:""
      });
      setSelectedAudio(null);
      setSelectedImage(null);
      Navigate('/dashboard');
      setLoading(false)
    }catch(error)
    {
      setLoading(false)
      console.log(error)
    }
  };

  const handleChangePayement = async(type) => {
    setPayement(type);
    if (type === 'simple'){
      const updatedPayementData = { ...payementData };
      updatedPayementData.type = "Simple";
      updatedPayementData.amount = 10
      updatedPayementData.description = "Achat du pack simple pour une promotion de la musique"
      updatedPayementData.transaction_id = await getId()
      setPayementData(updatedPayementData)
    }else if (type === 'pro'){
      const updatedPayementData = { ...payementData };
      updatedPayementData.type = "Pro";
      updatedPayementData.amount = 25
      updatedPayementData.description = "Achat du pack pro pour une promotion de la musique"
      updatedPayementData.transaction_id = await getId()
      setPayementData(updatedPayementData)
    }else if (type === 'plus'){
      const updatedPayementData = { ...payementData };
      updatedPayementData.type = "Plus";
      updatedPayementData.amount = 42
      updatedPayementData.description = "Achat du pack plus pour une promotion de la musique"
      updatedPayementData.transaction_id = await getId()
      setPayementData(updatedPayementData)
    }
  }
  const handleDrop = (event, fileType) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (fileType === "image") {
      setSelectedImage(file);
    } else if (fileType === "audio") {
      setSelectedAudio(file);
    }
  };
  const handleFileClick = (fileType) => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    if (fileType === "image") {
      fileInput.accept = "image/jpg, image/png, image/jpeg";
    } else if (fileType === "audio") {
      fileInput.accept = "audio/wav";
    }
    fileInput.onchange = async (event) => {
      const file = event.target.files[0];
      if (fileType === "image") {
        try {
          const imageSize = await getImageSize(file);
          if ((imageSize.width !== imageSize.height)
              || (imageSize.width < 3000 || imageSize.width > 6000)
              || (imageSize.height < 3000 || imageSize.height > 6000)){
            setImageError(true)
            setSelectedImage(null);
            setShowImage(null);
          }else {
            setSelectedImage(file);
            setShowImage(URL.createObjectURL(file));
            setImageError(false)
          }
        } catch (error) {
          console.error("Erreur lors de la récupération de la taille de l'image:", error);
        }
      } else if (fileType === "audio") {
        setSelectedAudio(file);
        setAudioClass('have-audio')
        setShowAudio(URL.createObjectURL(file));
      }
    };
    fileInput.click();
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
  };

  console.log(currentUser)
  return(
      <React.Fragment>
          <div className="addMusic">
            <Chatbot />
              <div className="addMusic-navbar">
                  <div className="logo">
                      <img src={logo} alt="logo de Lithyus" />
                  </div>
                  <div className="respect">
                      <span className="txt">Respectez les consignes donnés et envoyez des musiques de qualité supérieure</span>
                  </div>
                  <div className="cancel">
                      <NavLink to='/dashboard'><ClearIcon /></NavLink>
                  </div>
              </div>
              <div className="music-details">
                  <h2>Détails de la musique</h2>
                  <form className="form" onSubmit={handleClick} method="POST">
                      <input 
                        type="text"
                        name="music_title"
                        placeholder="Tire de la musique"
                        onChange={handleChange}
                        value={musicData.music_title}
                      />
                      {errors.music_title && <span style={{color:'red'}}>{errors.music_title}</span>}
                      <select name="music_genre" onChange={handleChange}>
                          <option value="">Sélectionnez un genre</option>
                          <option value="Alternative">Alternative</option>
                          <option value="Hip Hop/Rap">Hip Hop/Rap2</option>
                          <option value="R&B/Soul">R&B/Soul</option>
                          <option value="Trap">Trap</option>
                          <option value="Hip-Hop / Trap">Hip Hop</option>
                      </select>
                      {errors.music_genre && <span style={{color:'red'}}>{errors.music_genre}</span>}
                      <select
                        name="music_lang"
                        onChange={handleChange}
                        id="selectlanguage1"
                      >
                          <option value="">Sélectionnez une langue</option>
                          <option value="français">Français</option>
                          <option value="anglais">Anglais</option>
                          <option value="mooré">Mooré</option>
                      </select>
                      {errors.music_lang && <span style={{color:'red'}}>{errors.music_lang}</span>}
                      <div
                          className={'drag-or-click-field ' + audioClass}
                          onDrop={(event) => handleDrop(event, "audio")}
                          onDragOver={handleDragOver}
                          onDragLeave={handleDragLeave}
                          onClick={() => handleFileClick("audio")}
                      >
                          {selectedAudio ? (
                              <>
                                {/* <p>Audio sélectionné : {selectedAudio.name}</p> */}
                                  <audio controls>
                                    <source src={showAudio} type="audio/wav" />
                                    Votre navigateur ne prend pas en charge l'élément audio.
                                  </audio>
                                <div className="changer-music">
                                  changer
                                </div>
                              </>
                            ) : (
                              <p>
                                Glissez et déposez un fichier audio ici ou cliquez pour sélectionner
                                un fichier audio.
                                <AddIcon className="addAudioIcon" />
                              </p>
                          )}
                      </div>
                      {errors.music && <span style={{color:'red'}}>{errors.music}</span>}
                      <span>
                        Les fichiers audio doivent être au format WAV (en 16 bits, 44,1 kHz, stéréo) et de bonne qualité afin 
                        d'être approuvés pour leur diffusion sur les plateformes de musique. Cliquez <a href="https://convertio.co/" target="_blank" rel="noreferrer">Ici</a> pour modifier vos audios
                      </span>
                      <div className="cov">
                          <div
                              className="drag-or-click-field image"
                              onDrop={(event) => handleDrop(event, "image")}
                              onDragOver={handleDragOver}
                              onDragLeave={handleDragLeave}
                              onClick={() => handleFileClick("image")}
                          >
                              {selectedImage ? (
                              <img src={showImage} alt="Selected" />
                              ) : (
                              <p>
                                  Glissez et déposez une image ici ou cliquez pour sélectionner une
                                  image.
                                  <AddIcon className="addAudioIcon"/>
                                  {
                                    imageError
                                      ?
                                        <span style={{color:'red'}}>
                                          la taille de votre image ne correspond pas veuillez lire les consignes. <br />
                                        </span>
                                      :null
                                  }
                              </p>
                              
                              )}
                          </div>
                              
                          <div className="description">
                            La photo de couverture doit être un fichier .jpg ou .png carré et avoir une résolution minimale de 3000x3000 pixels et maximale de 6000x6000 pixels, sans être flou ou pixelisé. Il ne doit pas contenir les éléments suivants :
                            <span>
                              <FiberManualRecordIcon className="point"/>
                              Logos ou identifiants des réseaux sociaux.
                            </span>
                            <span>
                              <FiberManualRecordIcon className="point"/>
                              Logos de marque.
                            </span>
                            <span>
                              <FiberManualRecordIcon className="point"/>
                              Tout texte, à l'exception des noms d'artistes et/ou du nom de la publication.
                            </span>
                            <span>
                              <FiberManualRecordIcon className="point"/>
                              Si votre photo de couverture contient l'un de ces éléments, votre publication sera rejetée (ces règles 
                              sont établies par les plateformes et nous devons les suivre).
                            </span>
                            <button className="generate-cover" onClick={handleDisplayGenerateCover}>Generer un cover</button>
                            
                            {
                              displayGenerateCover &&
                              <div className="generate-cover-contain relative">
                                <CloseIcon className="icon absolute text-red-600 right-2 top-2 mb-4" onClick={handleDisplayGenerateCover}/>
                                Lithyus Music vous propose la génération automatique de vos covers pour vous.
                                En choisissant de générer un cover, vous devez payer la somme de 1000 fcfa.
                                Vous aurez jusqu'à trois essais supplementaire de génération après le paiement.
                                <a href="/generate-cover" target="_blank"  rel="noopener noreferrer" className="w-36 h-12 bg-red-500 flex justify-center items-center text-white">Continuer</a>
                              </div>
                            }
                          </div>
                      </div>
                      {
                        imageError
                          ?
                            <span style={{color:'red'}}>
                              Cliquez <a href="https://www.resizepixel.com/">ici</a> pour redimensionner vos images
                            </span>
                          :null
                      }
                      {errors.cover && <span style={{color:'red'}}>{errors.cover}</span>}
                      <label htmlFor="music_date">Date de sortie</label>
                      <input type="date" name="date" placeholder="" onChange={handleChange}/>
                      <span className="message-promotion">
                        Envie de propulser votre musique vers de nouveaux sommets ? Ne cherchez pas plus loin ! Avec nos offres 
                        spéciale de promotion musicale, votre talent sera mis en avant comme jamais 
                        auparavant. Atteignez une audience plus large, gagnez en visibilité et faites vibrer les cœurs de fans du 
                        monde entier. Ne laissez pas votre musique rester dans l'ombre, laissez-la briller avec notre promotion 
                        exceptionnelle. Choisissez votre offre des maintenant! 🎶✨
                      </span>
                      <div className="radio-input">
                        <label onClick={() => handleChangePayement('pro')}> 
                          <input type="radio" id="value-1" name="promute" value="yes" onChange={handleChange}/>
                          <span>OUI</span>
                        </label>
                        <label>
                          <input className={musicData.promute === 'no' && "promute-no"} type="radio" id="value-2" name="promute" value="no" onChange={handleChange}/>
                        <span>NON</span>
                        </label>
                        <span className="selection"></span>
                      </div>
                      {errors.promute && <span style={{color:'red'}}>{errors.promute}</span>}
                      <div className={"paiement " + (musicData.promute === "yes" ? 'active': '')}>
                          <div className="paiement-top">
                              <h5>Choix du pack pour la promotion</h5>
                              <p>Simpe et sécurisé</p>
                          </div>
                          <div className="paiement-middle">
                              <h5>Quel type de promotion souhaitez vous faire ?</h5>
                              <div className="paiement-middle-content">
                                  <div className="paiement-middle-content-box" onClick={() => handleChangePayement('simple')}>
                                      <div className={"box " + (payement === 'simple' ? 'active':'')}>
                                          <div className="check-box">
                                              <FaCheck className="icon"/>
                                          </div>
                                          <div className="check-box-content">
                                              <div className="title">Simple</div>
                                              <div className="prix">
                                                <div style={{
                                                  width:'100%',
                                                  textAlign:'center',
                                                  fontSize:'18px'
                                                }}>
                                                  5.000 Fcfa
                                                </div>
                                                <div style={{
                                                  width:'100%',
                                                  textAlign:'center',
                                                  fontSize:'12px'
                                                }}>
                                                  ≈
                                                </div>
                                                <div style={{
                                                  width:'100%',
                                                  textAlign:'center',
                                                  fontSize:'18px'
                                                }}>
                                                  10 $
                                                </div>
                                              </div>
                                              <div className="description">Un marketing qui sera fait au sein des plateformes de streaming 
                                              pour une durée illimité afin de développer votre influence dans l'industrie de la musique.</div>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="paiement-middle-content-box" onClick={() => handleChangePayement('pro')}>
                                      <div className={"box " + (payement === 'pro' ? 'active':'')}>
                                          <div className="check-box">
                                              <FaCheck className="icon"/>
                                          </div>
                                          <div className="check-box-content">
                                              <div className="title">Pro</div>
                                              <div className="prix">
                                                <div style={{
                                                  width:'100%',
                                                  textAlign:'center',
                                                  fontSize:'18px'
                                                }}>
                                                  15.000 Fcfa
                                                </div>
                                                <div style={{
                                                  width:'100%',
                                                  textAlign:'center',
                                                  fontSize:'12px'
                                                }}>
                                                  ≈
                                                </div>
                                                <div style={{
                                                  width:'100%',
                                                  textAlign:'center',
                                                  fontSize:'18px'
                                                }}>
                                                  25 $
                                                </div>
                                              </div>
                                              <div className="description">Une promotion de vos titres musicaux sur les réseaux sociaux, mais aussi au sein des radios internationales afin de développer votre influence.</div>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="paiement-middle-content-box" onClick={() => handleChangePayement('plus')}>
                                      <div className={"box " + (payement === 'plus' ? 'active':'')}>
                                          <div className="check-box">
                                              <FaCheck className="icon"/> 
                                          </div>
                                          <div className="check-box-content">
                                              <div className="title">Plus</div>
                                              <div className="prix">
                                                <div style={{
                                                  width:'100%',
                                                  textAlign:'center',
                                                  fontSize:'18px'
                                                }}>
                                                  25.000 Fcfa
                                                </div>
                                                <div style={{
                                                  width:'100%',
                                                  textAlign:'center',
                                                  fontSize:'12px'
                                                }}>
                                                  ≈
                                                </div>
                                                <div style={{
                                                  width:'100%',
                                                  textAlign:'center',
                                                  fontSize:'18px'
                                                }}>
                                                  42 $
                                                </div>
                                              </div>
                                              <div className="description">On fera une promotion de vos titres sur : les réseaux sociaux, sur les playlists, les radios en plus de faire la promotion de vos titres musicaux sur youtube afin de contribuer à développer encore plus votre notoriété.</div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <span style={{fontSize:'14px', color:'red'}}>Attention le paiement se fait uniquement par <span style={{fontWeight:'bold', fontSize:'16px'}}>carte bancaire</span>. Veuillez donc vous munir de votre <span style={{fontWeight:'bold', fontSize:'16px'}}>carte bancaire</span> avant de passer à l'étape suivante</span>
                      </div>
                      <div className="confirmation">
                          <p className="txt">En cliquant sur « Payer maintenant » vous acceptez les <a href="/politiqueVentes" className="text-blue">conditions générales d'utilisation</a> et les chartes de <a href="/politiqueconfidentialites" className="text-blue">confidentialité</a> de Lithyus Music, ainsi que les règles  
                          tarifaires.</p>                          
                      </div>
                      {
                        loading
                          ?<button disabled>
                            <Loader type="newtons-cradle"/>
                          </button>
                          :<button type="submit">{musicData.promute === 'yes' && <span>Payer et </span>}Ajouter la musique</button>
                      }
                      {(errors.promute || errors.cover || errors.date || errors.music_genre || errors.music_lang || errors.music_title) && <span style={{color:'red'}}>Veuillez entrer tous les champs demandés</span>}
                      {
                        existMusic
                          ?<div style={{color:'red'}}>{existMusic}</div>
                          :null
                      }
                  </form>
              </div>
          </div>
          <Footer />
      </React.Fragment>
  )
}

export default AddMusic;
