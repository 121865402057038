import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';

function BarChart( {status, countryData} ) {
  const [data, setData] = useState([]);
  const [options, setOptions] = useState({});

  let data1;
  let x_data1;
  if (countryData){
    data1 = [
      countryData[0] ? countryData[0].nbr : 0,
      countryData[1] ? countryData[1].nbr : 0,
      countryData[2] ? countryData[2].nbr : 0,
      countryData[3] ? countryData[3].nbr : 0,
      countryData[4] ? countryData[4].nbr : 0
    ];
    x_data1 = [
      countryData[0] ? countryData[0].pays : "",
      countryData[1] ? countryData[1].pays : "",
      countryData[2] ? countryData[2].pays : "",
      countryData[3] ? countryData[3].pays : "",
      countryData[4] ? countryData[4].pays : ""
    ]
  }
  useEffect(() => {
    if (status === 'DailyListen'){

        setData([
            {
                name: 'Spotify',
                data: data1,
            },
        ]);
    }else if (status === "traffic"){
        setData([
            {
                name: 'traffic',
                data: [800, 1200, 1100, 600, 720, 1400, 1500],
            },
        ]);
    }
    const baseOptions = {
        chart: {
            stacked: true,
            toolbar: {
              show: false,
            },
        },
        tooltip: {
            style: {
              fontSize: "12px",
              fontFamily: undefined,
            },
            onDatasetHover: {
              style: {
                fontSize: "12px",
                fontFamily: undefined,
              },
            },
            theme: "dark",
        },
        xaxis: {
            show: false,
            labels: {
              show: true,
              style: {
                colors: "#A3AED0",
                fontSize: "14px",
                fontWeight: "500",
              },
            },
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
        },
        yaxis: {
            show: true,
            color: "black",
            labels: {
              show: true,
              style: {
                colors: "#A3AED0",
                fontSize: "14px",
                fontWeight: "500",
              },
            },
        },
        grid: {
            borderColor: "rgba(163, 174, 208, 0.3)",
            show: true,
            yaxis: {
              lines: {
                show: false,
                opacity: 0.5,
              },
            },
            row: {
              opacity: 0.5,
            },
            xaxis: {
              lines: {
                show: false,
              },
            },
        },
        fill: {
            type: "solid",
            colors: ["#ff1616"],
        },
        legend: {
            show: false,
        },
        colors: ["#5E37FF"],
        dataLabels: {
            enabled: false,
        },
    };
    if (status === "DailyListen") {
        setOptions({
          ...baseOptions,
          xaxis:{
            categories: x_data1,
          },
          plotOptions: {
            bar: {
              borderRadius: 5,
              columnWidth: "10px",
            },
          },
        });
      } else if (status === "traffic"){
        setOptions({
            ...baseOptions,
            xaxis:{
              categories: ["Mon", "Tue", "wed", "Thu", "Fri", "Sat", "Sun"],
            },
            plotOptions: {
                bar: {
                    borderRadius: 15,
                  columnWidth: "30px",
                },
            },
            fill: {
                type: "gradient",
                gradient: {
                  type: "vertical",
                  shadeIntensity: 1,
                  opacityFrom: 0.7,
                  opacityTo: 0.9,
                  colorStops: [
                    [
                      {
                        offset: 0,
                        color: "#ff1616",
                        opacity: 1,
                      },
                      {
                        offset: 100,
                        color: "rgb(118, 118, 118)",
                        opacity: 0.28,
                      },
                    ],
                  ],
                },
            },
        });
      }
    }, [status]);
  return (
    <Chart
      options={options}
      series={data}
      type='bar'
      width="100%"
      height="100%"
      stacked='true'
    />
  );
}

export default BarChart;
