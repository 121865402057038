import React, { useState } from "react";
import axios from "axios";

import CheckSharpIcon from '@mui/icons-material/CheckSharp';
import DoNotDisturbAltSharpIcon from '@mui/icons-material/DoNotDisturbAltSharp';
import AutorenewSharpIcon from '@mui/icons-material/AutorenewSharp';

import './allTransactions.scss'

function AllTransactions({transactions}) {
    const [showMore, setShowMore] = useState(false);

    const handleEnCours = async() => {
        try{
            const res = await axios.get(`/api/transactions/makeEnCours?transactionsId=${transactions.idtransactions}`, {
                withCredentials:true
            })
            console.log(res)
        }catch(error){

        }
        setShowMore(!showMore)
        window.location.reload()
    }
    const handlePublie = async() => {
        try{
            const res = await axios.get(`/api/transactions/makePublie?transactionsId=${transactions.idtransactions}`, {
                withCredentials:true
            })
            console.log(res)
        }catch(error){

        }
        setShowMore(!showMore)
        window.location.reload()
    }
    const handleShowMore = (event) => {
        setShowMore(!showMore)
    }
    return (
        <div className="allTransactions">
            <div className="box" key={transactions.idtransactions} onClick={handleShowMore}>
                <div className="id">
                    #{transactions.idtransactions.split('-')[0]}
                </div>
                <div className="titre">
                    {transactions.nom_artiste}
                </div>
                <div className="montant">
                    {transactions.Montant} $
                </div>
                <div className="methodes">
                    {transactions.methode}
                </div>
                {transactions.status === 0
                    ?
                        <div className="status">
                            <div className="statusNoIcon">
                                <DoNotDisturbAltSharpIcon className="icon"/>
                            </div>
                            <span>non traité</span>
                        </div>
                    : transactions.status === 1
                        ?
                            <div className="status">
                                <div className="statusInIcon">
                                    <AutorenewSharpIcon className="icon"/>
                                </div>
                                <span>En cours</span>
                            </div>
                        :
                            <div className="status">
                                <div className="statusDoIcon">
                                    <CheckSharpIcon className="icon"/>
                                </div>
                                <span>Traité</span>
                            </div>
                }
            </div>
            {showMore 
                ?
                    transactions.methode === 'orange money' || transactions.methode === 'moov money'
                        ?
                            <div className="allTransactionsMore">
                                <table style={{ borderCollapse: "collapse" }}>
                                    <tbody>
                                        <tr>
                                            <td style={{ border: "1px solid #5f6065", padding: "5px" }}>
                                                <div>Artistes</div>
                                                <div>{transactions.nom_artiste}</div>
                                            </td>
                                            <td style={{ border: "1px solid #5f6065", padding: "5px" }}>
                                                <div>Nom & Prénom</div>
                                                <div>{transactions.name}</div>
                                            </td>
                                            <td style={{ border: "1px solid #5f6065", padding: "5px" }}>
                                                <div>Numero de téléphone</div>
                                                <div>{transactions.tel}</div>
                                            </td>
                                            <td style={{ border: "1px solid #5f6065", padding: "5px" }}>
                                                <div>Email</div>
                                                <div>{transactions.email}</div>
                                            </td>
                                            <td style={{ border: "1px solid #5f6065", padding: "5px" }}>
                                                <div>Montant</div>
                                                <div>{transactions.Montant} $</div>
                                            </td>
                                            <td style={{ border: "1px solid #5f6065", padding: "5px" }}>
                                                <button className="enCours" onClick={handleEnCours}>En cours ?</button>
                                                <button className="publie" onClick={handlePublie}>Publié ?</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        : transactions.methode === 'paypal'
                        ?
                            <div className="allTransactionsMore">
                                <table style={{ borderCollapse: "collapse" }}>
                                    <tbody>
                                        <tr>
                                            <td style={{ border: "1px solid #5f6065", padding: "5px" }}>
                                                <div>Artistes</div>
                                                <div>{transactions.nom_artiste}</div>
                                            </td>
                                            <td style={{ border: "1px solid #5f6065", padding: "5px" }}>
                                                <div>Nom & Prénom</div>
                                                <div>{transactions.name}</div>
                                            </td>
                                            <td style={{ border: "1px solid #5f6065", padding: "5px" }}>
                                                <div>Email</div>
                                                <div>{transactions.email}</div>
                                            </td>
                                            <td style={{ border: "1px solid #5f6065", padding: "5px" }}>
                                                <div>Montant</div>
                                                <div>{transactions.Montant} $</div>
                                            </td>
                                            <td style={{ border: "1px solid #5f6065", padding: "5px" }}>
                                                <button className="enCours" onClick={handleEnCours}>En cours ?</button>
                                                <button className="publie" onClick={handlePublie}>Traité ?</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        : transactions.methode === 'wave'
                        ?
                            <div className="allTransactionsMore">
                                <table style={{ borderCollapse: "collapse" }}>
                                    <tbody>
                                        <tr>
                                            <td style={{ border: "1px solid #5f6065", padding: "5px" }}>
                                                <div>Artistes</div>
                                                <div>{transactions.nom_artiste}</div>
                                            </td>
                                            <td style={{ border: "1px solid #5f6065", padding: "5px" }}>
                                                <div>Nom & Prénom</div>
                                                <div>{transactions.name}</div>
                                            </td>
                                            <td style={{ border: "1px solid #5f6065", padding: "5px" }}>
                                                <div>Numero de téléphone</div>
                                                <div>{transactions.tel}</div>
                                            </td>
                                            <td style={{ border: "1px solid #5f6065", padding: "5px" }}>
                                                <div>Montant</div>
                                                <div>{transactions.Montant} $</div>
                                            </td>
                                            <td>
                                                <button className="enCours" onClick={handleEnCours}>En cours ?</button>
                                                <button className="publie" onClick={handlePublie}>Publié ?</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        :null
                :null
            }
        </div>
    )
}

export default AllTransactions;