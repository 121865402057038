import React from "react"
import { Link } from "react-router-dom";
import NavBar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import "./politiqueConfidentialites.scss"

function PolitiqueConfidentialites() {
    return(
        <div className="PolitiqueConfidentialites">
             <NavBar />
             <div className="corps">
                <div className="title">
                    <span>POLITIQUE DE CONFIDENTIALITÉ DE LITHYUS MUSIC GROUP, LLC</span>
                </div>
                <div className="body">
                    <p>
                    Lithyus Music Group, LLC (la « société ») qui opère à travers les marques de commerce Lithyus Music, Lithyus Music Distribution, Lithyus Music Distribution, et s'engage à maintenir de solides mesures de protection de la vie privée pour ses utilisateurs.  Notre Politique de confidentialité (« Politique de confidentialité ») est conçue pour vous aider à comprendre comment nous recueillons, utilisons et protégeons les informations que vous nous fournissez et pour vous aider à prendre des décisions éclairées lors de l'utilisation de notre Service.  
                    
                    <br />Aux fins du présent Contrat, le « Site » désigne le site Web de la Société, accessible à l'adresse lithyusmusic.com.
                    <br />« Service » désigne les services de la Société accessibles via le Site, dans lesquels les utilisateurs peuvent distribuer leur musique sur les plateformes de streaming, en plus de payer pour des offres de promotion digitales via différents packs vendus à travers le site web.
                    Les termes « nous », « notre » et « nos » font référence à la Société.
                    <br />« Vous » fait référence à vous, en tant qu'utilisateur de notre Site ou de notre Service. 
                    <br />En accédant à notre Site ou à notre Service, vous acceptez notre Politique de confidentialité et nos Conditions d'utilisation disponibles ici : (lien vers les termes et conditions d’utilisations), et vous consentez à ce que nous recueillons, stockions, utilisions et divulguions vos Informations personnelles comme décrit dans la présente Politique de confidentialité.

                    </p>

                
                    <span>I. INFORMATIONS QUE NOUS RECUEILLONS</span>
                    <p>
                    Nous recueillons des « renseignements non personnels » et des « renseignements personnels ». Les informations non personnelles comprennent des informations qui ne peuvent pas être utilisées pour vous identifier personnellement, telles que les données d'utilisation anonymes, les informations démographiques générales que nous pouvons collecter, les pages et URL de référence/sortie, les types de plateformes, les préférences que vous soumettez et les préférences générées en fonction des données que vous soumettez et du nombre de clics. Les renseignements personnels comprennent votre courriel, nom, prénoms, nom d’artiste, pays d’origine, numéro de téléphone que vous nous soumettez par le biais du processus d'inscription sur le Site.
                    </p>
                    <span>1. Informations collectées via la technologie</span>
                    <p>
                    Pour activer le Service, vous n'avez pas besoin de soumettre d'Informations personnelles autres que votre adresse e-mail. Pour utiliser le Service par la suite, vous devez soumettre d'autres Informations personnelles telles que votre nom, prénoms, nom d’artiste, pays d’origine, numéro de téléphone. Toutefois, dans le but d'améliorer la qualité du Service, nous suivons les informations qui nous sont fournies par votre navigateur ou par notre application logicielle lorsque vous consultez ou utilisez le Service, telles que le site Web d'où vous venez (connu sous le nom d'« URL de référence »), le type de navigateur que vous utilisez, l'appareil à partir duquel vous vous êtes connecté au Service,  l'heure et la date de l'accès, ainsi que d'autres informations qui ne vous identifient pas personnellement. Nous suivons ces informations à l'aide de cookies ou de petits fichiers texte qui incluent un identifiant unique anonyme. Les cookies sont envoyés au navigateur d'un utilisateur à partir de nos serveurs et sont stockés sur le disque dur de l'ordinateur de l'utilisateur. L'envoi d'un cookie au navigateur d'un utilisateur nous permet de collecter des informations non personnelles sur cet utilisateur et de conserver un enregistrement des préférences de l'utilisateur lors de l'utilisation de nosservices, à la fois sur une base individuelle et agrégée. Par exemple, la Société peut utiliser des cookies pour collecter les informations suivantes :
                    La fréquence de connexion, le pays de connexion et la page d’intérêt.
                    La Société peut utiliser des cookies persistants et de session ; les cookies persistants restent sur votre ordinateur après la fermeture de votre session et jusqu'à ce que vous les supprimiez, tandis que les cookies de session expirent lorsque vous fermez votre navigateur. Nous stockons un cookie persistant pour suivre votre fréquence d’utilisation et faciliter votre connexion.

                    </p>
                    <span>2. Informations que vous nous fournissez en créant un compte</span>
                    <p>
                    En plus des informations fournies automatiquement par votre navigateur lorsque vous visitez le Site, pour devenir abonné au Service, vous devrez créer un profil personnel. Vous pouvez créer un profil en vous inscrivant au Service et en saisissant votre adresse e-mail, et en créant un nom d'utilisateur et un mot de passe. En vous inscrivant, vous nous autorisez à collecter, stocker et utiliser votre adresse e-mail conformément à la présente politique de confidentialité.
                    </p>
                    <span>3. Vie privée des enfants</span>
                    <p>
                    Le Site et le Service ne s'adressent pas aux personnes de moins de 13 ans. Le Site ne recueille ni ne sollicite sciemment des informations auprès de personnes de moins de 13 ans, ni ne permet à toute personne de moins de 13 ans de s'inscrire au Service. Dans le cas où nous apprendrions que nous avons recueilli des informations personnelles auprès d'une personne de moins de 13 ans sans le consentement d'un parent ou d'un tuteur, nous supprimerons ces informations dès que possible. Si vous pensez que nous avons recueilli de telles informations, veuillez nous contacter à l'adresse : infos@lithyusmusic.com. 
                    </p>
                    <span>II. COMMENT NOUS UTILISONS ET PARTAGEONS LES INFORMATIONS</span>
                    <br /><span>Informations personnelles :</span>
                    <p>
                    Sauf indication contraire dans la présente politique de confidentialité, nous ne vendons pas, n'échangeons pas, ne louons pas et ne partageons pas vos renseignements personnels à des fins de marketing avec des tiers sans votre consentement. Nous partageons des informations personnelles avec des fournisseurs qui fournissent des services à la société, tels que les serveurs de nos communications par e-mail qui ont accès à l'adresse e-mail de l'utilisateur dans le but d'envoyer des e-mails de notre part. Ces fournisseurs n'utilisent vos renseignements personnels que sous nos instructions et conformément à notre politique de confidentialité.
                    <br />En général, les renseignements personnels que vous nous fournissez sont utilisés pour nous aider à communiquer avec vous. Par exemple, nous utilisons les informations personnelles pour contacter les utilisateurs en réponse à des questions, solliciter des commentaires des utilisateurs, fournir une assistance technique et informer les utilisateurs des offres promotionnelles.
                    <br />Nous pouvons partager des renseignements personnels avec des tiers si nous croyons de bonne foi que l'accès, l'utilisation, la conservation ou la divulgation des renseignements est raisonnablement nécessaire pour répondre à toute procédure judiciaire applicable ou à toute demande gouvernementale exécutoire ; pour faire respecter les conditions d'utilisation applicables, y compris les enquêtes sur les violations potentielles ; traiter les problèmes de fraude, de sécurité ou techniques ; ou pour protéger contre les atteintes aux droits, à la propriété ou à la sécurité de nos utilisateurs ou du public, comme l'exige ou le permet la loi. 
                    </p>
                    <span>Informations non personnelles</span>
                    <p>
                    En général, nous utilisons des informations non personnelles pour nous aider à améliorer le service et à personnaliser l'expérience utilisateur. Nous regroupons également les informations non personnelles afin de suivre les tendances et d'analyser les modèles d'utilisation sur le site. La présente politique de confidentialité ne limite en aucun cas notre utilisation ou notre divulgation des renseignements non personnels et nous nous réservons le droit d'utiliser et de divulguer ces renseignements non personnels à nos partenaires, annonceurs et autres tiers à notre discrétion.
                    <br />Dans le cas où nous subissons une transaction commerciale telle qu'une fusion, une acquisition par une autre société ou la vente de tout ou partie de nos actifs, vos informations personnelles peuvent faire partie des actifs transférés. Vous reconnaissez et consentez à ce que de tels transferts puissent avoir lieu et soient autorisés par la présente politique de confidentialité, et que tout acquéreur de nos actifs puisse continuer à traiter vos informations personnelles comme indiqué dans la présente politique de confidentialité. Si nos pratiques en matière d'information changent à l'avenir, nous publierons les modifications de la politique sur le site afin que vous puissiez vous désinscrire des nouvelles pratiques en matière d'information. Nous vous suggérons de consulter périodiquement le site si vous êtes préoccupé par la façon dont vos informations sont utilisées.
                    </p>
                    <span>III. COMMENT NOUS PROTÉGEONS LES INFORMATIONS</span>
                    <p>
                    Nous mettons en œuvre des mesures de sécurité conçues pour protéger vos informations contre tout accès non autorisé. Votre compte est protégé par votre mot de passe et nous vous invitons à prendre des mesures pour protéger vos informations personnelles en ne divulguant pas votre mot de passe et en vous déconnectant de votre compte après chaque utilisation. Nous protégeons davantage vos informations contre les failles de sécurité potentielles en mettant en œuvre certaines mesures de sécurité technologiques, notamment le cryptage, les pare-feu et la technologie Secure Socket Layer. Cependant, ces mesures ne garantissent pas que vos informations ne seront pas consultées, divulguées, modifiées ou détruites par la violation de ces pare-feu et logiciels de serveur sécurisés. En utilisant notre Service, vous reconnaissez que vous comprenez et acceptez d'assumer ces risques.
                    </p>
                    <span>IV. VOS DROITS CONCERNANT L'UTILISATION DE VOS INFORMATIONS PERSONNELLES</span>
                    <p>
                    Vous avez le droit à tout moment de nous empêcher de vous contacter à des fins de marketing.  Lorsque nous envoyons une communication promotionnelle à un utilisateur, celui-ci peut se désabonner en suivant les instructions de désabonnement fournies dans chaque e-mail promotionnel. Vous pouvez également indiquer que vous ne souhaitez pas recevoir de communications marketing de notre part dans la section qui y réservée dans vos paramètres utilisateurs. Veuillez noter que nonobstant les préférences promotionnelles que vous indiquez en vous désabonnant ou en vous désabonnant dans les paramètres de votre profil utilisateur sous la barre désinscription du Site, nous pouvons continuer à vous envoyer des e-mails administratifs, y compris, par exemple, des mises à jour périodiques de notre Politique de confidentialité ou tout autre correspondance d’ordre administrative.
                    </p>
                    <span>V. LIENS VERS D'AUTRES SITES WEB</span>
                    <p>
                    Dans le cadre du Service, nous pouvons fournir des liens ou la compatibilité avec d'autres sites Web ou applications. Cependant, nous ne sommes pas responsables des pratiques de confidentialité employées par ces sites Web ou des informations ou du contenu qu'ils contiennent. La présente Politique de confidentialité s'applique uniquement aux informations que nous recueillons par le biais du Site et du Service. Par conséquent, la présente Politique de confidentialité ne s'applique pas à votre utilisation d'un site Web tiers auquel vous accédez en sélectionnant un lien sur notre Site ou via notre Service. Dans la mesure où vous accédez ou utilisez le Service via ou sur un autre site Web ou une autre application, la politique de confidentialité de cet autre site Web ou application s'appliquera à votre accès ou à votre utilisation de ce site ou de cette application. Nous encourageons nos utilisateurs à lire les déclarations de confidentialité d'autres sites Web avant de procéder à leur utilisation.
                    </p>
                    <span>VI. MODIFICATIONS DE NOTRE POLITIQUE DE CONFIDENTIALITÉ</span>
                    <p>
                    La Société se réserve le droit de modifier cette politique et nos Conditions d'utilisation à tout moment.  Nous vous informerons des modifications importantes apportées à notre politique de confidentialité en envoyant un avis à l'adresse e-mail principale spécifiée dans votre compte ou en plaçant un avis bien visible sur notre site. Les changements importants entreront en vigueur 30 jours après cette notification. Les modifications ou clarifications non matérielles prendront effet immédiatement. Vous devez consulter périodiquement le site et cette page de confidentialité pour les mises à jour.
                    </p>
                    <span>VII. CONTACTEZ-NOUS</span>
                    <p>
                    Si vous avez des questions concernant la présente politique de confidentialité ou les pratiques de ce site, veuillez nous contacter en envoyant un courriel à infos@lithyusmusic.com.
                    Dernière mise à jour : La présente politique de confidentialité a été mise à jour pour la dernière fois le 22-05-2024.

                    </p>

                </div>
             </div>
             <Footer/>
        </div>
    )
    

}
export default PolitiqueConfidentialites;