import img from "../../assets/blog/img.png";
import img_1
    from "../../assets/blog/img_1.png";
import img_2 from "../../assets/blog/img_2.png";
import img_3 from "../../assets/blog/img_3.png";
import img_4 from "../../assets/blog/img_4.png";
import img_11 from "../../assets/blog/img_11.png";
import img_6 from "../../assets/blog/img_6.png";
import img_7 from "../../assets/blog/img_7.png";
import img_8 from "../../assets/blog/img_8.png";
import img_9 from "../../assets/blog/img_9.png";
import img_10 from "../../assets/blog/img_10.png";

export const articles = [
    {
        id: 1,
        type: "CONSEILS",
        title: 'Structurer l’industrie musicale Burkinabè: une industrie déséquilibrée…',
        auteur: "Ayed Neya",
        date: "10 min read . Mar 2, 2024",
        introduction: "Le but de cet article est de mettre en lumière l’un des principaux défauts de l’industrie musicale Burkinabè. " +
                "Lorsqu’on analyse l’industrie musicale Burkinabè, on se rend compte que comparer aux autres pays de la sous-région tel " +
                "que le Sénégal, la Cote d’ivoire ou encore même le Nigeria et le Ghana, le Burkina Faso ne bénéficie pas d’une forte " +
                "exportation culturelle de sa culture musicale. Maintenant la question que vous pourrez des lors vous demander c’est " +
                "qu’est-ce qu’on entend par exportation culturelle! Pour" +
                " notre part on attend par exportation culturelle: ‘’ **Par" +
                " exportation artistique ou culturelle, nous entendons une" +
                " musique qui respecte trois critères de base qui sont: une popularité reconnue dans la sous-région ou internationalement, une source d’inspiration qui peut être reproduite à travers une utilisation du style musicale** " +
                "ou de la production et enfin la consommation locale et" +
                " internationale du titre musical ’’.\n" +
                "\n"+
                "Ainsi après avoir défini ce que nous entendons par" +
                " **exportation culturelle**, il convient maintenant de se " +
                "demander pourquoi, le Burkina Faso ne bénéficie pas de" +
                " cela? \n\n" +
                "D’aucuns diront que les musiques locales ne sont pas" +
                " exportables compte tenu de **l’aspect traditionnel des" +
                " chansons** et aussi de **la langue avec lesquelles ces" +
                " dernières sont " +
                "chantés (le Moore)** donc au vu de cela peut constituer un" +
                " frein à cette expansion culturelle. Mais se baser sur de tels" +
                " propos peut s’avérer à la fois vraie et fausse surtout lorsqu’on sait que certains pays voisins comme: " +
                "La Cote d’ivoire, Le Sénégal ou encore même le Nigeria réussirent à exporter leurs musiques, on a pour exemples, " +
                "Meiway de la cote d’ivoire qui réussit cet exploit avec certaines de ses titres chanté en langue locale, (il est " +
                "initiateur d’un style développé par lui appelé le zoblazo ou danse des mouchoirs blancs) comme : ‘’[Miss lolo](https://youtu.be/89E7EY_J0Kc?si=4HnoU1nYJbIgGZRk)’’ " +
                "rendus célèbre dans la sous-région ou encore même Youssou" +
                " N’dour et ses titres dont: ‘’[Djino](https://youtu.be/9GwRQOVNuLw?si=d_RPGJjoN6qwcHtt)’’ qui d’une" +
                " certaine " +
                "manière a contribué à mettre en lumière la culture musicale du Sénégal berce par le Mbalar dont Youssou Ndour contribua " +
                "principalement a développée. Nous avons également Sidiki Diabaté du Mali avec des titres comme ‘’Fais-moi’’ qui fut un " +
                "succès pas que locale mais également sous régionale au vu de la rythmique mandingue qui fut utilisé tant bien que dans " +
                "les paroles, et ce même si dans certains passage celui-ci s’exprimaient en Bambara langue locale malienne mais on remarqua " +
                "que cela ne constitua pas un frein à l’expansion de sa musique dans la sous-région ni même dans le monde quand bien même on" +
                " sait que le temps de celui-ci le poussa à contribuer avec certaines pointures du rap internationale comme Booba dans un de ses" +
                " albums notamment Trône dans le titre: ‘’[Ça va aller](https://youtu.be/rY4q6VXmhLg?si=w2ZenfdnklYjFxTe)’’. Il est très important de souligner que l’ensembles des titres soulignes" +
                " plus haut furent des titres qui connurent des succès énormes dans la sous-région et contribua à mettre en avant les rythmes musicaux " +
                "des différents pays respectifs. Dès lors la question est" +
                " pourquoi eux réussirent ou les Burkinabè échouèrent \n\n" +
                "Face à cette déclaration beaucoup d’avis seront mitiges" +
                " notamment en indiquant le fait que la Musique Burkinabè" +
                " réussit tant bien que mal à s’exporter notamment à travers" +
                " des chanteurs locaux très bien connus tels que Floby qui" +
                " réussit tant bien que mal à se faire connaitre dans la" +
                " sous-région à travers les collaborations qu’il réalisa de" +
                " gauche à droite dont celui qui reste emblématique tant il" +
                " fut réalisé avec l’une des stars régionales en ce temps, j’ai nommé Didier Arafat avec le titre: ‘’[Danse un peu](https://youtu.be/Rfy73ebK6wQ?si=tiDLc2UFc7l7_DGb)’’ afin de développer une certaine notoriété sous-régionale. Néanmoins on vit que ces efforts furent d’une certaine manière récompense mais sur une courte durée car ses chansons ayant accumulées le plus de vu sur YouTube remontent à plusieurs années auparavant car maintenant ce n’est plus le cas maintenant et nous avons aussi Dez Altino qui chante aussi en langue locale et qui réussirent également à se faire connaitre. Pour être honnête, on peut classer ses artistes comme une génération musicale qui tenta cette exportation culturelle dont on fait référence mais qui ne fut pas s’établir sur le long terme et donc qui baissa le rythme et par la suite finit par être enterré. La génération qui suivit ces artistes tenta ou tente encore de suivre ce chemin laisser par ses devanciers mais sur une approche plus novatrice notamment par la manière et l’approche c’est-à-dire en adaptant les nouvelles chansons produites à la génération qui l’écoute et parmi eux, on peut citer des artistes comme tels que [Imilo le chanceux](https://fr.wikipedia.org/wiki/Imilo_Lechanceux), [Hugo Boss](https://lefaso.net/spip.php?article75663=) ex-membre du groupe LastKing226 qui avait amorce une bonne lancée tant leurs morceaux s’inscrivait sur une bonne dynamique qui fut stoppe après leur séparation et tous les problèmes qui s’en suivirent même si l’artiste lui-même (Hugo Boss) eut une carrière ascendante à travers les concerts qu’il réalisa par ci par là en Afrique et des collaborations qu’il fit dont celui notoire avec Mc One de Cote d’ivoire avant de se reconvertir dans les affaires. \n\n" +
                "À travers ainsi ce bref inventaire historique des efforts fournit par les artistes Burkinabè, on peut dès lors se demander pourquoi ces efforts ne fit pas émergés dès lors des artistes qui à leur tour pourrait renforcer cette notoriété que les devanciers tentèrent d’instaurer sur la scène sous-régionale et ce peu importe le genre musical. Pourquoi, n’observons-nous pas une explosion de pépites voulant s’imposer ? **pourquoi la musique Burkinabè tout comme son territoire reste enclaver?**"
        ,
        content: [
            "Lorsqu’on se base sur les trois critères relatifs à l’exportation" +
            " culturelle de la culture musicale Burkinabè tel que défini plus" +
            " haut, on se rend compte que la liste des artistes de nouvelles" +
            " générations répondant ou remplissant ces critères s’amenuisent" +
            " tant peu sont ceux qui remplisse tous les trois. Dès lors" +
            " qu’on évolue selon cette dynamique, on peut citer des artistes" +
            " Burkinabè qui réussirent un semblant d’exportation ( mais qui" +
            " ne peuvent pas être classifie dans ce groupe dit de nouvelles" +
            " générations mais plutôt appartenant à celle précédente), on" +
            " peut dire que ce groupe de musique" +
            " [Yeleen](https://www.bing.com/ck/a?%21=&p=72739077ba4dc133JmltdHM9MTcwODM4NzIwMCZpZ3VpZD0zY2Q4OWJlYi1jODIzLTZhNmQtMjVjYi04ODQ3YzkwYTZiMWYmaW5zaWQ9NTIyMA&ptn=3&ver=2&hsh=3&fclid=3cd89beb-c823-6a6d-25cb-8847c90a6b1f&psq=Yeleen&u=a1aHR0cHM6Ly9mci53aWtpcGVkaWEub3JnL3dpa2kvWWVsZWVu&ntb=1) réussit à exporter leur art musicale au-delà des frontières à travers leur sonorité entrainante, et motivante mais qui n’arrivèrent pas à s’imposer comme musique populaire ni à inspirer une forme de reprise même si dans le fond cette musique réussit à se faire au-delà des frontières Burkinabè( un exemple de titres populaires en son temps, qu’ils sortirent fut: [Sortir de l’exil](https://www.bing.com/ck/a?%21=&p=e05b210930ff246bJmltdHM9MTcwODM4NzIwMCZpZ3VpZD0zY2Q4OWJlYi1jODIzLTZhNmQtMjVjYi04ODQ3YzkwYTZiMWYmaW5zaWQ9NTI1Mw&ptn=3&ver=2&hsh=3&fclid=3cd89beb-c823-6a6d-25cb-8847c90a6b1f&psq=Yeleen&u=a1aHR0cHM6Ly93d3cueW91dHViZS5jb20vd2F0Y2g_dj1Ya3RVdk9vdzBSRQ&ntb=1) , on eut également, le cas de Wassa Wassa de Imilo Le chanceux, qui réussit à faire connaitre sa musique au-delà des frontières et également sa personne. La seule limite qui se posa à cette chanson est le fait que cette musique n’était pas arrivée au niveau d’être reprise par d’autres rappeurs comme source d’inspiration pouvant être reprise car le rythme est entrainant mais pas unique ou propre à la culture. Cependant, on remarquera que par la suite, il ne fut pas le seul dans cette situation et cela nous pousse à nous poser la question de savoir est-ce culturelle ? La musique produite au Burkina Faso, n’est-elle pas exportable? \n " +
            "Ainsi, pour revenir au sujet initial relatif à l’exportation culturelle on se rend compte que cette limite ne permet pas une réelle distinction de la culture. Ce qui laisse la musique produite par les artistes plonger dans une marée de musique identique qui ne se distingue pas de la masse. C’est ainsi que comparément à d’autres artistes d’autres pays comme la Cote d’ivoire et ses musiciens les plus connus comme ‘’Dj Arafat’’, ‘’Suspect 95'’, ‘’Didi b’’ ou encore le Sénégal avec Youssou N’dour qui a travers leurs différents styles comme le coupe décalé pour la cote d’ivoire ou encore le Mbalar pour le Sénégal cité plus haut qui tous les trois répondent à notre catégorisation initiale tant ils remplissent les questions de popularités, consommation et reproduction. Dès lors, on peut souligner que c’est leur particularité qui les fait se distinguer. Ce qui pourrait sous-entendre que la musique Burkinabè n’a rien de particulier ni distinctif et est voue à rester local, c’est un avis sur lequel on pourrait s’arrêter mais non. Cet avis serait bien trop simpliste car il y’a d’autres élément qu’il faudrait prendre en compte dans cette analyse dont:",
            "Le point commun entre les artistes des pays voisins cites" +
            " plus haut est l’existence d’un marché de consommation à la hauteur de la consommation de ladite musique notamment à travers les plateformes de streaming, ou encore à travers la valorisation de cette musique au-delà des frontières nous avons aussi la popularisation ou la démocratisation des concerts et la culture du marketing par les fans de ces différents artistes. Dès lors, on voit que c’est la population qui contribue à accroitre la notoriété de leurs artistes ce qui dès lors permet aux artistes de se faire un nom, l’exemple palpable est bien dj Arafat qui même par ses paroles incompréhensibles aux premiers abords fit un mystère pour les fans de la musique. Ce mystère quant à la signification des paroles de ses chansons poussa ses fans à se demander sils pouvaient répéter ce qu’il chanta ce qu’il fit et donc contribua à augmenter sa renommée.",
            "Dès lors cette renommée pousser par ces fans, accompagnées" +
            " du rythme musical dansant du chanteur on a le mélange parfait" +
            " pour faire de lui une star sous-régionale et lui permettre" +
            " d’être invite à des évènements dehors de son pays et lui" +
            " permettre de bâtir sa carrière musicale. Ainsi, ce qui fait" +
            " suite à cette montée en popularité est le fait qu’il réalisa des collaborations avec des artistes internationaux ce qui renforça cette notoriété acquise. C’est cette même stratégie de développement qu’on retrouve chez les artistes congolais (nous avons pour exemple Fally Ipupa dont l’évolution ne suivit pas forcément la même trajectoire mais **fut propulser par le style propre à celui-ci et a un soutien massif de ses fans à l’intérieur comme à l’extérieur** ce qui peut être légèrement différent de celui Sénégalais. Les Sénégalais ayant une structure un peu renferme de part une forte consommation majoritairement locale se sont leurs rythmes qui s’exportent et on a pu le constater par l’utilisation de leurs rythmes musicaux par certains artistes internationaux comme Booba avec sa chanson [DKR](https://youtu.be/Stet_4bnclk?si=R_9iBfdVR4Xt75hR).",
            "Ou encore même Didi B avec son titre [Shogun](https://youtu.be/cXnZDCD4OV0?si=gRfYeaL3RxZ9Ql2L)",
            "Qui sont des exemples parfaits de cette consommation, puis popularité et reproduction. Dès lors, à partir de ces différents éléments on peut voir quels sont les points de rupture avec les projets musicaux Burkinabè et leurs limites.\n" +
            "\n" +
            "Cependant, il faille qu’on attire votre attention sur le fait que lorsqu’on parle de musique comme vous aurez pu le remarquer durant le long de l’article nous parlions de la musique en générale, ce qui prend en compte la majeure partie des sous-genres du rap en français en incluant la musique en langue locale. Néanmoins, il faut souligner que la nouvelle génération tant à vouloir dynamiser les choses en essayant de changer l’aspect traditionnel de la musique instaurer par les ténors locaux (Floby, Dez Altino, Kayawoto et bien d’autres).\n" +
            "\n" +
            "Mais réussiront-ils?\n" +
            "\n" +
            "Telle est la question qui reste poser surtout lorsqu’on sait à" +
            " quel point les couts de production artistique notamment pour" +
            " des titres musicaux cheminent entre 25 000 et plus de 100 milles francs au Burkina Faso selon les données recensées par la maison de distribution [Lithyus Music](http://lithyusmusic.com/) tandis que dans les pays voisins notamment dans celui le plus proche culturellement du Burkina (la Cote d’ivoire) les couts de production musicaux sont plusieurs fois inferieurs a ces chiffres, on parle d’intervalles de 10 000milles voire moins pour la réalisation d’un seul titre ce qui dès lors est nettement plus abordable selon les données qu’on a pu recensées de la même source (lithyus Music) ce qui permet d’une certaine façon de favoriser la création artistique et permet un essor artistique.\n" +
            "\n" +
            "On peut comprendre que les grandes maisons de production" +
            " telles que les Shamar Empire ou encore [Hcone on the beat](https://www.facebook.com/hconestudio/)" +
            " ou Leo on the beat, se procurent un matériel couteux et" +
            " veulent rentabiliser leur investissement. Cependant, rendre" +
            " abordable la création musicale leur permettrait (pour notre" +
            " part) de rentabiliser sur le nombre d’artistes qui voudraient" +
            " tenter leurs chances et aussi de favoriser le développement musical. C’est dans cette vision que [Lithyus Music](http://lithyusmusic.com/) s’inscrit afin de créer une industrie musicale structurée stimulant la création et la popularisation de la distribution en ligne des projets des artistes locaux suivant des moyens simples et facile d’utilisation et ainsi permettre l’expansion de la musique Burkinabè car on voit que ce pays regorge de talents qui attendent juste d’être écouté, on le découvrit suite à un projet (nommée [l’industrie](https://open.spotify.com/album/0IOejOACVBNYmJLA169QCk?si=Mg9bvSywSsqWMtP786L4XA) disponible sur Spotify )qui fut mené par certains artistes Burkinabè qui regroupait plusieurs collaborations entre ces artistes et qui montra le potentiel.",
            "Les pessimistes diront que non tant les efforts qui sont à" +
            " faire sont considérables avant de réussir cette exportation" +
            " culturelle au même titre que les Ivoiriens, les Sénégalais ou encore les Maliens au même titre de leurs artistes comme on le vit plus haut. Cependant, L’objectif ne serait pas de les imiter, ni les copier mais de rendre notre musique accessible au monde, que ce soit à travers le marketing, la création artistique. On observe l’apparition de structure telle que [lithyusmusic.com](http://lithyusmusic.com/), Destiny prod et bien d’autres qui font de ça leur mission principale afin de permettre une égalité des chances à tous pour que tout le monde puisse être capable de montrer sa musique au monde. Donc face à cela on ne peut pas être totalement pessimiste sur cette question de modification de la structure musicale Burkinabè tant elle évolue avec son temps donc tout reste possible.\n" +
            "\n" +
            "Vous pouvez si vous le voulez distribuez votre musique et" +
            " en faire la promotion puis bénéficiez de plusieurs studios" +
            " d’enregistrement à des couts bas et des lors montrer votre" +
            " musique au monde entier grâce à Lithyus Music donc on vous" +
            " attend. \n \n **Lithyus Music, là, pour soutenir les" +
            " artistes**","", "", "", "", "", "", "", ""

        ],
        imageCover: {
            src: img_2,
            description: "Cover du single shogun par Didi B"
        },
        images: [
            {},
            {},
            {},
            {src: img_1, description: "Cover du single de Booba dénommé DKR"},
            {src: img_2, description: "Cover du single shogun par Didi B"},
            {},
            {},
        ],
    videos: [
    {},
    {},
    {},
    {},
    {},
    {},
    {},
],

        sectionTitle: ["D’où vient le blocage Burkinabè?", ". Celui du marché" +
        " de consommation interne et externe:", ". Valorisation par les" +
        " consommateurs:", "L’avenir s’annonce t’il radieux?", "Lithyus" +
        "", "", ""],
    },
    {
        id: 2,
        type: "CONSEILS",
        title: 'La synchronisation dans la musique',
        auteur: "Ayed Neya",
        date: "2 min read . Dec 10, 2023",
        introduction: "La synchronisation est un processus qui consiste à combiner de la musique avec une œuvre audiovisuelle. Les droits de synchronisation sont accordés pour l’utilisation de musique préexistante dans une production audiovisuelle. Les droits d’auteur musicaux et les droits d’enregistrement sont abordés lorsqu’une licence de synchronisation est délivrée. Si vous êtes un artiste indépendant, vous pouvez signer des contrats de synchronisation par vous-même. Si vous travaillez avec un éditeur, il est alors le seul à pouvoir signer des contrats de synchronisation car il possède les droits de la composition.",
        sectionTitle: ["Les droits de synchronisation et les clauses" +
        " contractuelles", "Conseils pour vendre une licence de" +
        " synchronisation", "", "", "", "", ""],
        content: ["Dans un contrat de synchronisation, il est important de comprendre les clauses relatives à la promotion de votre musique, le prix et le cadre contractuel. Pour vendre une licence de synchronisation, vous devez avoir une autorisation légale de combiner de la musique avec une œuvre audiovisuelle.",
            "Pour vendre une licence de synchronisation, vous devez avoir une autorisation légale de combiner de la musique avec une œuvre audiovisuelle. Il est également important de comprendre les clauses relatives à la promotion de votre musique, le prix et le cadre contractuel. Si vous êtes un artiste indépendant, vous pouvez signer des contrats de synchronisation par vous-même. Si vous travaillez avec un éditeur, il est alors le seul à pouvoir signer des contrats de synchronisation car il possède les droits de la composition. Certains services en ligne comme [lithyusmusic.com](http://lithyusmusic.com/) vous permettent de mettre sous licence musicale vos chansons et ainsi permettre l’apparition de vos chansons au sein de divers projets en vous rémunérant par la suite, alors ne perdez plus de temps et inscrivez vous.",
            "Pour en allez plus loin: \n\n [imusician.pro](http://imusician.pro/) \n\n" +
            " [blog.groover.co](https://blog.groover.co/)", "", "", "", "", "", "", ""
        ],
        imageCover: {
            src: img,
            description: "Photo by Matt Botsford on Unsplash"
        },
        images: [
            {},
            {},
            {src: img, description: "Photo by [Matt" +
                        " Botsford](https://unsplash.com/fr/@mattbotsford) on" +
                        " [Unsplash](https://unsplash.com/fr)"},
            {},
            {},
            {},
            {},
        ],
        videos: [
            {
                src: "Hd0kLWjAX8s",
                description: "Tarik Hamiche-Producteur à succès"
            },
            {},
            {},
            {},
            {},
            {},
            {},
        ],
    },
    {
        id: 3,
        type: "CONSEILS",
        title: 'C’est quoi le playlisting?',
        auteur: "Ayed Neya",
        date: "3 min read . Dec 11, 2023",
        introduction: "Le playlisting est un processus qui consiste à créer une liste de lecture de chansons pour une occasion ou un événement particulier. Les playlists peuvent être créées pour des occasions spéciales telles que les mariages, les fêtes d’anniversaire, les fêtes de fin d’année, etc. Les playlists peuvent également être créées pour des événements tels que les concerts, les festivals de musique, etc. Les playlists peuvent être créées pour des genres musicaux spécifiques tels que le rock, le jazz, le blues, etc. Les playlists peuvent également être créées pour des artistes spécifiques.\n" +
                "\n" +
                "Les playlists sont un excellent moyen de découvrir de nouveaux artistes et de nouvelles chansons. Les playlists peuvent être partagées avec des amis et des membres de la famille. Les playlists peuvent également être partagées sur les réseaux sociaux tels que Facebook, Twitter, Instagram, etc. Les playlists peuvent être créées sur des plateformes de streaming de musique telles que Spotify, Apple Music, Tidal, etc.",
        sectionTitle: [
            "Playlisting en musique",
            "Comment créer une playlist",
            "Comment partager une playlist",
            "L’importance du playlisting dans la musique", "", "", "", ""
        ],
        content: [
            "Le playlisting est un processus qui consiste à créer une liste de lecture de chansons pour une occasion ou un événement particulier. Les playlists peuvent être créées pour des occasions spéciales telles que les mariages, les fêtes d’anniversaire, les fêtes de fin d’année, etc. Les playlists peuvent également être créées pour des événements tels que les concerts, les festivals de musique, etc. Les playlists peuvent être créées pour des genres musicaux spécifiques tels que le rock, le jazz, le blues, etc. Les playlists peuvent également être créées pour des artistes spécifiques.",
            "Pour créer une playlist, vous devez d’abord choisir une plateforme de streaming de musique telle que Spotify, Apple Music, Tidal, etc. Ensuite, vous devez choisir les chansons que vous souhaitez inclure dans votre playlist. Vous pouvez choisir des chansons en fonction de l’occasion, de l’événement, du genre musical, de l’artiste, etc. Vous pouvez également ajouter des chansons recommandées par la plateforme de streaming de musique.",
            "Une fois que vous avez créé votre playlist, vous pouvez la partager avec vos amis et votre famille. Vous pouvez également la partager sur les réseaux sociaux tels que Facebook, Twitter, Instagram, etc. Pour partager une playlist, vous devez d’abord copier le lien de la playlist. Ensuite, vous pouvez envoyer le lien de la playlist à vos amis et votre famille. Vous pouvez également partager le lien de la playlist sur les réseaux sociaux.",
            "Le playlisting reste un excellent moyen de découverte de jeunes artistes indépendamment dans la mesure ou ces listes de lectures permettent à de nouveaux auditeurs de découvrir les projets de ces jeunes artistes et ainsi favoriser le développement de leur influence. Alors, pour chaque morceau produit une adhésion à ces listes de lecture doit être un élément important de la stratégie de communication des artistes. Des acteurs comme la plateforme de distribution [lithyusmusic.com](http://lithyusmusic.com/) peuvent vous aidez à mettre en exergue vos réalisations musicales sur des playlists populaires. Alors n’hésitez pas.",
            "", "", "", "", ""
        ],
        imageCover: {
            src: img_3,
            description: "Photo by Martin Sanchez on Unsplash"
        },
        images: [
            {},
            {},
            {src: img_3, description: "Photo by [Martin Sanchez](https://unsplash.com/@martinsanchez?utm_source=medium&utm_medium=referral) on [Unsplash](https://unsplash.com/?utm_source=medium&utm_medium=referral)"},
            {},
            {},
            {},
            {},
        ],
        videos: [
            {},
            {},
            {},
            {},
            {src: "TOA-tIPJbPE", description: ""},
            {},
            {},
        ],
    },
    {
        id: 4,
        type: "CONSEILS",
        title: 'En Marche vers la Transformation Digitale : Les Moyens de la Digitalisation au Burkina Faso',
        auteur: "Ayed Neya",
        date: "3 min read . Aug 12, 2023",
        introduction: "Le Burkina Faso, comme de nombreux pays en Afrique, a entrepris un voyage ambitieux vers la transformation digitale pour stimuler la croissance économique, améliorer les services publics et renforcer la connectivité. À travers une série d'initiatives, le pays cherche à exploiter les avantages de la technologie pour créer un avenir plus moderne et prospère.",
        sectionTitle: [
            "1. Infrastructure Technologique",
            "2. E-Gouvernement",
            "3. Éducation Numérique",
            "4. Innovation Financière",
            "5. Startups et Entrepreneuriat",
            "6. Cybersécurité et Protection des Données",
            ""
        ],
        content: [
            "La mise en place d’une infrastructure technologique solide est un préalable essentiel à la digitalisation. Le Burkina Faso investit dans l’expansion des réseaux de télécommunications, notamment la 4G et la 5G, pour accroître la connectivité à travers le pays. Des efforts sont également déployés pour réduire la fracture numérique en rendant l’accès à Internet plus abordable et accessible aux populations rurales. À travers les operateurs de télécommunications tels que Orange, Telecel et Moov qui tous ceux battent afin d’offir les meilleures options de connections pour l’ensemble des utilisateurs de telecom.",
            "Le gouvernement burkinabé s’engage dans la mise en place de services en ligne pour simplifier les interactions entre les citoyens et l’administration. Des plateformes telles que le portail gouvernemental offrent aux citoyens la possibilité de payer des impôts, de demander des documents officiels et de participer à des consultations publiques, le tout en ligne. Ainsi que d’autres services tels que celui des douanes qui facilitent l’enregistrement de ces données entre les postes douaniers et la centrale.",
            "La digitalisation de l’éducation vise à améliorer l’accès à l’apprentissage et à renforcer les compétences numériques. Des projets éducatifs intègrent les technologies de l’information et de la communication dans les programmes scolaires, préparant ainsi les jeunes à un avenir numérique.",
            "Les services financiers numériques, tels que les paiements" +
            " mobiles et les plateformes de transfert d’argent, jouent un rôle crucial dans la transformation digitale. Ces services facilitent les transactions financières, particulièrement dans les zones rurales où les infrastructures bancaires traditionnelles sont limitées. C’est ainsi que nous avons l’exemple de certaines applications comme Orange Money ou encore Coris Money qui facilitent cette liaison entre les populations rurales et services gouvernementaux ou privés comme le paiement de factures d’électricité ou encore l’achat de crédit téléphoniques. Nous avons aussi la facilitation de transfers d’argent entre particuliers qui permet une meilleure circulation d’argent entre les particuliers et même à l’international.",
            "L’écosystème des startups se développe au Burkina Faso, soutenu par des incubateurs et des espaces de coworking. Les entrepreneurs locaux travaillent sur des solutions innovantes pour résoudre les défis locaux, allant de l’agriculture à la santé en passant par les services publics. C’est ainsi qu’on peut observer le développement de certaines startups prometteuses comme VinoTicket dans le secteur du développement de la vente de tickets en ligne enfin de faciliter l’achat des tickets aux riverains et ainsi réduire les problèmes d’achinement des tickets d’événements aux populations qui fussent éloignées. Ceux-ci eurent beaucoup de compliments face à cette innovation apporter dans le pays. En plus de VinoTicket, nous avons aussi dans le domaine du divertissement, principalement dans la musique Lithyus Music qui vise à permettre aux artistes locaux de rendre accessible leurs musiques sur les plateformes de streaming afin de les permettre de toucher plus de monde avec leurs musiques et ainsi internationaliser la culture musicale locale.",
            "La digitalisation s’accompagne de préoccupations liées à la cybersécurité et à la protection des données. Le gouvernement et les entreprises travaillent ensemble pour mettre en place des mesures de sécurité robustes et des politiques de protection des données pour assurer la confiance des utilisateurs.\n\n" +
            "En conclusion, la digitalisation au Burkina Faso est une" +
            " entreprise en évolution visant à améliorer divers aspects de" +
            " la vie quotidienne. Grâce à des investissements dans" +
            " l’infrastructure, l’éducation, l’innovation et la sécurité" +
            " numérique, le pays ouvre la voie à un avenir où la technologie" +
            " jouera un rôle central dans la réalisation de ses objectifs de" +
            " développement.", "", "", "", "", "", ""
        ],
        imageCover: {
            src: img_4,
            description: "Place des cinéastes à Ouagadougou"
        },
        images: [
            {src: img_4, description: "Place des cinéastes à Ouagadougou"},
            {},
            {},
            {},
            {src: img_11, description: "Orange Money Burkina Faso"},
            {},
            {},
        ],
        videos: [
            {},
            {},
            {},
            {},
            {},
            {},
            {},
        ],
    },
    {
        id: 5,
        type: "CONSEILS",
        title: 'Naviguer avec Succès le Chemin du Développement d’une Carrière Musicale',
        auteur: "Ayed Neya",
        date: "3 min read . Dec 11, 2023",
        introduction: "Le monde de la musique est un univers passionnant et stimulant, mais il peut aussi être complexe et exigeant. Pour tout artiste ambitieux, le développement d'une carrière musicale fructueuse nécessite non seulement du talent, mais également une approche stratégique, de la persévérance et une compréhension approfondie de l'industrie. Cet article explore les étapes clés du développement d'une carrière musicale, en mettant en évidence les conseils essentiels pour progresser de manière significative dans l'industrie.",
        sectionTitle: [
            "1. Définir une Vision et des Objectifs",
            "2. Cultiver son Identité Artistique",
            "3. Travailler sur sa Musique et sa Performance",
            "4. Construire une Présence en Ligne",
            "5. Networking et Collaboration",
            "6. Éducation Continue",
            ""
        ],
        content: [
            "Le premier pas vers le développement d'une carrière musicale solide consiste à définir une vision claire de ce que vous souhaitez réaliser en tant qu'artiste. Établissez des objectifs réalistes et mesurables, à court et à long terme. Que ce soit la sortie d'un album, l'atteinte d'un certain nombre de streams ou la réalisation de tournées, des objectifs précis vous aideront à rester concentré et motivé.",
            "Développer une identité artistique authentique est crucial pour se démarquer dans l’industrie musicale compétitive. Explorez différents genres et styles pour découvrir ce qui vous passionne le plus. Votre identité artistique devrait refléter qui vous êtes en tant qu’individu et artiste, tout en étant en accord avec les tendances actuelles.\n" +
            "\n" +
            "Développer une identité artistique authentique est crucial pour se démarquer dans l'industrie musicale compétitive. Explorez différents genres et styles pour découvrir ce qui vous passionne le plus. Votre identité artistique devrait refléter qui vous êtes en tant qu'individu et artiste, tout en étant en accord avec les tendances actuelles.",
            "La qualité de votre musique est le cœur de votre carrière. Consacrez du temps à l'écriture, à la composition et à la production de morceaux captivants et uniques. En parallèle, perfectionnez vos compétences de performance sur scène. Les concerts live sont une opportunité cruciale pour établir une connexion directe avec votre public.",
            "À l’ère numérique, une présence en ligne solide est indispensable. Créez et entretenez des comptes sur les principales plateformes de streaming et de médias sociaux. Partagez régulièrement du contenu engageant, des extraits musicaux, des vidéos et des coulisses pour interagir avec vos fans et attirer de nouveaux auditeurs.",
            "L'industrie musicale repose souvent sur les relations. Établissez des connexions avec d'autres artistes, producteurs, managers et professionnels de l'industrie. Les collaborations musicales et les partenariats stratégiques peuvent élargir votre portée et ouvrir de nouvelles opportunités.",
            "L'industrie musicale est en constante évolution. Restez informé des dernières tendances, des nouvelles technologies et des changements dans l'industrie. Investissez dans votre éducation musicale en participant à des ateliers, des conférences et des cours en ligne.\n" +
            "\n" +
            "Le développement d’une carrière musicale est un voyage exigeant" +
            " mais passionnant. En suivant une approche stratégique, en" +
            " cultivant votre identité artistique, en perfectionnant vos" +
            " compétences musicales et en établissant des relations solides," +
            " vous pouvez progresser de manière significative dans l’industrie. Rappelez-vous que le succès musical nécessite du temps, de la persévérance et un engagement constant envers votre art.", "", "", "", "", ""
        ],
        imageCover: {
            src: img_6,
            description: "Photo by Alexandre Debiève on Unsplash"
        },
        images: [
            {src: img_6, description: "Photo by Alexandre Debiève on Unsplash"},
            "",
            "",
            "",
            "",
            "",
            "",
            ""
        ],
        videos: [
            {},
            {},
            {},
            {},
            {},
            {},
            {},
        ],
    },
    {
        id: 6,
        type: "CONSEILS",
        title: 'Les Avantages du Licensing Musical dans le Contexte Africain',
        auteur: "Ayed Neya",
        date: "2 min read . Aug 16, 2023",
        introduction: "La scène musicale en Afrique a connu une croissance exceptionnelle au cours des dernières années, attirant l’attention tant sur le continent qu’à l’international. Dans ce contexte en pleine évolution, le licensing musical émerge comme une opportunité stratégique pour les artistes, les labels et l’industrie musicale dans son ensemble. Cet article explore les avantages du licensing musical dans le contexte africain, en mettant en évidence les gains potentiels pour les créateurs et l’industrie, ainsi que les moyens de maximiser ces opportunités.",
        sectionTitle: [
            "*Exploitation des Marchés Internationaux*",
            "*Génération de Revenus Diversifiés*",
            "*Promotion et Visibilité*",
            "*Encouragement de la Créativité*",
            "*Conclusion*", "", ""
        ],
        content: [
            "Le licensing musical offre aux artistes africains la possibilité de diffuser leur musique à travers le monde. En obtenant des licences pour leurs chansons, ils peuvent les voir figurer dans des films, des publicités, des jeux vidéo et d’autres supports médiatiques internationaux. Cela permet de toucher un public plus vaste et diversifié, tout en générant des revenus grâce aux redevances de licences.",
            "Le licensing musical ouvre de nouvelles sources de revenus pour les artistes et les labels. Dans de nombreux pays africains où l’industrie musicale traditionnelle peut être moins développée, le licensing offre une alternative pour générer des profits. Les redevances de licences, les avances et les paiements pour l’utilisation de la musique dans différents médias peuvent constituer une source de revenus plus stable et prévisible que les seules ventes de musique.",
            "Le placement de chansons dans des médias tels que des films ou des publicités peut considérablement accroître la visibilité des artistes. Cela peut amener de nouveaux auditeurs à découvrir leur musique, tant sur le continent africain qu'à l'étranger. De plus, le licensing peut renforcer la crédibilité et la notoriété d'un artiste en associant sa musique à des marques ou des projets reconnus.",
            "Le licensing musical encourage la créativité des artistes en leur offrant la possibilité d'expérimenter différents styles musicaux pour répondre aux besoins spécifiques des projets médiatiques. Cela peut ouvrir la voie à des collaborations interculturelles et à des échanges artistiques enrichissants, renforçant ainsi le paysage musical africain dans sa diversité.",
            "Le licensing musical présente des avantages significatifs pour" +
            " les artistes et l’industrie musicale en Afrique. En exploitant" +
            " les marchés internationaux, en diversifiant les revenus, en" +
            " augmentant la visibilité et en favorisant la créativité, le" +
            " licensing devient un pilier essentiel de la croissance" +
            " continue de la scène musicale africaine. Pour maximiser ces avantages, il est crucial que les artistes et les professionnels de l’industrie explorent activement les opportunités de licensing et s’engagent dans des partenariats stratégiques avec des acteurs du secteur médiatique.", "", "", "", "", ""
        ],
        imageCover: {
            src: img_7,
            description: "Photo by Jovan Vasiljević on Unsplash"
        },
        images: [
            {src: img_7, description: "Photo by Jovan Vasiljević on Unsplash"},
            {},
            {},
            {},
            {},
            {},
            {},
            {},
        ],
        videos: [
            {},
            {},
            {},
            {},
            {},
            {},
            {},
        ],
    },
    {
        id: 7,
        type: "CONSEILS",
        title: 'Les droits voisins dans la musique : Comprendre et récupérer vos revenus',
        auteur: "Ayed Neya",
        date: "3 min read . May 11, 2023",
        introduction: "Dans l’industrie musicale, les artistes ne sont pas les seuls à bénéficier de droits d’auteur. Les droits voisins, également connus sous le nom de droits de performance, sont une composante essentielle pour les musiciens, les chanteurs et les producteurs. Ces droits couvrent les utilisations publiques de leurs enregistrements sonores et offrent une source de revenus supplémentaires. Dans cet article, nous explorerons les droits voisins dans la musique, leur importance et comment vous pouvez les récupérer pour maximiser vos revenus.",
        sectionTitle: [
            "I. Qu’est-ce que les droits voisins?",
            "II. L’importance des droits voisins pour les artistes", "",
            "", "", "", "", "", "", ""],
        content: [
            "Les droits voisins sont des droits exclusifs accordés aux artistes-interprètes, aux producteurs et aux labels pour l’utilisation publique de leurs enregistrements sonores. Ils diffèrent des droits d’auteur traditionnels, qui protègent les compositions musicales. Les droits voisins concernent spécifiquement l’enregistrement original d’une performance musicale. Ils sont régis par des lois nationales et internationales, et leur gestion et collecte sont confiées à des sociétés de gestion collective.",
            "Inscrivez-vous auprès d’une société de gestion collective : La première étape pour récupérer vos droits voisins consiste à vous inscrire auprès d’une société de gestion collective de votre pays. Ces organisations sont responsables de collecter et de distribuer les redevances aux artistes. Faites des recherches pour trouver la société appropriée et suivez leur processus d’inscription.",
            "Enregistrez vos œuvres et métadonnées : Assurez-vous que vos enregistrements sonores sont correctement enregistrés et associés aux métadonnées appropriées. Cela facilite l’identification et la collecte des droits voisins lorsque vos chansons sont diffusées.\n" +
            "\n" +
            "Faites un suivi des utilisations de vos enregistrements :" +
            " Surveillez les utilisations publiques de votre musique, que ce soit à la radio, à la télévision, en streaming ou dans des lieux publics. Les sociétés de gestion collective utilisent ces informations pour calculer les redevances qui vous sont dues. Utilisez des outils et des services de suivi en ligne pour simplifier ce processus.",
            "Restez informé des changements législatifs : Les lois relatives aux droits voisins évoluent constamment. Restez informé des changements législatifs et des nouvelles réglementations pour vous assurer de maximiser vos droits et vos revenus.\n" +
            "\n" +
            "Restez informé des changements législatifs : Les lois relatives aux droits voisins évoluent constamment. Restez informé des changements législatifs et des nouvelles réglementations pour vous assurer de maximiser vos droits et vos revenus.\n" +
            "\n" +
            "Les droits voisins sont un élément clé pour les artistes" +
            " dans l’industrie musicale. Ils offrent une source de" +
            " revenus supplémentaires et reconnaissent le travail des artistes-interprètes et des producteurs. En vous inscrivant auprès d’une société de gestion collective, en enregistrant correctement vos œuvres et en surveillant les utilisations de votre musique, vous pouvez récupérer vos droits voisins et optimiser vos revenus. Ne négligez pas cette opportunité et assurez-vous de faire valoir vos droits dans l’environnement musical en constante évolution d’aujourd’hui. Aujourdhui il existe de nombreuses cosiétés qui peuvent s’occuper de la gestion de ces droits pour vous afin que vous puissiez vous concentrez sur votre musique. C’est ainsi que des sociétés de gestion tels que [lithyusmusic.com](http://lithyusmusic.com/) vous accompagne dans la récupération de vos droits d’auteurs surtout pour les artistes africains. Alors n’hésitez pas à faire un tour sur leur site afin qu’ils vous accompagnent.", "",
            "", "", "", ""],
        imageCover: {
            src: img_8,
            description: "Photo by Marius Masalar on Unsplash"
        },
        images: [
            {},
            {src: img_8, description: "Photo by [Marius Masalar](https://unsplash.com/fr/@marius) on" +
                        " [Unsplash](https://unsplash.com/fr)"},
            {src: img_9, description: "Photo by [Jametlene Reskp](https://unsplash.com/fr/@reskp) on" +
                        " [Unsplash](https://unsplash.com/fr)"},
            {src: img_10, description: ""},
            {},
            {},
            {},
        ],
        videos: [
            {},
            {},
            {},
            {},
            {},
            {},
            {},
        ],
    },
    {
        id: 8,
        type: "CONSEILS",
        title: 'Les avantages du streaming pour les artistes : pourquoi cela vaut-il la peine d’y être présent ?',
        auteur: "Ayed Neya",
        date: "3 min read . May 19, 2023",
        introduction: "Le streaming musical est devenu un canal important pour les artistes pour partager leur musique avec leur public. En effet, les services de streaming musical tels que Spotify, Apple Music, et Deezer sont devenus les plateformes de choix pour les auditeurs. Mais qu’en est-il des artistes ? Comment le streaming peut-il les aider ? Dans cet article, nous allons explorer les avantages du streaming pour les artistes.",
        sectionTitle: [
            "Avantage n°1 : Le streaming peut augmenter votre visibilité",
            "Avantage n°2 : Le streaming peut augmenter vos revenus",
            "Avantage n°3 : Le streaming peut vous aider à comprendre votre" +
            " public",
            "Avantage n°4 : Le streaming peut vous aider à promouvoir" +
            " votre musique", "", "", "", "", ""
        ],
        content: [
            "L’un des plus grands avantages du streaming pour les artistes est la possibilité d’augmenter leur visibilité. Les services de streaming musical sont disponibles dans le monde entier et peuvent atteindre des millions d’utilisateurs. En étant présent sur ces plateformes, vous pouvez toucher un public plus large que jamais auparavant. De plus, les services de streaming recommandent souvent de nouvelles musiques aux utilisateurs en fonction de leurs goûts musicaux, ce qui peut aider les auditeurs à découvrir votre musique.",
            "Bien que les artistes ne gagnent pas beaucoup d’argent grâce aux services de streaming, ils peuvent tout de même gagner de l’argent grâce à ces plateformes. Les services de streaming paient les artistes pour chaque lecture de leur musique. Bien que le taux de paiement puisse être faible, les streams peuvent s’accumuler rapidement, ce qui peut générer des revenus réguliers pour les artistes. De plus, en étant présent sur des plateformes de streaming, vous pouvez attirer l’attention des labels, des promoteurs de concerts et des agents, ce qui peut vous aider à obtenir des contrats lucratifs à l’avenir.",
            "En étant présent sur des plateformes de streaming, vous pouvez accéder à des données sur vos auditeurs, telles que leur âge, leur sexe, leur emplacement géographique, etc. Cela peut vous aider à comprendre votre public et à adapter votre musique en conséquence. Par exemple, si vous découvrez que la majorité de vos auditeurs sont des jeunes de moins de 25 ans, vous pouvez créer des chansons qui répondent à leurs goûts musicaux.",
            "Les services de streaming offrent des outils de promotion pour les artistes, tels que les playlists de découverte, les listes de lecture de l’éditeur, et les campagnes publicitaires. En utilisant ces outils, vous pouvez promouvoir votre musique auprès d’un public plus large et attirer l’attention des auditeurs. De plus, les services de streaming ont des partenariats avec d’autres entreprises, tels que les marques de vêtements, les sociétés de production de films et les festivals de musique, qui peuvent offrir des opportunités de promotion supplémentaires.\n\n" +
            "Les services de streaming musical peuvent offrir de" +
            " nombreux avantages aux artistes, notamment une plus grande" +
            " visibilité, des revenus supplémentaires, des informations" +
            " sur leur public et des outils de promotion. Bien que les revenus générés par les services de streaming soient moindre aux débuts ceux-ci ont tendance à avoir une croissance à la hausse de manière progressive. Ainsi, des distributeurs comme [lithyusmusic.com](http://lithyusmusic.com/) accompagnent les artistes afin de les permettre de rentabiliser dans cette industrie.", "", "", "", ""],
        imageCover: {
            src: img_10,
            description: "Lithyus Music"
        },
        images: [
            {},
            {},
            {},
            {},
            {},
            {src: img_10, description: "Lithyus Music"},
            {},
            {},
        ],
        videos: [
            {},
            {},
            {},
            {},
            {},
            {},
            {},
        ],
    },
    {
        id: 9,
        type: "CONSEILS",
        title: 'Structurer l’industrie musicale Burkinabè: une industrie déséquilibrée…',
        auteur: "Ayed Neya",
        date: "10 min read . Mar 2, 2024",
        introduction: "Le but de cet article est de mettre en lumière l’un des principaux défauts de l’industrie musicale Burkinabè. " +
                "Lorsqu’on analyse l’industrie musicale Burkinabè, on se rend compte que comparer aux autres pays de la sous-région tel " +
                "que le Sénégal, la Cote d’ivoire ou encore même le Nigeria et le Ghana, le Burkina Faso ne bénéficie pas d’une forte " +
                "exportation culturelle de sa culture musicale. Maintenant la question que vous pourrez des lors vous demander c’est " +
                "qu’est-ce qu’on entend par exportation culturelle! Pour notre part on attend par exportation culturelle: ‘’ Par exportation " +
                "artistique ou culturelle, nous entendons une musique qui respecte trois critères de base qui sont: une popularité reconnue dans " +
                "la sous-région ou internationalement, une source d’inspiration qui peut être reproduite à travers une utilisation du style musicale " +
                "ou de la production et enfin la consommation locale et" +
                " internationale du titre musical ’’. \n\n" +
                "Ainsi après avoir défini ce que nous entendons par exportation culturelle, il convient maintenant de se " +
                "demander pourquoi, le Burkina Faso ne bénéficie pas de" +
                " cela? \n\n" +
                "D’aucuns diront que les musiques locales ne sont pas exportables compte tenu de l’aspect traditionnel des chansons et aussi de la langue avec lesquelles ces dernières sont " +
                "chantés (le Moore) donc au vu de cela peut constituer un frein à cette expansion culturelle. Mais se baser sur de tels" +
                " propos peut s’avérer à la fois vraie et fausse surtout lorsqu’on sait que certains pays voisins comme: " +
                "La Cote d’ivoire, Le Sénégal ou encore même le Nigeria réussirent à exporter leurs musiques, on a pour exemples, " +
                "Meiway de la cote d’ivoire qui réussit cet exploit avec certaines de ses titres chanté en langue locale, (il est " +
                "initiateur d’un style développé par lui appelé le zoblazo ou danse des mouchoirs blancs) comme : ‘’Miss lolo’’ " +
                "rendus célèbre dans la sous-région ou encore même Youssou N’dour et ses titres dont: ‘’Djino’’ qui d’une certaine " +
                "manière a contribué à mettre en lumière la culture musicale du Sénégal berce par le Mbalar dont Youssou Ndour contribua " +
                "principalement a développée. Nous avons également Sidiki Diabaté du Mali avec des titres comme ‘’Fais-moi’’ qui fut un " +
                "succès pas que locale mais également sous régionale au vu de la rythmique mandingue qui fut utilisé tant bien que dans " +
                "les paroles, et ce même si dans certains passage celui-ci s’exprimaient en Bambara langue locale malienne mais on remarqua " +
                "que cela ne constitua pas un frein à l’expansion de sa musique dans la sous-région ni même dans le monde quand bien même on" +
                " sait que le temps de celui-ci le poussa à contribuer avec certaines pointures du rap internationale comme Booba dans un de ses" +
                " albums notamment Trône dans le titre: ‘’[Ça va aller](https://youtu.be/rY4q6VXmhLg?si=w2ZenfdnklYjFxTe)’’." +
                " Il est très important de souligner que l’ensembles des titres soulignes" +
                " plus haut furent des titres qui connurent des succès énormes dans la sous-région et contribua à mettre en avant les rythmes musicaux " +
                "des différents pays respectifs. Dès lors la question est" +
                " pourquoi eux réussirent ou les Burkinabè échouèrent \n \n" +
                "Face à cette déclaration beaucoup d’avis seront mitiges" +
                " notamment en indiquant le fait que la Musique Burkinabè" +
                " réussit tant bien que mal à s’exporter notamment à travers" +
                " des chanteurs locaux très bien connus tels que Floby qui" +
                " réussit tant bien que mal à se faire connaitre dans la" +
                " sous-région à travers les collaborations qu’il réalisa de" +
                " gauche à droite dont celui qui reste emblématique tant il" +
                " fut réalisé avec l’une des stars régionales en ce temps," +
                " j’ai nommé Didier Arafat avec le titre: ‘’[[Danse un" +
                " peu](https://youtu.be/Rfy73ebK6wQ?si=tiDLc2UFc7l7_DGb)](https://youtu.be/Rfy73ebK6wQ?si=tiDLc2UFc7l7_DGb)’’ afin de développer une certaine notoriété sous-régionale. Néanmoins on vit que ces efforts furent d’une certaine manière récompense mais sur une courte durée car ses chansons ayant accumulées le plus de vu sur YouTube remontent à plusieurs années auparavant car maintenant ce n’est plus le cas maintenant et nous avons aussi Dez Altino qui chante aussi en langue locale et qui réussirent également à se faire connaitre. Pour être honnête, on peut classer ses artistes comme une génération musicale qui tenta cette exportation culturelle dont on fait référence mais qui ne fut pas s’établir sur le long terme et donc qui baissa le rythme et par la suite finit par être enterré. La génération qui suivit ces artistes tenta ou tente encore de suivre ce chemin laisser par ses devanciers mais sur une approche plus novatrice notamment par la manière et l’approche c’est-à-dire en adaptant les nouvelles chansons produites à la génération qui l’écoute et parmi eux, on peut citer des artistes comme tels que [[Imilo le chanceux](https://fr.wikipedia.org/wiki/Imilo_Lechanceux)](https://fr.wikipedia.org/wiki/Imilo_Lechanceux), [Hugo Boss](https://lefaso.net/spip.php?article75663=) ex-membre du groupe LastKing226 qui avait amorce une bonne lancée tant leurs morceaux s’inscrivait sur une bonne dynamique qui fut stoppe après leur séparation et tous les problèmes qui s’en suivirent même si l’artiste lui-même (Hugo Boss) eut une carrière ascendante à travers les concerts qu’il réalisa par ci par là en Afrique et des collaborations qu’il fit dont celui notoire avec Mc One de Cote d’ivoire avant de se reconvertir dans les affaires. \n\n" +
                "À travers ainsi ce bref inventaire historique des efforts" +
                " fournit par les artistes Burkinabè, on peut dès lors se" +
                " demander pourquoi ces efforts ne fit pas émergés dès lors" +
                " des artistes qui à leur tour pourrait renforcer cette" +
                " notoriété que les devanciers tentèrent d’instaurer sur la scène sous-régionale et ce peu importe le genre musical. Pourquoi, n’observons-nous pas une explosion de pépites voulant s’imposer ? **pourquoi la musique Burkinabè tout comme son territoire reste enclaver?**"
        ,
        content: [
            "Lorsqu’on se base sur les trois critères relatifs à l’exportation" +
            " culturelle de la culture musicale Burkinabè tel que défini plus" +
            " haut, on se rend compte que la liste des artistes de nouvelles" +
            " générations répondant ou remplissant ces critères s’amenuisent" +
            " tant peu sont ceux qui remplisse tous les trois. Dès lors" +
            " qu’on évolue selon cette dynamique, on peut citer des artistes" +
            " Burkinabè qui réussirent un semblant d’exportation ( mais qui" +
            " ne peuvent pas être classifie dans ce groupe dit de nouvelles" +
            " générations mais plutôt appartenant à celle précédente), on peut dire que ce groupe de musique [Yeleen](https://www.bing.com/ck/a?%21=&p=72739077ba4dc133JmltdHM9MTcwODM4NzIwMCZpZ3VpZD0zY2Q4OWJlYi1jODIzLTZhNmQtMjVjYi04ODQ3YzkwYTZiMWYmaW5zaWQ9NTIyMA&ptn=3&ver=2&hsh=3&fclid=3cd89beb-c823-6a6d-25cb-8847c90a6b1f&psq=Yeleen&u=a1aHR0cHM6Ly9mci53aWtpcGVkaWEub3JnL3dpa2kvWWVsZWVu&ntb=1) réussit à exporter leur art musicale au-delà des frontières à travers leur sonorité entrainante, et motivante mais qui n’arrivèrent pas à s’imposer comme musique populaire ni à inspirer une forme de reprise même si dans le fond cette musique réussit à se faire au-delà des frontières Burkinabè( un exemple de titres populaires en son temps, qu’ils sortirent fut: [Sortir de l’exil](https://www.bing.com/ck/a?%21=&p=e05b210930ff246bJmltdHM9MTcwODM4NzIwMCZpZ3VpZD0zY2Q4OWJlYi1jODIzLTZhNmQtMjVjYi04ODQ3YzkwYTZiMWYmaW5zaWQ9NTI1Mw&ptn=3&ver=2&hsh=3&fclid=3cd89beb-c823-6a6d-25cb-8847c90a6b1f&psq=Yeleen&u=a1aHR0cHM6Ly93d3cueW91dHViZS5jb20vd2F0Y2g_dj1Ya3RVdk9vdzBSRQ&ntb=1) , on eut également, le cas de Wassa Wassa de Imilo Le chanceux, qui réussit à faire connaitre sa musique au-delà des frontières et également sa personne. La seule limite qui se posa à cette chanson est le fait que cette musique n’était pas arrivée au niveau d’être reprise par d’autres rappeurs comme source d’inspiration pouvant être reprise car le rythme est entrainant mais pas unique ou propre à la culture. Cependant, on remarquera que par la suite, il ne fut pas le seul dans cette situation et cela nous pousse à nous poser la question de savoir est-ce culturelle ? La musique produite au Burkina Faso, n’est-elle pas exportable? \n\n " +
            "Ainsi, pour revenir au sujet initial relatif à l’exportation culturelle on se rend compte que cette limite ne permet pas une réelle distinction de la culture. Ce qui laisse la musique produite par les artistes plonger dans une marée de musique identique qui ne se distingue pas de la masse. C’est ainsi que comparément à d’autres artistes d’autres pays comme la Cote d’ivoire et ses musiciens les plus connus comme ‘’Dj Arafat’’, ‘’Suspect 95'’, ‘’Didi b’’ ou encore le Sénégal avec Youssou N’dour qui a travers leurs différents styles comme le coupe décalé pour la cote d’ivoire ou encore le Mbalar pour le Sénégal cité plus haut qui tous les trois répondent à notre catégorisation initiale tant ils remplissent les questions de popularités, consommation et reproduction. Dès lors, on peut souligner que c’est leur particularité qui les fait se distinguer. Ce qui pourrait sous-entendre que la musique Burkinabè n’a rien de particulier ni distinctif et est voue à rester local, c’est un avis sur lequel on pourrait s’arrêter mais non. Cet avis serait bien trop simpliste car il y’a d’autres élément qu’il faudrait prendre en compte dans cette analyse dont:",
            "Le point commun entre les artistes des pays voisins cites" +
            " plus haut est l’existence d’un marché de consommation à la hauteur de la consommation de ladite musique notamment à travers les plateformes de streaming, ou encore à travers la valorisation de cette musique au-delà des frontières nous avons aussi la popularisation ou la démocratisation des concerts et la culture du marketing par les fans de ces différents artistes. Dès lors, on voit que c’est la population qui contribue à accroitre la notoriété de leurs artistes ce qui dès lors permet aux artistes de se faire un nom, l’exemple palpable est bien dj Arafat qui même par ses paroles incompréhensibles aux premiers abords fit un mystère pour les fans de la musique. Ce mystère quant à la signification des paroles de ses chansons poussa ses fans à se demander sils pouvaient répéter ce qu’il chanta ce qu’il fit et donc contribua à augmenter sa renommée.",
            "Dès lors cette renommée pousser par ces fans, accompagnées" +
            " du rythme musical dansant du chanteur on a le mélange parfait" +
            " pour faire de lui une star sous-régionale et lui permettre" +
            " d’être invite à des évènements dehors de son pays et lui" +
            " permettre de bâtir sa carrière musicale. Ainsi, ce qui fait" +
            " suite à cette montée en popularité est le fait qu’il réalisa" +
            " des collaborations avec des artistes internationaux ce qui" +
            " renforça cette notoriété acquise. C’est cette même stratégie de développement qu’on retrouve chez les artistes congolais (nous avons pour exemple Fally Ipupa dont l’évolution ne suivit pas forcément la même trajectoire mais **fut propulser par le style propre à celui-ci et a un soutien massif de ses fans à l’intérieur comme à l’extérieur** ce qui peut être légèrement différent de celui Sénégalais. Les Sénégalais ayant une structure un peu renferme de part une forte consommation majoritairement locale se sont leurs rythmes qui s’exportent et on a pu le constater par l’utilisation de leurs rythmes musicaux par certains artistes internationaux comme Booba avec sa chanson [DKR](https://youtu.be/Stet_4bnclk?si=R_9iBfdVR4Xt75hR).",
            "Ou encore même Didi B avec son titre [Shogun](https://youtu.be/cXnZDCD4OV0?si=gRfYeaL3RxZ9Ql2L)",
            "Qui sont des exemples parfaits de cette consommation, puis popularité et reproduction. Dès lors, à partir de ces différents éléments on peut voir quels sont les points de rupture avec les projets musicaux Burkinabè et leurs limites.\n" +
            "\n" +
            "Cependant, il faille qu’on attire votre attention sur le fait que lorsqu’on parle de musique comme vous aurez pu le remarquer durant le long de l’article nous parlions de la musique en générale, ce qui prend en compte la majeure partie des sous-genres du rap en français en incluant la musique en langue locale. Néanmoins, il faut souligner que la nouvelle génération tant à vouloir dynamiser les choses en essayant de changer l’aspect traditionnel de la musique instaurer par les ténors locaux (Floby, Dez Altino, Kayawoto et bien d’autres).\n" +
            "\n" +
            "Mais réussiront-ils?\n" +
            "\n" +
            "Telle est la question qui reste poser surtout lorsqu’on sait à" +
            " quel point les couts de production artistique notamment pour" +
            " des titres musicaux cheminent entre 25 000 et plus de 100 milles francs au Burkina Faso selon les données recensées par la maison de distribution [Lithyus Music](http://lithyusmusic.com/) tandis que dans les pays voisins notamment dans celui le plus proche culturellement du Burkina (la Cote d’ivoire) les couts de production musicaux sont plusieurs fois inferieurs a ces chiffres, on parle d’intervalles de 10 000milles voire moins pour la réalisation d’un seul titre ce qui dès lors est nettement plus abordable selon les données qu’on a pu recensées de la même source (lithyus Music) ce qui permet d’une certaine façon de favoriser la création artistique et permet un essor artistique.\n" +
            "\n" +
            "On peut comprendre que les grandes maisons de production" +
            " telles que les Shamar Empire ou encore [Hcone on the beat](https://www.facebook.com/hconestudio/)" +
            " ou Leo on the beat, se procurent un matériel couteux et" +
            " veulent rentabiliser leur investissement. Cependant, rendre" +
            " abordable la création musicale leur permettrait (pour notre part) de rentabiliser sur le nombre d’artistes qui voudraient tenter leurs chances et aussi de favoriser le développement musical. C’est dans cette vision que [Lithyus Music](http://lithyusmusic.com/) s’inscrit afin de créer une industrie musicale structurée stimulant la création et la popularisation de la distribution en ligne des projets des artistes locaux suivant des moyens simples et facile d’utilisation et ainsi permettre l’expansion de la musique Burkinabè car on voit que ce pays regorge de talents qui attendent juste d’être écouté, on le découvrit suite à un projet (nommée [l’industrie](https://open.spotify.com/album/0IOejOACVBNYmJLA169QCk?si=Mg9bvSywSsqWMtP786L4XA) disponible sur Spotify )qui fut mené par certains artistes Burkinabè qui regroupait plusieurs collaborations entre ces artistes et qui montra le potentiel.",
            "Les pessimistes diront que non tant les efforts qui sont à faire sont considérables avant de réussir cette exportation culturelle au même titre que les Ivoiriens, les Sénégalais ou encore les Maliens au même titre de leurs artistes comme on le vit plus haut. Cependant, L’objectif ne serait pas de les imiter, ni les copier mais de rendre notre musique accessible au monde, que ce soit à travers le marketing, la création artistique. On observe l’apparition de structure telle que [lithyusmusic.com](http://lithyusmusic.com/), Destiny prod et bien d’autres qui font de ça leur mission principale afin de permettre une égalité des chances à tous pour que tout le monde puisse être capable de montrer sa musique au monde. Donc face à cela on ne peut pas être totalement pessimiste sur cette question de modification de la structure musicale Burkinabè tant elle évolue avec son temps donc tout reste possible.\n" +
            "\n" +
            "Vous pouvez si vous le voulez distribuez votre musique et" +
            " en faire la promotion puis bénéficiez de plusieurs studios d’enregistrement à des couts bas et des lors montrer votre musique au monde entier grâce à Lithyus Music donc on vous attend.",
            "", "", "", "", "", "", "", ""
        ],
        imageCover: {
            src: img_1,
            description: "Cover du single shogun par Didi B"
        },
        images: [
            {},
            {},
            {},
            {src: img_1, description: "Cover du single de Booba dénommé DKR"},
            {src: img_2, description: "Cover du single shogun par Didi B"},
            {},
            {},
        ],
        videos: [
            {},
            {},
            {},
            {},
            {},
            {},
            {},
        ],

        sectionTitle: ["D’où vient le blocage Burkinabè?", ". Celui du marché" +
        " de consommation interne et externe:", ". Valorisation par les" +
        " consommateurs:", "L’avenir s’annonce t’il radieux?", "Lithyus" +
        " Music, là, pour soutenir les artistes", "", ""],
    },
];