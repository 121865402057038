import React, { useState } from 'react';
import axios from 'axios';
import { NavLink } from 'react-router-dom';

import DashboardSharpIcon from '@mui/icons-material/DashboardSharp';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import MenuSharpIcon from '@mui/icons-material/MenuSharp';
import NotificationsActiveSharpIcon from '@mui/icons-material/NotificationsActiveSharp';
import KeyboardArrowDownSharpIcon from '@mui/icons-material/KeyboardArrowDownSharp';
import FolderIcon from '@mui/icons-material/Folder';
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic';
import EmailIcon from '@mui/icons-material/Email';
import PodcastsIcon from '@mui/icons-material/Podcasts';

import logoLithyus from "../../assets/logoLithyus.webp";
import profile from "../../assets/images/profile.png";

import './leftSide.scss';

function LeftSideAdmin({ page }) {
    const [menu, setMenu] = useState(false);
    const [search, setSearch] = useState();
    const [displayAddButton, setDisplayAddButton] = useState(false);
    const handleSetMenu = () => {
        setMenu(!menu)
    }
    const handleSetDisplayAddButton = () => {
        setDisplayAddButton(!displayAddButton);
    }
    const handleLogout = async () => {
        try {
            // console.log("test test test");
            await axios.post("/api/users/logout",{}, {
                withCredentials:true
            });
            localStorage.removeItem("user");
            window.location.href = '/login';
          } catch (error) {
            console.error(error);
          }
    }
    const handleChange = (event) => {
        setSearch((prev) => ({
            ...prev, [event.target.name]:event.target.value
        }));
    }
    return (
        <div className='Slide'>
            <div className={"leftSide " + (menu ? "active":"inactive")} >
                    <div className="TopTop">
                        <div className="top">
                            <CloseSharpIcon className="leftSideCloseIcon" onClick={handleSetMenu}/>
                            <img src={logoLithyus} alt="logo de lithyus" />
                        </div>
                        <hr />
                        <div className="bottom">
                            <NavLink to='/lithyusMusicOnlyOnlineAdminDashbord' style={{textDecoration:'none'}}>
                                <div className={"box " + (page === "admin" ? "active" : "inactive")}>
                                    <DashboardSharpIcon className='boxIcon' />
                                    <span>Dashboard</span>
                                </div>
                            </NavLink>
                            <NavLink to='/adminSettings' style={{textDecoration:'none'}}>
                                <div className={"box " + (page === "parametres" ? "active" : "inactive")}>
                                    <SettingsSuggestIcon className='boxIcon' />
                                    <span>Paramètres</span>
                                </div>
                            </NavLink>
                            <NavLink to='/adminRetrait' style={{textDecoration:'none'}}>
                                <div className={"box " + (page === "retrait" ? "active" : "inactive")}>
                                    <CreditScoreIcon className='boxIcon'/>
                                    <span>Retrait</span>
                                </div>
                            </NavLink>
                            <NavLink to='/artistes' style={{textDecoration:'none'}}>
                                <div className={"box " + (page === "artistes" ? "active" : "inactive")}>
                                    <LibraryMusicIcon className='boxIcon'/>
                                    <span>Artistes</span>
                                </div>
                            </NavLink>
                            <NavLink to='/manageData' style={{textDecoration:'none'}}>
                                <div className={"box " + (page === "manageData" ? "active" : "inactive")}>
                                    <FolderIcon className='boxIcon'/>
                                    <span>Gestion des données</span>
                                </div>
                            </NavLink>
                            <NavLink to='/sendMail' style={{textDecoration:'none'}}>
                                <div className={"box " + (page === "sendMail" ? "active" : "inactive")}>
                                    <EmailIcon className='boxIcon'/>
                                    <span>Mail</span>
                                </div>
                            </NavLink>
                            <NavLink to='/addPodcast' style={{textDecoration:'none'}}>
                                <div className={"box " + (page === "podcast" ? "active" : "inactive")}>
                                    <PodcastsIcon className='boxIcon'/>
                                    <span>Ajouter Podcasts</span>
                                </div>
                            </NavLink>
                        </div>
                    </div>
                    <div className="logout">
                        <button onClick={handleLogout}>Deconnexion</button>
                    </div>
            </div>
            <div className="navbar-content">
                <div className="navbar">
                    <div className="navbarIcon">
                        <MenuSharpIcon style={{color:'#5f6065'}} onClick={handleSetMenu}/>
                        <NotificationsActiveSharpIcon style={{color:'#5f6065'}}/>
                    </div>
                    <NavLink to='/addMusic' className='addMusicPc'>Ajouter une musique</NavLink>
                    <div className="content">
                        <input type="search" placeholder="Search..." onChange={handleChange}value={search}/>
                        <img src={profile} alt="" />
                        <KeyboardArrowDownSharpIcon className='rightSideNavbarContentIcon' onClick={handleSetDisplayAddButton}/>
                    </div>
                </div>
            </div>
            <div className={"addMusicTel " + (displayAddButton ? "active":"inactive")}>
                <NavLink to='/addMusic' className='button'>Ajouter une musique</NavLink>
            </div>
        </div>
    )
}

export default LeftSideAdmin;