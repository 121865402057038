import React, {useState} from 'react'
import { FaRobot } from "react-icons/fa6";

export default function Chatbot() {
    const [showBot, setShowBot] = useState(false)

    const handleShowBot = () => {
        setShowBot(!showBot)
    }
  return (
    <div className={'fixed bottom-0 right-10 z-10 flex flex-col justify-end items-end ' + (showBot ? "w-[300px] h-[400px]": "w-[50px] h-[50px]")}>
      <iframe src="https://lithyus-music-chatbot.vercel.app/" frameBorder="0" className={'h-full w-full absolute bottom-20 ' + (showBot ? "block": "hidden -z-10 h-0")}></iframe>
      <div onClick={handleShowBot} className='text-white w-12 h-12 rounded-full bg-red-500 border-1 cursor-pointer border-black flex justify-center items-center absolute right-0 bottom-5 text-3xl'>
        <FaRobot />
      </div>
    </div>
  )
}
