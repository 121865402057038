import React, { useState, useEffect } from "react";
// import { useMutation, queryClient } from "react-query";
import axios from "axios";
import LeftSideAdmin from "../../components/Slide/LeftSideAdmin";
import AddIcon from '@mui/icons-material/Add';
import './manageData.scss'
import LoginAdmin from "../admin_login/Login";

function ManageData(){
    const [selectedFile, setSelectedFile] = useState(null)
    const [wait, setWait] = useState("");

    const uploadExcelFile = async () => {
        try {
          const formData = new FormData();
          formData.append("file", selectedFile);
          const res = await axios.post("/api/excelFile/upload", formData, {
            withCredentials:true
          });
          return res.data;
        } catch (err) {
          console.log(err);
        }
    }

    const setStreamData = async () => {
      try {
        const res = await axios.post("/api/data/setStreamData", {}, {
          withCredentials:true
        })
        return res.data;
      }catch(error)
      {
        console.log(error)
      }
    }
    const setRoyaltiesData = async () => {
      try {
        const res = await axios.post("/api/data/setRoyaltiesData", {}, {
          withCredentials:true
        })
        return res.data;
      }catch(error)
      {
        console.log(error)
      }
    }
    const setMonthData = async () => {
      try {
        const res = await axios.post("/api/data/setMonthData", {}, {
          withCredentials:true
        })
        return res.data;
      }catch(error)
      {
        console.log(error)
      }
    }
    const handleClick = async(event) => {
        event.preventDefault();
        setWait("En attente");
        if (selectedFile) await uploadExcelFile();
        await setStreamData();
        await setRoyaltiesData();
        await setMonthData();
        
        window.location.href = '/admin';
    }
    const handleDrop = (event, fileType) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];
        setSelectedFile(file);
      };
      const handleFileClick = (fileType) => {
        const fileInput = document.createElement("input");
        fileInput.type = "file";
        fileInput.accept = ".xlsx, .xls";
        fileInput.onchange = (event) => {
            const file = event.target.files[0];
            setSelectedFile(file);
        };
        fileInput.click();
      };
    
      const handleDragOver = (event) => {
        event.preventDefault();
      };
    
      const handleDragLeave = (event) => {
        event.preventDefault();
      };

      const [isAuthenticated, setIsAuthenticated] = useState(false);
    
    useEffect(() => {
        const checkAuth = async () => {
            try {
                await axios.post('/api/users/verifyAdmin', {}, {
                    withCredentials:true,
                });
                setIsAuthenticated(true);
            } catch (error) {
                console.error('Erreur lors de la vérification de l\'authentification', error);
            }
        };
        
        checkAuth();
    }, []);
    if (isAuthenticated){
      return (
          <div className="manageData">
              <LeftSideAdmin page='manageData' />
              <div className="rightSide">
                  <div className="cov">
                      <div
                          className="drag-or-click-field image"
                          onDrop={(event) => handleDrop(event, "image")}
                          onDragOver={handleDragOver}
                          onDragLeave={handleDragLeave}
                          onClick={() => handleFileClick("image")}
                      >
                          {selectedFile ? <p>Fichier selectionné : {selectedFile.name}</p> 
                          :
                              <p>
                                  Glissez et déposez un Fichier excel ici ou cliquez pour sélectionner un fichier
                                  <AddIcon className="addAudioIcon"/>
                              </p>
                          }
                      </div>
                  </div>
                  <button onClick={handleClick}>Envoyer les données</button>
              </div>
              {
                wait === "En attente"
                  ?
                  <div className="attente">
                      <span>En attente</span>
                      <div className="loader-container">
                          <div className="dot d1"></div>
                          <div className="dot d2"></div>
                          <div className="dot d3"></div>
                      </div>
                  </div>
                  :null
              }
          </div>
      )

    }else{
      return <LoginAdmin />
    }
}

export default ManageData;