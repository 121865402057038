import React, { useState, useContext, useEffect } from "react";
import AreaChart from "../../components/Chart/AreaChart";
import BarChart from "../../components/Chart/BarChart";
import axios from 'axios'
import { useQuery } from "react-query";
import { AuthContext } from "../../controllers/authControllers"
import Music from "../../components/music/Music";
import AllTransactions from "../../components/transactions/AllTransactions";
import LeftSideAdmin from "../../components/Slide/LeftSideAdmin";
import LoginAdmin from "../admin_login/Login";
import Royalties from "../../components/royalties/Royalties";
import Loader from "../../components/loading/Loader";
import MostGender from "../../components/mostGender/MostGender";

import MoreHorizSharpIcon from '@mui/icons-material/MoreHorizSharp';
import ArrowDropUpSharpIcon from '@mui/icons-material/ArrowDropUpSharp';
import HeadsetSharpIcon from '@mui/icons-material/HeadsetSharp';
import AttachMoneySharpIcon from '@mui/icons-material/AttachMoneySharp';
import AssignmentSharpIcon from '@mui/icons-material/AssignmentSharp';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import icon2 from '../../assets/icon/Depenses.png'
import icon10 from '../../assets/icon/revenus.png'
import './admin.scss'

function Admin() {
    const currentUser = useContext(AuthContext);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    
    useEffect(() => {
        const checkAuth = async () => {
            try {
                await axios.post('/api/users/verifyAdmin', {}, {
                    withCredentials:true,
                });
                setIsAuthenticated(true);
            } catch (error) {
                console.error('Erreur lors de la vérification de l\'authentification', error);
            }
        };
        
        checkAuth();
    }, []);
    const musicData = useQuery(["music"], () =>
        
        axios.get(`/api/music/getAllMusic`, {
            withCredentials:true
        }).then((res) => {    
            return res.data;
        })
    )

    const transactionsData = useQuery(["transactions"], () =>
        
        axios.get(`/api/transactions/getAllArtistesTransactions`, {
            withCredentials:true
        }).then((res) => {    
            return res.data;
        })
    )
    
    let retrait = 0;
    
    if (transactionsData.data) {
        retrait = Math.floor(transactionsData.data.reduce((sum, item) => {
            const {Montant } = item;
            const sumOfServices = Montant;
            return sum + sumOfServices;
        }, 0) * 100) / 100;
    }
    
    const streamData = useQuery(["stream"], () =>
        
        axios.get(`/api/royalties/getAllStream?musicienId=${currentUser.currentUser.idMusicien}`, {
            withCredentials:true
        }).then((res) => {    
            return res.data;
        })
    )
    const nbrMusicien = useQuery(["nbrMusicien"], () =>
        
        axios.get(`/api/users/getNbrMusicien`, {
            withCredentials:true
        }).then((res) => {    
            return res.data;
        })
    )
    const royaltiesData = useQuery(["royalties"], () =>
        
        axios.get(`/api/royalties/getAllRoyalties?musicienId=${currentUser.currentUser.idMusicien}`, {
            withCredentials:true
        }).then((res) => {    
            return res.data;
        })
    )
    const countryData = useQuery(["countryData"], () =>
        
        axios.get(`/api/users/getUserCountry`, {
            withCredentials:true
        }).then((res) => {    
            return res.data;
        })
    )
    let revenue = 0;
    
    if (royaltiesData.data) {
        revenue = Math.floor(royaltiesData.data.reduce((sum, item) => {
            const {idroyalties, music_title, musicien_id, ...others} = item;
            const sumOfServices = Object.values(others).reduce((acc, value) => acc + value, 0);
            return sum + sumOfServices;
        }, 0) * 100) / 100;
    }
    console.log(royaltiesData)
    const monthData = useQuery(["monthData"], () =>
        
        axios.get(`/api/data/getAllMonthData?musicienId=${currentUser.currentUser.idMusicien}`, {
            withCredentials:true
        }).then((res) => {    
            return res.data;
        })
    )
    const mostGender = useQuery(["mostGender"], () =>
        
        axios.get(`/api/music/getMostGender`, {
            withCredentials:true
        }).then((res) => {    
            return res.data;
        })
    )
    let result;
    if (monthData.data){
    
        result = monthData.data.reduce((acc, item) => {
            const { month, service, stream } = item;
            const key = `${month}_${service}`;
            
            if (acc[key]) {
            acc[key] += stream;
            } else {
            acc[key] = stream;
            }
            return acc;
        }, {});
    }
    let totalValue;
    let minValue;
    let maxValue;
    let meanValue;
    if (result) {
        totalValue = Object.values(result).reduce((sum, value) => sum + value, 0);
        const values = Object.values(result);
        if (values.length){
    
            minValue = Math.min(...values);
            maxValue = Math.max(...values);
            meanValue = Math.floor(totalValue / Object.keys(result).length);
        }else{
            minValue = 0;
            maxValue = 0;
            meanValue = 0;
        }
    }
    if (isAuthenticated)
    {
        return(
            <div className="admin">
                <LeftSideAdmin page="admin" />
                <div className="rightSide">
                    <div className="chiffre">
                        <div className="content">
                            <div className="box">
                                <div className="boxContent">
                                    <div className="iconContent">
                                        <img src={icon10} alt="" />
                                    </div>
                                    <div className="descContent">
                                        <span>
                                            Revenue
                                        </span>
                                        <h4>
                                            {revenue} $
                                        </h4>
                                    </div>
                                </div>
                            </div>
                            <div className="box">
                                <div className="boxContent">
                                    <div className="iconContent">
                                        <img src={icon2} alt="" />
                                    </div>
                                    <div className="descContent">
                                        <span>
                                            Retrait
                                        </span>
                                        <h4>
                                            {retrait} $
                                        </h4>
                                    </div>
                                </div>
                            </div>
                            <div className="box">
                                <div className="boxContent">
                                    <div className="iconContent">
                                        <HeadsetSharpIcon />
                                    </div>
                                    <div className="descContent">
                                        <span>
                                            Ecoutes
                                        </span>
                                        <h4>
                                            {totalValue}
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="content">
                            <div className="box">
                                <div className="boxContent">
                                    <div className="iconContent">
                                        <AttachMoneySharpIcon />
                                    </div>
                                    <div className="descContent">
                                        <span>
                                            Votre solde
                                        </span>
                                        <h4>
                                           {Math.floor((revenue - retrait) * 100) / 100} $
                                        </h4>
                                    </div>
                                </div>
                            </div>
                            <div className="box">
                                <div className="boxContent">
                                    <div className="iconContent">
                                        <AssignmentSharpIcon />
                                    </div>
                                    <div className="descContent">
                                        <span>
                                            Depenses
                                        </span>
                                        <h4>
                                            $
                                        </h4>
                                    </div>
                                </div>
                            </div>
                            <div className="box">
                                <div className="boxContent">
                                    <div className="iconContent">
                                        <MusicNoteIcon />
                                    </div>
                                    <div className="descContent">
                                        <span>
                                            Musiques / Musiciens
                                        </span>
                                        <h4>
                                            {musicData.data ? musicData.data.length : 0} / {nbrMusicien.data ? nbrMusicien.data[0].total_musicien : 0}
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="music">
                        <div className="title">
                            New Releases
                        </div>
                        <div className="content">
                            <div className="top">
                                <div className="titre">
                                    Titre
                                </div>
                                <div className="date">
                                    Date
                                </div>
                                <div className="status">
                                    Status
                                </div>
                                <div className="promute">
                                    Promotion
                                </div>
                            </div>
                            <hr />
                            <div className="contentBox">
                                {musicData.error
                                    ?"Une erreur s'est produit"
                                    :musicData.isLoading
                                        ?<Loader type="spiner"/>
                                        : musicData.data.length
                                            ? musicData.data.map((music) =><Music music={music} key={music.idmusic}/>)    
                                        : <span style={{
                                            textAlign:'center',
                                            color:'white'
                                        }}>Pas encore de Music</span>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="chartContainer">
                        <div className="revenu">
                            <div className="top">
                                <AutoGraphIcon className="icon"/>
                            </div>
                            {
                                result
                                    ?
                                        <div className="chart">
                                            <AreaChart chartData = {result}/>
                                        </div>
                                    :  
                                        <div className="chart">
                                    
                                        </div>
                            }
                            <div className="bottom">
                                <div className="stats">
                                    <div className="content">
                                        <span>Valeur Totale</span>
                                        <h4>{totalValue}</h4>
                                    </div>
                                    <div className="content">
                                        <span>Plus petite Valeur</span>
                                        <h4>{minValue}</h4>
                                    </div>
                                    <div className="content">
                                        <span>Plus grande Valeur</span>
                                        <h4>{maxValue}</h4>
                                    </div>
                                    <div className="content">
                                        <span>Valeur Moyenne</span>
                                        <h4>{meanValue}</h4>
                                    </div>
                                </div>
                                {/* <div className="objectif">
                                    <span>Objectif visé</span>
                                    <Bar percentage='80'/>
                                </div> */}
                            </div>
                        </div>
                        <div className="weekly">
                            <div className="top">
                                <AutoGraphIcon className="icon"/>
                            </div>
                            <div className="chart">
                                {countryData.error
                                    ?"Une erreur s'est produit"
                                    :countryData.isLoading
                                        ?<Loader type="spiner"/>
                                        : countryData.data.length
                                            ? <BarChart status="DailyListen" countryData={countryData.data} />
                                            : <span style={{
                                                textAlign:'center',
                                                color:'white'
                                            }}>Pas encore de musiques</span>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="traffic">
                        <div className="topGender">
                            <div className="top">
                                <span>Top 5 des genres</span>
                                <div className="TopGenderIcon">
                                    <MoreHorizSharpIcon />
                                </div>
                            </div>
                            {mostGender.error
                                ?"Une erreur s'est produit"
                                :mostGender.isLoading
                                    ?<Loader type="spiner"/>
                                    : mostGender.data.length
                                        ? <MostGender gender = {mostGender} music={musicData} />
                                        : <span style={{
                                            textAlign:'center',
                                            color:'white'
                                        }}>Pas encore de musiques</span>
                            }
                        </div>
                        <div className="dailyTraffic">
                            <div className="top">
                                <span>Traffic quotidien</span>
                                <div className="topGenderRight">
                                    <ArrowDropUpSharpIcon />
                                    <span>+2.45%</span>
                                </div>
                            </div>
                            <div className="topGenderBottom">
                                <h4>2.579</h4>
                                <span>Visitors</span>
                            </div>
                            <div className="topGenderBottomChart">
                                <BarChart status='traffic'/>
                            </div>
                        </div>
                    </div>
                    <div className="royalties">
                        <div className="title">
                            Stream
                        </div>
                        <div className="content">
                            <div className="top">
                                <div className="royaltiesMusic">
                                    Musiques
                                </div>
                                <div className="spotify">
                                    Spotify
                                </div>
                                <div className="apple">
                                    Deezer
                                </div>
                                <div className="google">
                                    Youtube
                                </div>
                                <div className="autre">
                                    Apple Music
                                </div>
                                <div className="youtube">
                                    Autres
                                </div>
                                <div className="total">
                                    Total
                                </div>
                            </div>
                            <hr />
                            <div className="contentBox">
                                {streamData.error
                                    ?"Une erreur s'est produit"
                                    :streamData.isLoading
                                        ?<Loader type="spiner"/>
                                        : streamData.data.length
                                            ? streamData.data.map((royalties) =><Royalties royalties={royalties} key={royalties.idstream}/>)   
                                            : <span style={{
                                                textAlign:'center',
                                                color:'white'
                                            }}>Pas encore de Stream</span>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="royalties">
                        <div className="title">
                            Royalties
                        </div>
                        <div className="content">
                            <div className="top">
                                <div className="royaltiesMusic">
                                    Musiques
                                </div>
                                <div className="spotify">
                                    Spotify
                                </div>
                                <div className="apple">
                                    Deezer
                                </div>
                                <div className="google">
                                    YouTube
                                </div>
                                <div className="autre">
                                    Apple Music
                                </div>
                                <div className="youtube">
                                    Autres
                                </div>
                                <div className="total">
                                    Total
                                </div>
                            </div>
                            <hr />
                            <div className="contentBox">
                                {royaltiesData.error
                                    ?"Une erreur s'est produit"
                                    :royaltiesData.isLoading
                                        ?<Loader type="spiner"/>
                                        : royaltiesData.data.length
                                            ? royaltiesData.data.map((royalties) =><Royalties royalties={royalties} key={royalties.idroyalties}/>)   
                                            : <span style={{
                                                textAlign:'center',
                                                color:'white'
                                            }}>Pas encore de Royalties</span>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="withdrawal">
                        <div className="title">
                            Retrait
                        </div>
                        <div className="content">
                            <div className="top">
                                <div className="id">
                                    ID
                                </div>
                                <div className="titre">
                                    Artistes
                                </div>
                                <div className="montant">
                                    Montant
                                </div>
                                <div className="methodes">
                                    Methodes
                                </div>
                                <div className="status">
                                    Status
                                </div>
                            </div>
                            <hr />
                            <div className="contentBox">
                            {transactionsData.error
                                    ?"Une erreur s'est produit"
                                    :transactionsData.isLoading
                                        ?<Loader type="spiner"/>
                                        : transactionsData.data.length
                                            ? transactionsData.data.map((transactions) =><AllTransactions transactions={transactions} key={transactions.idtransactions}/>)     
                                            : <span style={{
                                                textAlign:'center',
                                                color:'white'
                                            }}>Pas encore de Transaction</span>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="copyright">
                        <span>© 2024 Lithyus Music Group, LLC. Tous droits réservés.</span>
                    </div>
                </div>
            </div>
        )
    }else{
        return <LoginAdmin />
    }
}

export default Admin;