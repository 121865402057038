import React, { useState } from "react";
import "./signup.scss";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import Loader from "../../components/loading/Loader"
import { useLocation } from 'react-router-dom';
import Chatbot from "../../components/v2/chatbot";

function ChangePassword() {
  const location = useLocation();
  const Navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isValidPassword, setIsValidPassword] = useState(true);


  const [signupData, setSignupData] = useState({
    password: "",
    confirmPassword: ""
  });

  const [errors, setErrors] = useState({
    password: "",
    confirmPassword: "",
  });
  const [errorSignup, setErrorSignup] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSignupData({ ...signupData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const handleSignup = async () => {
    const { password, confirmPassword } = signupData;
    const newErrors = {};

    if (!password) {
      newErrors.password = "Veuillez remplir le champ mot de passe";
    }

    if (!confirmPassword) {
      newErrors.confirmPassword =
        "Veuillez remplir le champ de confirmation du mot de passe";
    } else if (password !== confirmPassword) {
      newErrors.confirmPassword =
        "Mot de passe différent";
    }
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    try {
      // console.log("test test test");
      setLoading(true);
      const params = new URLSearchParams(location.search);
      const token = params.get('token');
      await axios.post(`/api/users/changePassword?token=${token}`, signupData, {
        withCredentials:true,
      });
      Navigate('/login');
    } catch (error) {
      setErrorSignup(error.response.data);
      setLoading(false);
    }
  };
  const validatePassword = () => {
    const regex = /^(?=.*[A-Z])(?=.*[@!*&%#?-_+=]).{8,}$/;

    if (regex.test(signupData.password)) {
      setIsValidPassword(true);
    } else {
      setIsValidPassword(false);
    }
  };
  return (
    <React.Fragment>
      <div className="signup">
        <Navbar />
        <Chatbot />
        <div className="div-main">
          <div className="div-main2">
            <div className="top-div">
              <div className="left">
                <span className="text">
                  {" "}
                  <span>
                    <FontAwesomeIcon icon={faCheck} className="red-pi-check" />
                  </span>{" "}
                  DISTRIBUTION GRATUITE
                </span>
                <span className="text">
                  <span>
                    <FontAwesomeIcon icon={faCheck} className="red-pi-check" />
                  </span>{" "}
                  MISE EN LIGNE RAPIDE
                </span>
              </div>
              <div className="right">
                <span className="text">
                  <span>
                    <FontAwesomeIcon icon={faCheck} className="red-pi-check" />
                  </span>{" "}
                  DONNÉES EN TEMPS RÉELS
                </span>
                <span className="text">
                  <span>
                    <FontAwesomeIcon icon={faCheck} className="red-pi-check" />
                  </span>{" "}
                  SERVICE CLIENT EFFICACE
                </span>
              </div>
            </div>
            <div className="centered-div">
              <div className="form-container">
                {errorSignup
                  ?
                    <div 
                      style={{
                        width:'90%',
                        color:'red',
                        textAlign:'center',
                        marginLeft:'auto',
                        marginRight:'auto'
                      }}>{errorSignup.message}</div>
                  :null 
                }
                <div>
                  <input
                    type="password"
                    placeholder="Mot de passe"
                    value={signupData.password}
                    onChange={handleInputChange}
                    onBlur={validatePassword}
                    name="password"
                  />
                  {errors.password && (
                    <span className="error">{errors.password}</span>
                  )}
                </div>
                <div>
                  <input
                    type="password"
                    placeholder="confirmer mot de passe"
                    value={signupData.confirmPassword}
                    onChange={handleInputChange}
                    name="confirmPassword"
                  />
                  {errors.confirmPassword && (
                    <div className="error">{errors.confirmPassword}</div>
                  )}
                </div>
                <div style={{position:'relative', display:'flex', alignItems:'center', justifyContent:'center'}}>
                {
                  loading
                    ?<Loader type="newtons-cradle" />
                    :
                      <button
                        id="button-sigin"
                        onClick={handleSignup}
                      >
                        Modifier le mot de passe
                      </button>

                }
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </React.Fragment>
  );
}

export default ChangePassword;
