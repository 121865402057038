import React, {useState, useContext} from "react"
import axios from 'axios'
import { useMutation, useQueryClient } from "react-query";
import { useNavigate, NavLink } from "react-router-dom";
import logo from '../../assets/logoLithyus.webp'
import ClearIcon from '@mui/icons-material/Clear'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import AddIcon from '@mui/icons-material/Add';
import Footer from "../../components/footer/Footer";
import Loader from "../../components/loading/Loader";
import { AuthContext } from "../../controllers/authControllers"
import './addMusic.scss'

const getImageSize = (file) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      resolve({ width: img.width, height: img.height });
    };
    img.onerror = () => {
      reject(new Error("Erreur lors du chargement de l'image."));
    };
    img.src = URL.createObjectURL(file);
  });
};

function AddMusicTest(){
  const Navigate = useNavigate()
  const currentUser = useContext(AuthContext);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showImage, setShowImage] = useState(null);
  const [showAudio, setShowAudio] = useState(null);
  const [selectedAudio, setSelectedAudio] = useState(null);
  const [audioClass, setAudioClass] = useState("no-audio");
  const [imageError, setImageError] = useState(false);
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);
  const [musicData, setMusicData] = useState({
    music_title:"",
    music_genre:"",
    music_lang:"",
    date:""
  });
  const [errors, setErrors] = useState({
    music_title:"",
    music_genre:"",
    music_lang:"",
    date:"",
    cover:"",
    music:""
  });

  const uploadMusic = async () => {
    try {
      const formData = new FormData();
      formData.append("file", selectedAudio);
      const res = await axios.post("/api/musicFile/upload", formData, {
        withCredentials:true
      });
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };
  const uploadCov = async () => {
    try {
      const formData = new FormData();
      formData.append("file", selectedImage);
      const res = await axios.post("/api/cov/upload", formData, {
        withCredentials:true
      });
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };

  const handleChange = (event) => {
    setMusicData((prev) => ({
        ...prev, [event.target.name]:event.target.value
    }));
  }

  const mutation = useMutation(
    (newMusic) => {
      setLoading(true)
      return axios.post("/api/music/addMusic", newMusic, {
        withCredentials:true
      });
    },
    {
      onSuccess: () => {
        // Invalidate and refetch
        queryClient.invalidateQueries(["music"]);
      },
    }
  );
  const [existMusic, setExistMusic] = useState("")
  const handleClick = async (e) => {
    e.preventDefault();
    const {music_title, music_lang, music_genre, date} = musicData;
    const newErrors = {};

    if (!music_title){
      newErrors.music_title= "Veuillez donner le nom de votre musique";
    }
    if (!music_lang){
      newErrors.music_lang= "Veuillez donner la langue de votre musique";
    }
    if (!music_genre){
      newErrors.music_genre= "Veuillez donner le genre de votre musique";
    }
    if (!selectedAudio){
      newErrors.music = "Veillez choisir une musique"
    }
    if (!selectedImage){
      newErrors.cover = "Veillez choisir le cover de votre musique"
    }
    if (!date){
      newErrors.date = "Veillez selectionner la date de sortie de votre musique"
    }
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    let musicUrl = "";
    let covUrl = "";
    setLoading(true)
    try{
      if (selectedAudio) musicUrl = await uploadMusic();
      if (selectedImage) covUrl = await uploadCov();
      mutation.mutate({ musicData, music_cov: covUrl.cov, music_file: musicUrl.music });
      try {
        const formData = new FormData();
        const metadata = {
          Artist: currentUser.currentUser.nom_artiste,
          Title:musicData.music_title,
          Album:musicData.music_title,
          Filename:musicUrl.music,
          Genre:musicData.music_genre,
          Explicit:'Yes'
        }
        Object.entries(metadata).forEach(([key, value]) => {
          formData.append(`meta[${key}]`, value);
        });
  
        formData.append('token', '9595-7ceb19ac5763d0f95ca2cc9e95cd1d68');
        formData.append('autopublish', 1);
        formData.append('url', `https://lithyusmusic.com/api/static/music/${musicUrl.music}`)
        const apiUrl = 'https://lithyus.sourceaudio.com/api/import/upload';
      const response = await axios.post(apiUrl, formData, {
        withCredentials:false
      });
      console.log(response)
      } catch (error) {
        console.error('Erreur lors de la requête API :', error);
      }
      setMusicData({
        music_title:"",
        music_genre:"",
        music_lang:"",
        date:""
      });
      setSelectedAudio(null);
      setSelectedImage(null);
      Navigate('/lithyusMusicOnlyOnlineAdminDashbord');
    }catch(error)
    {
      console.log(error)
    }
  };

  const handleDrop = (event, fileType) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (fileType === "image") {
      setSelectedImage(file);
    } else if (fileType === "audio") {
      setSelectedAudio(file);
    }
  };
  const handleFileClick = (fileType) => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    if (fileType === "image") {
      fileInput.accept = "image/jpg, image/png, image/jpeg";
    } else if (fileType === "audio") {
      fileInput.accept = "audio/wav";
    }
    fileInput.onchange = async (event) => {
      const file = event.target.files[0];
      if (fileType === "image") {
        try {
          const imageSize = await getImageSize(file);
          console.log("Largeur de l'image:", imageSize.width);
          console.log("Hauteur de l'image:", imageSize.height);
          if ((imageSize.width !== imageSize.height)
              || (imageSize.width < 3000 || imageSize.width > 6000)
              || (imageSize.height < 3000 || imageSize.height > 6000)){
            setImageError(true)
            setSelectedImage(null);
            setShowImage(null);
          }else {
            setSelectedImage(file);
            setShowImage(URL.createObjectURL(file));
            setImageError(false)
          }
        } catch (error) {
          console.error("Erreur lors de la récupération de la taille de l'image:", error);
        }
      } else if (fileType === "audio") {
        setSelectedAudio(file);
        setAudioClass('have-audio')
        setShowAudio(URL.createObjectURL(file));
      }
    };
    fileInput.click();
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
  };
    return(
        <React.Fragment>
            <div className="addMusic">
                <div className="addMusic-navbar">
                    <div className="logo">
                        <img src={logo} alt="logo de Lithyus" />
                    </div>
                    <div className="respect">
                        <span className="txt">Respectez les consignes donnés et envoyez des musiques de qualité supérieure</span>
                    </div>
                    <div className="cancel">
                        <NavLink to='/dashboard'><ClearIcon /></NavLink>
                    </div>
                </div>
                <div className="music-details">
                    <h2>Détails de la musique</h2>
                    <form className="form" onSubmit={handleClick} method="POST">
                        <input 
                          type="text"
                          name="music_title"
                          placeholder="Tire de la musique"
                          onChange={handleChange}
                          value={musicData.music_title}
                        />
                        {errors.music_title && <span style={{color:'red'}}>{errors.music_title}</span>}
                        <select name="music_genre" onChange={handleChange}>
                            <option value="">Sélectionnez un genre</option>
                            <option value="Alternative">Alternative</option>
                            <option value="Hip Hop/Rap">Hip Hop/Rap2</option>
                            <option value="R&B/Soul">R&B/Soul</option>
                            <option value="Trap">Trap</option>
                            <option value="Hip-Hop / Trap">Hip Hop</option>
                        </select>
                        {errors.music_genre && <span style={{color:'red'}}>{errors.music_genre}</span>}
                        <select
                          name="music_lang"
                          onChange={handleChange}
                          id="selectlanguage1"
                        >
                            <option value="">Sélectionnez une langue</option>
                            <option value="français">Français</option>
                            <option value="anglais">Anglais</option>
                            <option value="mooré">Mooré</option>
                        </select>
                        {errors.music_lang && <span style={{color:'red'}}>{errors.music_lang}</span>}
                        <div
                            className={'drag-or-click-field ' + audioClass}
                            onDrop={(event) => handleDrop(event, "audio")}
                            onDragOver={handleDragOver}
                            onDragLeave={handleDragLeave}
                            onClick={() => handleFileClick("audio")}
                        >
                            {selectedAudio ? (
                                <>
                                  {/* <p>Audio sélectionné : {selectedAudio.name}</p> */}
                                    <audio controls>
                                      <source src={showAudio} type="audio/wav" />
                                      Votre navigateur ne prend pas en charge l'élément audio.
                                    </audio>
                                  <div className="changer-music">
                                    changer
                                  </div>
                                </>
                              ) : (
                                <p>
                                  Glissez et déposez un fichier audio ici ou cliquez pour sélectionner
                                  un fichier audio.
                                  <AddIcon className="addAudioIcon" />
                                </p>
                            )}
                        </div>
                        {errors.music && <span style={{color:'red'}}>{errors.music}</span>}
                        <span>
                          Les fichiers audio doivent être au format WAV (en 16 bits, 44,1 kHz, stéréo) et de bonne qualité afin 
                          d'être approuvés pour leur diffusion sur les plateformes de musique. Cliquez <a href="https://convertio.co/" target="_blank" rel="noreferrer">Ici</a> pour modifier vos audios
                        </span>
                        <div className="cov">
                            <div
                                className="drag-or-click-field image"
                                onDrop={(event) => handleDrop(event, "image")}
                                onDragOver={handleDragOver}
                                onDragLeave={handleDragLeave}
                                onClick={() => handleFileClick("image")}
                            >
                                {selectedImage ? (
                                <img src={showImage} alt="Selected" />
                                ) : (
                                <p>
                                    Glissez et déposez une image ici ou cliquez pour sélectionner une
                                    image.
                                    <AddIcon className="addAudioIcon"/>
                                    {
                                      imageError
                                        ?
                                          <span style={{color:'red'}}>
                                            la taille de votre image ne correspond pas veillez lire les consignes. <br />
                                          </span>
                                        :null
                                    }
                                </p>
                                
                                )}
                            </div>
                                
                            <div className="description">
                              La photo de couverture doit être un fichier .jpg ou .png carré et avoir une résolution minimale de 3000x3000 pixels et maximale de 6000x6000 pixels, sans être flou ou pixelisé. Il ne doit pas contenir les éléments suivants :
                              <span>
                                <FiberManualRecordIcon className="point"/>
                                Logos ou identifiants des réseaux sociaux.
                              </span>
                              <span>
                                <FiberManualRecordIcon className="point"/>
                                Logos de marque.
                              </span>
                              <span>
                                <FiberManualRecordIcon className="point"/>
                                Tout texte, à l'exception des noms d'artistes et/ou du nom de la publication.
                              </span>
                              <span>
                                <FiberManualRecordIcon className="point"/>
                                Si votre photo de couverture contient l'un de ces éléments, votre publication sera rejetée (ces règles 
                                sont établies par les plateformes et nous devons les suivre).
                              </span>
                            </div>
                        </div>
                        {
                          imageError
                            ?
                              <span style={{color:'red'}}>
                                Cliquez <a href="https://www.resizepixel.com/">ici</a> pour redimensionner vos images
                              </span>
                            :null
                        }
                        {errors.cover && <span style={{color:'red'}}>{errors.cover}</span>}
                        <label htmlFor="music_date">Date de sortie</label>
                        <input type="date" name="date" placeholder="" onChange={handleChange}/>
                        {
                          loading
                            ?<button disabled>
                              <Loader type="newtons-cradle"/>
                            </button>
                            :<button type="submit">Ajouter la musique</button>
                        }
                        {(errors.cover || errors.date || errors.music_genre || errors.music_lang || errors.music_title) && <span style={{color:'red'}}>Veillez entrer tous les champs demandés</span>}
                        {
                          existMusic
                            ?<div style={{color:'red'}}>{existMusic}</div>
                            :null
                        }
                    </form>
                </div>
            </div>
            <Footer />
        </React.Fragment>
    )
}

export default AddMusicTest;
