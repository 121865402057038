import React, { useState, useEffect } from "react";
import "./evenements.scss";
import NavBar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import { FaRegThumbsUp } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { SlLocationPin } from "react-icons/sl";
import { CiCalendarDate } from "react-icons/ci";
import { TbClockHour3 } from "react-icons/tb";
import axios from 'axios';
import Chatbot from "../../components/v2/chatbot";

const CountdownTimer = ({date}) => {
  const eventDate = new Date(date); // Date de l'événement
  const currentDate = new Date(); // Date actuelle

  // Calculer le temps restant en millisecondes
  const timeRemaining = eventDate.getTime() - currentDate.getTime();

  // Convertir le temps restant en jours, heures, minutes et secondes
  const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
  const hours = Math.floor((timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);

  // Utiliser le state pour mettre à jour l'interface
  const [countdown, setCountdown] = useState({ days, hours, minutes, seconds });

  useEffect(() => {
    // Mettre à jour le state toutes les secondes
    const interval = setInterval(() => {
      const now = new Date();
      const remaining = eventDate.getTime() - now.getTime();

      // Vérifier si l'événement est déjà passé
      if (remaining <= 0) {
        clearInterval(interval);
        setCountdown({ days: 0, hours: 0, minutes: 0, seconds: 0 });
      } else {
        const updatedDays = Math.floor(remaining / (1000 * 60 * 60 * 24));
        const updatedHours = Math.floor((remaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const updatedMinutes = Math.floor((remaining % (1000 * 60 * 60)) / (1000 * 60));
        const updatedSeconds = Math.floor((remaining % (1000 * 60)) / 1000);

        setCountdown({ days: updatedDays, hours: updatedHours, minutes: updatedMinutes, seconds: updatedSeconds });
      }
    }, 1000);

    // Nettoyer l'intervalle lorsque le composant est démonté
    return () => clearInterval(interval);
  }, [eventDate]);

  return (
    <div>
      {`${countdown.days} jrs ${countdown.hours} hrs ${countdown.minutes} mn ${countdown.seconds} sec`}
    </div>
  );
};

const Evenement = ({event}) => {
  const dateObject = new Date(event.date);
  const options = { day: 'numeric', month: 'short', year: 'numeric', hour: 'numeric', minute: 'numeric' };
  const formattedDate = dateObject.toLocaleDateString('fr-FR', options);
  return(
    <div key={event.id} className="col-md-3 colonne1">

      <div className="card card-cover overflow-hidden text-bg-dark rounded-4 shadow-lg">
          <img src={event.img} alt="" />
      </div>
      <div className="card-body" >
        <div className="price-box">
          <div className="col-md-9"> <h5 style={{ color: "white" }}>{event.price ? event.price + ' Fcfa':'Gratuit'}</h5></div>
          <div className="col-md-2"><p style={{ color: "white" }}> <FaRegThumbsUp className="icon" style={{ color: "white" }} />0</p></div>
        </div>

        <h6 style={{ color: "white" }} className="desc"></h6>
        <div className="info">
          <div className="col-md-2"> <button type="button" className="btn" ><SlLocationPin className="icon" /></button></div>
          <div className="col-md-8"> <h6 className="pt-2" style={{ color: "white" }}>{event.place}</h6></div>
        </div>
        <div className="info">
          <div className="col-md-2"> <button type="button" className="btn btn-danger" ><CiCalendarDate className="icon" /></button></div>
          <div className="col-md-8"> <h6 className="pt-2" style={{ color: "white" }}>{formattedDate}</h6></div>
        </div>
        <div className="info">
          <div className="col-md-2"> <button type="button" className="btn btn-danger" ><TbClockHour3 className="icon" /></button></div>
          <div className="col-md-8"> <h6 className="pt-2" style={{ color: "white" }}><CountdownTimer date={event.date}/></h6></div>
        </div>
        <div className="info">
          <div className="col-md-10">
            <Link to={`https://www.vinoticket.com/event/${event.id}:lithyus`} className="btn" target="_blank" rel="noopener noreferrer">Acheter votre pass</Link>
          </div>

        </div>

      </div>
    </div>
  )
}

function Evenements() {
  const [data, setData] = useState([])

  async function fetchData() {
    try {

      const response = await axios.get('https://api.vinoticket.com/vino-events', { headers: {"x-auth-vino" :  "Bearer eyJhbGciOiJIUzI1NiIsInR5cXVCJ9.eyJhdXRob3IiOiJ2aibGl0aHldCI6MTUxNjIzOTAyMn0.MmVpmhF1aralBKpE7lnQimUyDQs"} });
      if (response.data.success) {
        let fetchedEvents = [];
          response.data.result.reverse().forEach(event => {
            let item = {
                        id: event._id,
                        img: event.img,
                        place: event.lieu,
                        date: event.date,
                        price: event.prices[0].price,
                      }
            fetchedEvents.push(item)
            setData(fetchedEvents)
          });
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }
  useEffect(() => {
    fetchData();
  }, []);


  return (
    <div className="evenements">
      <NavBar page='propos' />
      <Chatbot />
      <div className="ordinateur">
        <div className="container-fluid">
          <br /><br /><br /><br /><br />
          <div className="row p-5 ligne">
            {
              data.map((event) => <Evenement event={event} key={event.id}/>)
            }
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default Evenements;