import React from "react"
import { Link } from "react-router-dom";
import NavBar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import "./serviceDistributions.scss"

function ServiceDistributions() {
    return(
        <div className="ServiceDistributions">
             <NavBar />
             <div className="corps">
                <div className="title">
                    <span>CONTRAT D’UTILISATION DES SERVICES DE DISTRIBUTION DE LITHYUS MUSIC DISTRIBUTION </span>
                    <p>Version1.0.</p>
                    <p>Dernière mise à jour : 22-05-2024</p>
                </div>
                <div className="body">
                    <span>1. PRÉSENTATION</span>
                    <p>
                    Merci d'avoir choisi les services de distribution de Lithyus Music Distribution (« Lithyus Music Distribution », « nous », « notre », « nos », « le(s)service(s) de distribution(s) »). En vous inscrivant ou en utilisant le(s) service(s) de Lithyus Music Distribution, y compris toute plateforme(s), application(s) mobile(s), site Web ou application(s) logicielle(s) (le(s) « Service(s) de Lithyus Music Distribution »), vous concluez un contrat contraignant avec Lithyus Music Group, LLC, une société basée aux États-Unis dans l’État du Delaware, dans la ville de Newark, app 305, code zip 19713.
                    Votre accord avec nous comprend le présent contrat d’utilisation des services de distribution puis nos termes et conditions d’utilisation (« Conditions d'utilisation ») auxquels ce contrat est soumis ainsi que notre politique de confidentialités (y compris toute modification future), ainsi que toutes les conditions supplémentaires qui peuvent s'appliquer à votre interaction avec le(s) Service(s) de distribution (collectivement, les « Accords »). Veuillez lire attentivement tous les documents. En vous inscrivant ou en utilisant le(s) Service(s) de distribution, vous reconnaissez avoir lu, compris et accepté ces Accords et acceptez d'être lié par eux. Si vous n'acceptez pas ou ne pouvez pas vous conformer aux Accords dans leur intégralité, vous ne devez pas vous inscrire, accéder ou utiliser nos services.
                    Afin d'accéder et d'utiliser nos services, vous devez être âgé d'au moins 18 ans et avoir le pouvoir de conclure un contrat contraignant avec nous et ne pas être empêché de le faire en vertu d'une loi. Si vous avez moins de 18 ans, vous devez être en mesure de fournir une preuve du consentement de votre tuteur immédiatement sur demande. Vous acceptez en outre que les informations que vous nous soumettez sont et resteront à tous moments vrais, exactes et complètes.
                    Veuillez noter que Lithyus Music Distribution ne prend actuellement pas en charge les comptes professionnels. Si vous ouvrez un compte au nom d'une société, d'une organisation ou d'une autre entité, alors (a) « vous » vous inclut, vous et cette entité, et (b) vous garantissez et déclarez que vous êtes autorisé à accorder toutes les autorisations et licences fournies dans les Accords et à lier l'entité aux Accords et que vous acceptez les Accords au nom de l'entité.

                    </p>
                   <span>2. DÉFINITIONS</span>
                   <p>
                    a. « Compte Utilisateur » désigne un compte utilisateur que vous avez créé et par lequel vous êtes autorisé à accéder au(x) Service(s) de distribution de Lithyus Music Distribution.
                    <br />b. « Matériel » désigne tous les documents, actifs et informations que vous soumettez par le biais du ou des Services de Lithyus Music Distribution, y compris, sans s'y limiter, les illustrations, les images, les logos et les vidéos.
                    <br />c. « Métadonnées » désigne les informations intégrées dans un fichier audio qui sont utilisées pour identifier le contenu. Cela inclut, mais sans s'y limiter, le titre du morceau, le nom du groupe ou de l'artiste, le genre de musique, le label et l'année de sortie du morceau.
                    <br />d. « Enregistrements » désigne tous les enregistrements audios que vous soumettez à Lithyus Music Distribution en les téléchargeant via le(s) Service(s) de Lithyus Music Distribution, y compris les compositions sous-jacentes complètes.
                    <br />e. « Magasins » désigne tous les magasins grand public en ligne numérique ou autres détaillants et plateformes numériques avec lesquels Lithyus Music Distribution a conclu des Accords de magasin de temps à autre pendant la Durée.
                    <br />f. « Accords de magasin » désigne les accords avec les magasins (tels que modifiés ou remplacés de temps à autre pendant la durée) auxquels nous sommes parties, et qui concernent la distribution, l'utilisation ou toute autre exploitation de vos enregistrements, documents et métadonnées. Toutes les conditions générales convenues dans le cadre de ces accords de magasin sont à notre seule discrétion.
                    <br />g. « Durée » désigne la période commençant à la date à laquelle vous vous inscrivez, accédez ou utilisez-le(s) Service(s) de Lithyus Music Distribution, jusqu'à la date à laquelle vous cessez d'accéder ou d'utiliser le(s) Service(s) de Lithyus Music Distribution (ou autrement résilié par Lithyus Music Distribution à sa seule discrétion). 
                    <br />h. « Territoire » désigne le monde (ou tout autre territoire spécifié, le cas échéant).
                    <br />i. « Applications tierces » désigne toute application, site Web ou service tiers qui est intégré au(x) Service(s) de Lithyus Music Distribution ou qui est autrement requis (à notre seule discrétion) afin de rendre le(s) Service(s) de Lithyus Music Distribution disponible(s).

                   </p>
                   <span>3. Octroi de droits</span>
                   <p>
                   Tant que vous accédez ou utilisez le(s) Service(s) de Lithyus Music Distribution, vous nous accordez les droits non exclusifs suivants pendant la Durée et sur l'ensemble du Territoire : 
                    <br />a. Pour distribuer, vendre, exécuter publiquement, exposer, diffuser, transmettre, mettre à disposition, sous-licencier, utiliser et exploiter de toute autre manière les Enregistrements et les Matériels par tous les moyens nécessaires ou autrement requis par nos Contrats de Store, y compris, sans s'y limiter, par le biais de toutes les formes de streaming (y compris interactif et non interactif), de téléchargements numériques (y compris permanents et temporaires), de services cloud et via tout autre appareil applicable,  applications ou autres moyens connus ou créés ultérieurement.
                    <br />b. Pour collecter tous les revenus des Stores provenant de l'exploitation ci-dessus (y compris tous les revenus perçus après la Durée qui se rapportent à l'exploitation de tout Enregistrement ayant eu lieu pendant la Durée).
                    <br />c. Pour créer, reproduire, exécuter publiquement et mettre à disposition des clips d'Enregistrements, ou pour permettre à des Stores d'exercer ces droits en notre nom conformément aux Accords de Magasins.
                    <br />d. Pour éditer, altérer ou amender les enregistrements et les documents dans la mesure raisonnablement nécessaire à notre seule discrétion ou comme l'exigent nos accords de magasin.
                    <br />e. Pour utiliser votre nom, nom professionnel, photographie, ressemblance, illustration, image, biographie et autres informations qui vous sont attribuables, que vous avez soumis à Lithyus Music Distribution.
                    <br />f. Pour effectuer des corrections de métadonnées si nécessaire pour se conformer à l'une de nos exigences, règles ou directives ou à celles d'une Boutique, ou à toute autre condition de nos Contrats de boutique.
                    <br />g. Pour synchroniser (et autoriser d'autres personnes à synchroniser, y compris les Stores conformément à nos Accords de Magasins) vos Enregistrements et Matériels en relation temporelle avec toute image visuelle, y compris, sans s'y limiter, dans le cadre d'un soi-disant « flux en direct ».
                    <br />h. Pour autoriser tout partenaire tiers et/ou sous-licencié, qui offre des services permettant la création, l'utilisation et l'exploitation de ce que l'on appelle des « remixes » de vos Enregistrements et du « contenu généré par l'utilisateur » incorporant vos Enregistrements, y compris, sans s'y limiter, Twitch, TikTok, Facebook et Instagram.

                    Tous les droits qui nous sont accordés ci-dessus le sont sur la base d'une licence libre de droits. Rien ne doit être interprété comme constituant un transfert de propriété à Lithyus Music Distribution.

                   </p>
                   <span>4.Votre musique, votre matériel et vos informations</span>
                   <p>
                   Lorsque vous téléchargez vos enregistrements par le biais du ou des services de Lithyus Music Distribution, vous devrez nous soumettre tous les documents et métadonnées associés pour distribution et utilisation par les magasins. Vous soumettez les enregistrements, les documents et les métadonnées à vos propres frais et dans les formats conformes que nous exigeons.
                   Vous êtes entièrement et seul responsable de tous les aspects des enregistrements, des documents et des métadonnées que vous nous soumettez. Si nous jugeons que l'un des Enregistrements, Matériels ou Métadonnées est inapproprié, ou en violation (ou en violation potentielle) de nos directives, règles ou restrictions (y compris nos Accords de Boutique), nous nous réservons le droit, à notre seule discrétion, de supprimer le(s) Enregistrement(s), Matériel(s), Métadonnées et/ou de vous empêcher d'accéder et d'utiliser le(s) Service(s) de Lithyus Music Distribution.
                   Vous acceptez que Lithyus Music Distribution ne soit à aucun moment responsable de tout manquement, erreur ou retard de notre part ou de celle d'un tiers (y compris tout Magasin) dans la distribution des Enregistrements, du Matériel ou des Métadonnées, ou de la suppression de ceux-ci par nous ou par un tiers (y compris tout Magasin) à la suite d'un acte ou d'une omission de votre part de vous conformer à toutes les instructions,  directives, règles et restrictions.
                   </p>
                   <span>5.Redevances et paiements</span>
                   <p>
                   Vous conservez 70 % de vos redevances que nous recevons des Magasins, à l'exception des frais d'agrégation tiers, des taxes applicables dues par vous en vertu de la loi, des frais de paiement et bancaires encourus, et des montants pour couvrir les frais administratifs et les coûts de vente,  y compris la gestion de l'argent et les paiements en votre nom. Vous êtes dans toute la mesure du paiement de toutes les taxes applicables conformément aux lois fiscales de votre pays.
                    Vous garantissez et déclarez que vous êtes seul responsable de l'obtention de toutes les autorisations et licences nécessaires (y compris, sans s'y limiter, toutes les licences mécaniques et d'édition concernant les compositions musicales sous-jacentes) avant la soumission de tout Enregistrement et Matériel via le(s) Service(s) de Lithyus Music Distribution. Vous garantissez et déclarez en outre que vous êtes seul responsable de tous les paiements requis en relation avec la distribution ou toute autre exploitation des Enregistrements et du Matériel via le(s) Service(s) de Lithyus Music Distribution, y compris, sans s'y limiter, tous les paiements à des tiers, y compris ceux qui ont contribué aux Enregistrements et au Matériel (par exemple, les artistes, interprètes, collaborateurs, musiciens, producteurs,  mixeurs, remixeurs, auteurs-compositeurs, éditeurs, designers, etc.).
                    Toutes les redevances et tous les revenus perçus par le biais de Youtube Content ID seront soumis à une commission de 30%.
                    Les enregistrements soumis à une commission partagée ne seront pas facturés sur les redevances accumulées à partir de Youtube Content ID.
                    Vous acceptez que le calcul, la répartition et le paiement de toute redevance soient soumis à toutes les conditions générales de chaque Contrat de magasin. Veuillez noter que certains Magasins peuvent exiger que vos Enregistrements génèrent un nombre minimum de streams avant que des redevances ne soient gagnées et payées pour ces Enregistrements.
                    Les Boutiques nous rapportent vos redevances gagnées et nous déploierons des efforts raisonnables pour créditer ces redevances sur votre compte utilisateur sur une base mensuelle. Les redevances se réfèrent aux mois précédents tels que nous les ont déclarés par les Magasins, après que nous ayons reçu le paiement des Magasins. Chaque Magasin a son propre délai de déclaration de vos redevances gagnées, généralement entre 2 et 6 mois. Lorsque les redevances ont été créditées sur votre compte utilisateur, vous pouvez demander à retirer l'argent. Notez qu'en raison de retards de déclaration, vous ne recevrez aucune redevance pendant votre ou vos premiers mois jusqu'à ce que Lithyus Music Distribution ait reçu les premiers rapports des Magasins sur vos revenus. Tous les revenus nets crédités sur votre compte utilisateur seront détenus sur un compte bancaire appartenant à Lithyus Music Distribution, et vous acceptez de ne pas recevoir d'intérêts ou d'autres revenus.
                    Le montant minimum des gains que vous pouvez retirer est de 10 USD et ne peut pas être versé en plusieurs fois. Vos gains sont accumulés et seront reportés jusqu'à ce que vous atteigniez le seuil de 10 USD, sauf si votre compte utilisateur est résilié ou annulé. Le montant maximal des gains que vous pouvez retirer peut être soumis à des règles et à des restrictions de tiers de temps à autre.
                    Le processus de paiement prend environ 1 à 7 jours ouvrables. Nous ferons des efforts raisonnables pour nous y conformer, mais nous ne donnerons aucune garantie. Si vous n'avez pas retiré vos gains de votre compte utilisateur dans les 3 ans suivant la date à laquelle ces gains sont crédités sur votre compte Amuse, vous acceptez de pouvoir renoncer à ces gains.
                    Le service de paiement actuellement utilisé est Hyperwallet. Vous devez détenir un compte Hyperwallet pour pouvoir recevoir vos redevances gagnées. Lithyus Music Distribution se réserve le droit de modifier le service de paiement sans préavis. Nous nous réservons également le droit de retenir un paiement sans préavis pour quelque raison que ce soit, y compris, mais sans s'y limiter, si nous soupçonnons, à notre seule discrétion, qu'une information est incorrecte ou qu'une personne non identifiée a accédé à votre compte utilisateur.
                    Nous nous réservons également le droit, sans préavis, de prélever sur vos gains (ou de vous facturer d'une autre manière) 10 USD par Enregistrement et par mois (ou l'équivalent alors en vigueur dans toute autre devise) (les « Frais ») si nous ou l'un des Magasins (à notre seule discrétion ou à celle d'un Magasin) identifions une activité de streaming frauduleuse, manipulée, artificielle et/ou illégale en ce qui concerne l'Enregistrement en question. Indépendamment de tout partage mis en place avec d'autres utilisateurs de Lithyus Music Distribution pour cet enregistrement, les frais seront prélevés sur le propriétaire du compte utilisateur.
                    En outre, nous pouvons prendre l'une des mesures suivantes sans préavis lorsque nous déterminons, à notre seule discrétion, que vous êtes, avez été ou pouvez avoir été impliqué dans une conduite frauduleuse, artificielle, manipulée ou illégale, que vous avez téléchargé des enregistrements contrefaits ou que vous êtes autrement en violation des accords :
                    <br />a. Déduire toutes les sommes requises (y compris les frais) de tout solde accumulé de redevances créditées sur votre compte utilisateur ; et/ou
                    <br />b. Déduire toutes les sommes requises (y compris les frais) de tous les futurs revenus de redevances qui auraient autrement été crédités sur votre compte utilisateur ; et/ou
                    <br />c. Facturer toutes les sommes requises (y compris les frais) sur le mode de paiement que vous avez choisi (comme une carte de débit, une carte de crédit, un compte mobile money ou un compte PayPal).
                    Certains pays sont limités par le service de paiement (tel que modifié de temps à autre) et nous ne sommes pas en mesure de traiter les paiements qui vous sont destinés si vous résidez dans un pays restreint. Nous déclinons toute responsabilité si le service de paiement limite les paiements à un pays dans lequel vous résidez. Actuellement, ces pays comprennent, sans s'y limiter, l'Afghanistan, la Biélorussie, la République centrafricaine, la République du Congo, Cuba, l'Érythrée, la Guinée-Bissau, l'Iran, l'Irak, le Liban, la Libye, le Libéria, le Myanmar, la Corée du Nord, la Russie, la Somalie, le Soudan du Sud, le Soudan, la Syrie, le Yémen, le Zimbabwe. Si vous résidez dans un pays restreint, veuillez ne pas vous inscrire pour utiliser le(s) Service(s) de Lithyus Music Distribution, car nous ne serons pas en mesure de vous verser les redevances accumulées. Si vous contournez cela ou enfreignez les conditions d'utilisation du fournisseur de paiement, vous acceptez que nous retenions toutes les redevances et que nous résiliions votre compte sans préavis.
                    Si un magasin signale un montant incorrect de redevances que vous avez gagnées pour quelque raison que ce soit, Lithyus Music Distribution se réserve le droit de l'ajuster rétroactivement. Lithyus Music Distribution n'assume aucune responsabilité de quelque nature que ce soit découlant de ou en relation avec tout signalement des magasins.

                   </p>
                   <span>6. MAGASINS</span>
                   <p>
                   Vous pouvez télécharger des Enregistrements via le(s) Service(s) de Lithyus Music Distribution mais vous ne pouvez choisir les Magasins dans lesquels vous souhaitez que vos Enregistrements soient livrés. Les conditions de nos Contrats de Boutique régissent la base sur laquelle vos Enregistrements, Matériels et Métadonnées sont distribués, utilisés ou exploités de toute autre manière par les Boutiques. Lithyus Music Distribution aura à tout moment l'autorité exclusive et définitive sur les Contrats de magasin qu'elle choisit de conclure ou de retirer. Lithyus Music Distribution ne fournit aucune garantie quant à la distribution, l'utilisation et/ou l'exploitation des Enregistrements, du Matériel ou des Métadonnées par un Magasin particulier et Lithyus Music Distribution n'accepte aucune responsabilité si ces Enregistrements, Matériels ou Métadonnées ne sont pas mis à disposition dans un Magasin particulier ou sont retirés d'un Magasin pour quelque raison que ce soit.
                    Lithyus Music Distribution décline toute responsabilité en cas d'interruption, de retard, d'erreur ou de suspension d'accès, de signalement ou de paiement par les Magasins, que ce soit en tout ou en partie.

                   </p>
                   <span>7. PARTAGE DE REDEVANCE</span>
                   <p>
                   Vous pouvez partager les redevances d'un enregistrement avec d'autres utilisateurs de Lithyus Music Distribution s’ils ont un compte. Cependant, vous n'avez besoin que d'un compte utilisateur pour recevoir des parts de redevances sur les enregistrements d'autres utilisateurs.
                    Vous pouvez ajouter et modifier des partages de redevances pour les enregistrements nouveaux et précédemment publiés (dans la mesure du possible) si vous disposez d'un  compte utilisateur actif. Une fois que les nouveaux partages de redevances sont soumis, tous les utilisateurs invités doivent accepter et confirmer les invitations avant que tout partage de redevances puisse prendre effet. Les partages de redevances soumis prendront effet sur les redevances déclarées à partir du même mois où les partages ont été confirmés. Si vous avez soumis des partages de redevances pour un nouvel Enregistrement, tous les partages de redevances doivent être confirmés le jour de la sortie de l'Enregistrement. En cas de fractionnement non confirmé, le fractionnement non confirmé ne prendra pas effet et sera réattribué à l'utilisateur dont le compte est le propriétaire de l'enregistrement.

                   </p>
                   <span>8. Utilisation interdite</span>
                   <p>
                   Vous ne pouvez pas utiliser le(s) Service(s) de Lithyus Music Distribution à des fins illégales, y compris pour les raisons suivantes (ou équivalent) :
                    <br />•	De quelque manière que ce soit (ou nous croyons, à notre seule discrétion, avoir pour but d'être) artificiel, contrefait, manipulé ou frauduleux.
                    <br />•	Dans le but de nuire (ou de tenter de nuire) à toute autre personne de quelque manière que ce soit.
                    <br />•	D'une manière qui pourrait enfreindre ou violer les Accords ou tout Accord de magasin.
                    Vous ne pouvez pas télécharger d'Enregistrements ou de Matériel qui font (ou nous pensons qu'à notre seule discrétion peuvent faire) l'un des éléments suivants (ou équivalent) :
                    <br />•	Contenir du matériel haineux, raciste, diffamatoire ou incendiaire.
                    <br />•	Promouvoir du matériel sexuellement explicite ou violent.
                    <br />•	Promouvoir la discrimination de toute nature, y compris lorsqu'elle est fondée sur la race, la religion, la nationalité, le handicap ou l'orientation sexuelle.
                    <br />•	Promouvoir, défendre ou aider à toute activité illégale.
                    <br />•	Menacer, harceler, contrarier ou alarmer toute autre personne ou porter atteinte à sa vie privée.
                    <br />•	Usurper l'identité de n'importe qui.
                    <br />•	Enfreindre ou violer les droits d'un tiers ou toute loi, règle ou réglementation.
                    <br />•	Enfreindre ou violer les Accords ou tout Accord de magasin.
                    <br />•	Contenir du contenu généré par ce que l'on appelle « l'intelligence artificielle » (en tout ou en partie).
                    Lithyus Music Distribution se réserve le droit, à sa seule discrétion, de déterminer si une violation (ou une violation anticipée) de l'une des dispositions ci-dessus ou de toute autre section des Accords (ou de tout Accord de magasin) a eu lieu (ou pourrait avoir lieu). Si nous estimons qu'une telle violation (ou une violation anticipée) s'est produite (ou pourrait se produire), nous pouvons prendre toutes les mesures que nous jugeons appropriées dans les circonstances. Une telle action peut inclure, sans s'y limiter, la suppression temporaire ou permanente de votre Compte utilisateur et de tous les Enregistrements et/ou Matériels et/ou Métadonnées téléchargés par le biais du ou des Services de Lithyus Music Distribution, la retenue de toutes les redevances attribuables aux Enregistrements et/ou Matériels et/ou Métadonnées, et le gel de votre Compte utilisateur. Une telle action peut être prise à tout moment et sans vous en informer. Notez que nous n'autorisons pas non plus le téléchargement des types d'enregistrements suivants :
                    <br />•	Reprises de musique classique.
                    <br />•	Podcasts, livres audio et émissions de radio.
                    <br />•	Parodies et hommages.
                    <br />•	Tout type d'enregistrement interdit ou restreint en vertu d'un accord de magasin, y compris, sans s'y limiter, le bruit blanc/brun/rose, l'ASMR, les fréquences (Hz, ondes, tonalités), les effets sonores/la conception sonore (pluie, forêt, circulation), les sons binauraux et la méditation guidée.
                    <br />•	Une reprise (c'est-à-dire un enregistrement d'une chanson précédemment publiée dont vous n'êtes pas l'auteur ou le propriétaire original) à moins que vous n'ayez obtenu toutes les licences/consentements nécessaires et que vous soyez en mesure de nous la présenter sur demande.

                   </p>
                   <span>9. Applications tierces</span>
                   <p>
                   De temps à autre pendant la Durée, et à notre seule discrétion, nous pouvons utiliser diverses Applications tierces pour faciliter la mise à votre disposition du ou des Services de distribution. Ces applications tierces peuvent avoir leurs propres conditions générales, politiques de confidentialité et/ou conditions supplémentaires et vous acceptez que votre utilisation de ces applications tierces soit régie et soumise à ces conditions générales, politiques de confidentialité et/ou conditions supplémentaires. Vous comprenez et acceptez que Lithyus Music Distribution n'approuve pas et n'est pas responsable du comportement, des fonctionnalités ou du contenu de toute Application tierce ou de toute transaction que vous pourriez conclure avec le fournisseur de ces Applications tierces.


                   </p>
                   <span>10. Modifications de l'entente</span>
                   <p>
                   À l'occasion, nous pouvons, à notre discrétion et sans préavis, apporter des modifications aux Accords. Il est de votre responsabilité de vérifier les modifications apportées aux Accords. Lorsque nous apportons des modifications importantes aux Accords, nous pouvons vous fournir un avis bien visible selon les circonstances, par exemple en affichant un avis bien visible dans le(s) Service(s) de distribution ou en vous envoyant un e-mail. Dans certains cas, nous pouvons (mais ne sommes pas obligés de) vous en informer à l'avance. Votre utilisation continue du ou des services de Lithyus Music Distribution après que les modifications ont été apportées constituera votre acceptation des modifications. Assurez-vous donc de vérifier régulièrement les modifications ou de lire attentivement les avis.
                   </p>



                </div>
             </div>
             <Footer/>
        </div>
    )
    

}
export default ServiceDistributions;