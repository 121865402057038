import axios from "axios";
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import Footer from "../../components/footer/Footer";
import Navbar from "../../components/navbar/Navbar";
import Loader from "../../components/loading/Loader";

function ForgotPassword() {
  const [loginData, setLoginData] = useState({
    email: "",
  });
  const [loading, setLoading] = useState(false)
  const [response, setResponse] = useState("");

  const [errors, setErrors] = useState({
    email: "",
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLoginData({ ...loginData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const handleChangepassword = async (event) => {
    event.preventDefault();
    const { email  } = loginData;
    const newErrors = {};

    if (!email) {
      newErrors.email = "Veuillez remplir le champ email";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    try {
      // Envoyer les données de connexion au backend
      setResponse("");
      setLoading(true);
      const res = await axios.post(
          "/api/users/sendMailChangePassword",
          loginData,
          {
            withCredentials: true,
          }
      );
      setResponse(res.data.message)
      setLoading(false);
    } catch (error) {
      setLoading(false)
      setResponse(error.response.data)
    }
  };

  return (
    <React.Fragment>
      <div className="login">
        <Navbar />
        <div className="div-main">
          <div className="div-main21">
            <div className="top-div">
              <div className="left">
                <span className="text">
                  {" "}
                  <span>
                    <FontAwesomeIcon icon={faCheck} className="red-pi-check" />
                  </span>{" "}
                  DISTRIBUTION GRATUITE
                </span>
                <span className="text">
                  <span>
                    <FontAwesomeIcon icon={faCheck} className="red-pi-check" />
                  </span>{" "}
                  MISE EN LIGNE RAPIDE
                </span>
              </div>
              <div className="right">
                <span className="text">
                  <span>
                    <FontAwesomeIcon icon={faCheck} className="red-pi-check" />
                  </span>{" "}
                  DONNÉES EN TEMPS RÉELS
                </span>
                <span className="text">
                  <span>
                    <FontAwesomeIcon icon={faCheck} className="red-pi-check" />
                  </span>{" "}
                  SERVICE CLIENT EFFICACE
                </span>
              </div>
            </div>
            <div className="centered-div">
              <div className="form-container">
                <form action="" onSubmit={handleChangepassword}>
                  <div>
                    <input
                      type="email"
                      placeholder="Entrez votre adresse mail"
                      value={loginData.email}
                      onChange={handleInputChange}
                      name="email"
                      required
                    />
                    {errors.email && (
                      <span className="error">{errors.email}</span>
                    )}
                  </div>

                  <div className="butom">
                    {
                      loading
                        ?
                          <button disabled style={{marginLeft:'auto', marginRight:'auto', width:"250px"}} className="button-login">
                            <Loader type="newtons-cradle" />
                          </button>
                        :
                          <button type="submit" style={{marginLeft:'auto', marginRight:'auto', width:"250px"}} className="button-login">
                            Changer Mot de Passe
                          </button>
                    }
                  </div>
                </form>
                <div className="response">
                    {
                        response 
                            ? <div style={{textAlign:'center', color:'green', top:'10px', position:'relative'}}>{response}</div>
                            :null
                    }
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      
    </React.Fragment>
  );
}

export default ForgotPassword;
