import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { FcCancel } from "react-icons/fc";
import "./payementCancel.scss"

const PayementCancel = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const id = params.get('id');

  console.log(id)
  useEffect(() => {
    async function verifyEmail() {
      try {
        await axios.post(`/api/payement/payement-cancel`, {id}, {
          withCredentials: true,
        });
        setTimeout(() => {
          window.location.href = '/login'
        }, 3000);
      } catch (error) {

      }
    }

    verifyEmail();
  }, [id]);

  return (
    <div className='payement-cancel'>
        <div className="card"> 
            <button type="button" className="dismiss">×</button> 
            <div className="header"> 
                <div className="image">
                  <FcCancel />
                </div> 
                <div className="content">
                    <span className="title">Paiement annulé</span> 
                    <p className="message">Votre paiement a été annulé. Vous serez redirigez vers le tableau de bord du site. Si dans quelques instant vous n'etes pas redirigez, veillez Cliquer sur le bouton ci-dessous!</p> 
                </div> 
                <div className="actions">
                    <button type="button" className="history">Tableau de bord</button>
                </div> 
            </div> 
        </div>
    </div>
  );
}

export default PayementCancel;