/* eslint-disable no-redeclare */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect, useState } from "react";
import photo6 from "../../assets/images/smarty.png";
import photo7 from "../../assets/images/kayawoto.jpeg";
import photo8 from "../../assets/images/huguo.jpeg";
import photo9 from "../../assets/images/amzy.jpeg";
import photo10 from "../../assets/images/reman.jpg";
import { IoMdMusicalNote } from "react-icons/io";
import axios from "axios"
import "./services.scss";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCreditCard } from "@fortawesome/free-solid-svg-icons";
import NavBar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import { useQuery } from "react-query";
import Chatbot from "../../components/v2/chatbot";
library.add(faCreditCard);

function Services() {
  const [artistesData, setArtistesData] = useState()
  const [monthListen, setMonthListen] = useState()
  const [countryData, setCountryData] = useState()
  const artistes_burkinabes = [
    { image: photo6, text: "Smarty" },
    { image: photo7, text: "Kayawoto" },
    { image: photo8, text: "Huguo Boss" },
    { image: photo9, text: "Amzy" },
    { image: photo10, text: "Reman" },
  ];

  useEffect(() => {
    const fetchArtistesData = async() => {
      try{
        const res = await axios.get("/api/users/getArtistesDist", {
          withCredentials:false
        })
        setArtistesData(res.data)
      }catch(error){
        console.log(error)
      }
    }
    const fetchMostListenMusique = async() => {
      try{
        const res = await axios.get("/api/music/getMonthListenMusique", {
          withCredentials:false
        })
        setMonthListen(res.data)
      }catch(error){
        console.log(error)
      }
    }

    const fetchCountryData = async() => {
      try{
        const res = await axios.get("/api/users/getUserCountry", {
          withCredentials:false
        })
        setCountryData(res.data)
      }catch(error){
        console.log(error)
      }
    }
    fetchMostListenMusique()
    fetchArtistesData()
    fetchCountryData ()
  }, [])

  console.log(countryData)
  return (
    <React.Fragment>
      <NavBar page='services'/>
      <Chatbot />
      <div className="services-page">
        <div className="background-container">
          <div className="background-image">
            <div className="distribution">
              <h2>DISTRIBUTION</h2>
              <p>
                Avec cette option, nous distribuerons vos chansons peu importe
                le nombre, gratuitement sur toutes les plateformes de streaming.
                Par la suite, nous récupérons 30% des revenus générés par vos
                streams.
              </p>
            </div>
            <div className="slider">
              <h3 className="titre-slide">Les musiques les plus écoutées</h3>
              <div className="slider-content"> 
                {monthListen && monthListen.map((music, index) => (
                  <div key={index} className="slider-slide">
                    <img
                      className="slider-image"
                      src={"/api/static/cover/" + music.music_cov}
                      alt={`Photo ${index + 1}`}
                    />
                    <div className="slider-text">
                      <div className="info">
                        <div className="music-title">
                          {music.music_title}
                        </div>
                        <div className="artiste-name">
                          {music.nom_artiste}
                        </div>
                      </div>
                      <div className="listen">
                        <span>
                        {music.nbrstream}

                        </span>
                        <IoMdMusicalNote className="icon"/>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="background-image">
            <div className="promotion">
              <h2>PROMOTION</h2>
              <p>
                Cette option comprend la publicité de votre musique sur les
                sites Web de musique populaires, sur les réseaux sociaux et
                devant les curateurs pour l'ajout en playlist. En plus de
                diffuser votre musique, nous pouvons également en faire la
                promotion sur les plateformes, et ainsi vous aider à gagner plus
                d'auditeurs et de visibilité ! Et développez votre notoriété
                dans ce monde où, pour vous démarquer, il faut non seulement
                être excellent mais aussi savoir faire parler de vous !
              </p>
            </div>
            <div className="slider">
              <h3 className="titre-slide">Artistes Distribués</h3>
              <div className="slider-content"> 
                {artistesData && artistesData.map((artiste, index) => (
                  <div key={index} className="slider-slide">
                    <img
                      className="slider-image"
                      src={"/api/static/profile/" + artiste.profile}
                      alt={artiste.mon_artiste}
                    />
                    <div className="slider-text">
                      <div className="info">
                        <div className="music-title">
                          {artiste.nom_artiste}
                        </div>
                        <div className="artiste-name">
                          {artiste.pays}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="background-image">
            <div className="droit">
              <h2>COLLECTE DE VOS DROITS D'AUTEUR</h2>
              <p>
                En choisissant cette option, nous nous occuperons de la
                récupération de vos différents droits d'auteurs et droits
                voisins au sein des différents organismes de gestion, artistes
                comme compositeurs, on ne vous laissera pas tomber.
              </p>
            </div>
            <div className="slider">
              <h3 className="titre-slide">PAYS LES PLUS DISTRIBués</h3>
              <div className="slider-content">
                {

                }
                {countryData && countryData.map((country, index) => (
                  <div key={index} className="slider-slide">
                    <img
                      className="slider-image"
                      src={"/api/static/profile/" + country.pays + ".png"}
                      alt={country.pays}
                    />
                    <div className="slider-text">
                      <div className="info">
                        <div className="music-title pays">
                          {country.pays}
                        </div>
                        <div className="artiste-name">
                          {country.nbr} Artistes
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="container">
                <div className="spotify-widget">
                <iframe
                    src="https://open.spotify.com/embed/playlist/37i9dQZF1DX05r4Oy3Ln97?utm_source=generator&theme=0"
                    width="100%"
                    // height="352"
                    allowFullScreen
                    allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                    loading="lazy"
                ></iframe>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
      
    </React.Fragment>
  );
}

export default Services;
